import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as searchActions from "actions/searchActions";
import * as siteActions from "actions/siteActions";

import axios from "axios";
import settings from "../../settings";
import SearchFilterList from "./SearchFilterList";
import * as helper from "../../helper";
import * as customerServicesHelper from "../../customerServicesHelper";
import Button from "antd/es/button";
import {Modal} from "antd";
import Cookies from "js-cookie";
import Search from "../../containers/Search";
import {setSearchRequestData} from "../../actions/searchActions";

class SearchFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchRequests: [{}],
			searchFilterIndex: null,
			newSearchFilter: false,
			searchRequest: null,
			filterAlreadyExist: false,
			openNotifyModal: false
		}
	}

	componentDidMount() {
		this.loadFilter();
		this.props.actions.resetSearchRequestData();
	}

	async loadFilter() {
		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");
		const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");

		await axios.post(`${settings.servicePath}/searchfilters`, {mail: mail, linkCode: linkCode})
			.then((result) => {
				let searchRequests = {dates: [], total: result.data.length};

				result.data.map(request => {
					let index = searchRequests.dates.findIndex(date => date.date === this.formatTimestamp(request.createdAt))

					if(index !== -1) {
						searchRequests.dates[index].requests.push(request);
					} else {
						searchRequests.dates.push({date: this.formatTimestamp(request.createdAt), requests: [request]});
					}
				})

				this.setState({searchRequests: searchRequests});
			});
	}

	formatTimestamp(timestamp) {
		let createdAt = new Date(timestamp);
		let monthString = "";

		switch(createdAt.getMonth()) {
			case 0: monthString = "Januar"; break;
			case 1: monthString = "Februar"; break;
			case 2: monthString = "März"; break;
			case 3: monthString = "April"; break;
			case 4: monthString = "Mai"; break;
			case 5: monthString = "Juni"; break;
			case 6: monthString = "Juli"; break;
			case 7: monthString = "August"; break;
			case 8: monthString = "September"; break;
			case 9: monthString = "Oktober"; break;
			case 10: monthString = "November"; break;
			case 11: monthString = "Dezember"; break;
		}

		return createdAt.getDate() + ". " + monthString.toUpperCase() + " " + createdAt.getFullYear();
	}

	newSearchFilter = () => {
		this.props.actions.setNewFilterDisabled(false);
		this.props.actions.resetSearchState();
		localStorage.removeItem("SEARCH_FILTER_STATE");
		localStorage.removeItem("SEARCH_META_STATE");
		this.props.history.push(`/`);
	}

	editSearchFilter = async (suchanfrageId, datesIndex, daysBack, jumpToList) => {
		const id = this.state.searchRequests.dates[datesIndex].requests.findIndex(request => request.suchanfrageId === suchanfrageId);
		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		axios.post(`${settings.servicePath}/searchfilter/${suchanfrageId}/${linkCode}/update/lastseen`);
		axios.get(`${settings.servicePath}/searchfilter/${suchanfrageId}/${linkCode}`)
			.then((result) => {
				this.props.actions.setSearchRequestData(daysBack, jumpToList, result.data);
				this.props.history.push(`/`);
			});
	}

	setNotifyFilter = async (requestIndex, datesIndex, zugestimmt) => {
		const suchanfrage = this.state.searchRequests.dates[datesIndex].requests[requestIndex];
		const id = suchanfrage.suchanfrageId;
		const index = this.state.searchRequests.dates[datesIndex].requests.findIndex(request => request === suchanfrage);
		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		await axios.post(`${settings.servicePath}/searchfilter/${id}/${linkCode}/notify`, {zugestimmt: zugestimmt})
			.then((result) => {
				if(result.data === "UNAUTHORIZED") {
					this.setState({openNotifyModal: true});
				} else {
					let searchRequestsCopy = Object.assign({}, this.state.searchRequests);

					searchRequestsCopy.dates[datesIndex].requests[index].zugestimmt = zugestimmt;
					this.setState({searchRequests: searchRequestsCopy, searchFilterIndex: null});
				}
			});
	}

	deleteSearchFilter = (toDelete = -1, datesIndex) => {
		const suchanfrageId = toDelete !== -1 ? toDelete : this.state.searchRequest.suchanfrageId;
		const index = this.state.searchRequests.dates[datesIndex].requests.findIndex(request => request.suchanfrageId === suchanfrageId);

		if(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail") || Cookies.get("linkCode") && Cookies.get("mail")) {
			const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

			axios.post(`${settings.servicePath}/searchfilter/${suchanfrageId}/${linkCode}/delete`)
				.then((result) => {
					let searchRequestsCopy = this.state.searchRequests;

					searchRequestsCopy.dates[datesIndex].requests.splice(index, 1);
					this.setState({searchRequests: searchRequestsCopy, searchFilterIndex: null, newSearchFilter: false});

					this.props.updateSearchRequests();
					this.loadFilter();
				});
		}
	}

	render() {
		return (
			<div>
				<Modal footer={null} okButtonProps={{ display: "none" }} style={{width: "fit-content"}} title="Benachrichtigung kann nicht aktiviert werden." visible={this.state.openNotifyModal} onCancel={() => this.setState({openNotifyModal: false})}>
					<p>Zum Aktivieren der E-Mail Benachrichtigung benötigen wir Ihre Einwilligungserklärung.</p>

					<Button type={"primary"}
						onClick={() => {
							this.setState({openNotifyModal: false});
							this.props.changeSite("einverständnis");
						}}
					>
						Einwilligung erklären
					</Button>
					<Button style={{marginLeft: ".5em"}} onClick={() => this.setState({openNotifyModal: false})}>Abbrechen</Button>
				</Modal>
				<SearchFilterList
					agreementAccepted={this.props.agreementAccepted}
					searchRequests={this.state.searchRequests}
					onCreateSearchFilter={this.newSearchFilter}
					onEditSearchFilter={this.editSearchFilter}
					onDeleteSearchFilter={this.deleteSearchFilter}
					onSetNotifyFilter={this.setNotifyFilter}
				/>
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		search: state.search,
		site: state.site
	};
}

export default connect(mapStateToProps)(SearchFilter);