import React from 'react';

const FinancingExample = ({ financing }) => {
	const variante = financing.finanzierungsvariante;
	const typ = financing.typ;

	return (
		<div id="modal">
			<table>
				<tbody>
					<tr>
						<td>{variante === "3" ? "Listenpreis" : "Anschaffungspreis"}</td>
						<td style={{textAlign: "right", paddingRight: "20px"}}>{financing.anschaffungsPreis}</td>
						<td>{financing.periodInMonths && financing.periodInMonths} Monatliche {variante === "3" ? "Finanzierungsraten" : "Leasingraten" } á¹</td>
						<td>{financing.monthlyRate}</td>
					</tr>
					<tr>
						<td>{variante === "3" ? "Anzahlung" : "Leasingsonderzahlung"}</td>
						<td style={{textAlign: "right", paddingRight: "20px"}}>{variante === "3" ? financing.firstInstallment : financing.leasingsonderzahlung}</td>
						<td>Sollzinssatz p.a.²</td>
						<td>{financing.nominalInterestRate}</td>
					</tr>
					<tr>
						<td>{variante === "3" ? `${financing.periodInMonths}. Zielrate` : "Laufleistung p.a."}</td>
						<td style={{textAlign: "right", paddingRight: "20px"}}>{variante === "3" ? financing.endingRate : financing.jahreskm}</td>
						<td>Effektiver Jahreszins</td>
						<td>{financing.ratePerCent}</td>
					</tr>
					<tr>
						<td>Laufzeit</td>
						<td style={{textAlign: "right", paddingRight: "20px"}}>{financing.periodInMonths} Monate</td>
						<td>{variante === "3" ? typ === "privat" ? "Nettodarlehensbetrag" : "Bruttodarlehensbetrag" : "Gesamtbetrag"}</td>
						<td>{variante === "3" ? financing.netCreditAmount : financing.grossCreditAmount}</td>
					</tr>
				</tbody>
			</table>
			<br />¹ Ein unverbindliches {variante === "3" ? "Finanzierungsbeispiel" : "Leasingbeispiel"} der {financing.bankName} {financing.bankAdresse};
			alle Preise {typ === "privat" ? "inkl." : "zzgl."} 19 % MwSt.; Stand {financing.stand}.
			{typ === "privat" ?
				`Ist der ${variante === "3" ? "Darlehensnehmer" : "Leasingnehmer"} Verbraucher,  
				besteht nach Vertragsschluss ein gesetzliches Widerrufsrecht. 
				Nach den ${variante === "Finanzierung" ? "Finanzierungs" : "Leasing"}bedingungen besteht die Verpflichtung für das Fahrzeug eine Vollkaskoversicherung abzuschließen.`
			:
				`Nach den ${variante === "3" ? "Finanzerungs" : "Leasing"}bedingungen besteht die Verpflichtung für das Fahrzeug eine Vollkaskoversicherung abzuschließen. 
				Dieses Beispiel gilt nicht für Verbraucher. Es richtet sich ausschließlich an selbstständige und gewerbliche Kunden. 
				Informationen hinsichtlich weiterer Bedingungen und Details erhalten Sie bei uns.`
			}
			<br /><br />
			² gebunden für die gesamte Vertragslaufzeit.<br /><br />
			Wir vermitteln {variante === "3" ? "Finanzierungs" : "Leasing"}verträge ausschließlich an die {financing.bankName} {financing.bankAdresse}.<br /><br />
			Zzgl. {financing.ueberfuehrungsKosten} für Überführung.<br /><br />
			Alle Angaben vorbehaltlich Tippfehler, Zwischenverkauf und Irrtümer
		</div>
	);
};

/*
	<div id="modal">
		<div className="content">
			<h2 style={{ marginBottom: "1rem" }}>Finanzierungsbeispiel {vehicle.shortDescription}</h2>
		</div>
	</div>
*/

export default FinancingExample;