import React from 'react';
import {Icon} from "antd";
import chfFlag from "../../../styles/img/chf.jpg";
import Button from "antd/es/button";

const VehiclePrice = ({ vehicle, isFinancing, toggleFinancing, onVorschlag }) => {
	return (
		<div className="vehicle-price">
			<div style={{display: "flex", justifyContent: vehicle.financing && vehicle.financing.length > 0 ? "space-evenly" : "center"}}>
				<div>
					<span style={{ marginTop: "0.75rem", display: "block", minHeight: "24px" }}>{vehicle.priceNet && "(Inklusive MwSt.)"}</span>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<div style={{ marginTop: "0.5rem", marginBottom: "0" }} className="title title-heading title-center">{vehicle.price}</div>
						<Button onClick={onVorschlag} size={"small"} type={"primary"} style={{ borderRadius: "0", marginBottom: ".5rem", padding: ".5rem" }}>
							Machen Sie uns einen Vorschlag!
						</Button>
					</div>
					{!!vehicle.reducedBy && (
						<div className="reduced-price">
							Reduziert um {vehicle.reducedBy}
						</div>
					)}
					{vehicle.chfPriceInfo && vehicle.chfPriceInfo.deVAT && vehicle.chfPriceInfo.deVAT != "null €" && vehicle.priceNet ?
						<div id="vehicle-price-mwst">Nettopreis: {vehicle.priceNet}</div>
					: null}
					<hr />
					<div>
						<h2
							className="chf-price"
							onClick={() => toggleFinancing("chfPricingModal")}
						>
							<img src={chfFlag} style={{ marginRight: "0.5rem" }} />
							{vehicle.chfPriceInfo && vehicle.chfPriceInfo.totalPriceCHF ? vehicle.chfPriceInfo.totalPriceCHF : null}
							<Icon className="chf-price-icon" type="info-circle" />
						</h2>
					</div>
				</div>
				<div>
					{vehicle.financing &&
						vehicle.financing.map((financing, index) => {
							if(financing.typ !== "privat" && financing.typ !== "gewerblich") { return null; }
							return (
								<div key={`financing-${index}`} style={{marginLeft: "1em"}}>
									<span style={{ marginTop: "0.75rem", display: "block" }}>{financing.finanzierungsvariante === "3" ? "Finanzierung" : "Leasing"}</span>
									<div style={{ display: "block" }}>
										<div style={{ marginTop: "0.5rem" }} className="title title-heading title-center">
											{financing.monthlyRate}
											<span style={{verticalAlign: "middle", textTransform: "lowercase", fontSize: "1rem", display: "inline-block", marginBottom: "5px", marginLeft: ".5em"}}>mtl.</span>
										</div>
									</div>
									<Button style={{padding: ".5em 1em", width: "100%"}} size={"small"} onClick={() => toggleFinancing("financingModal", financing)}>Beispiel</Button>
								</div>
							);
						})
					}
				</div>
			</div>

		</div>
	);

};

export default VehiclePrice;