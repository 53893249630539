import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Section = ({ containerClassName, containerId, title, children }) => (
	<div className={classnames(
		"container",
		containerClassName
	)}
	id={containerId}
	>

		<div className="heading">
			<div className="text">
				<div className="skewed-bg"></div>
				<span>{title}</span>
			</div>
		</div>

		<div className="row no-margin">
			{children}
		</div>
	</div>
);

Section.propTypes = {
	containerClassName: PropTypes.string,
	containerId: PropTypes.string,
	title: PropTypes.string
};

export default Section;