import React from 'react';

const EfficiencyClass = ({ id }) => {
	if(id != "") {
		return (
			<img style={{ maxWidth: "100%" }} src={require(`../../../styles/img/eff_${id.replaceAll("+", "plus")}.jpg`)} alt={`Effizienzklasse ${id.toUpperCase()}`} />
		);
	} else {
		return null;
	}
};

export default EfficiencyClass;