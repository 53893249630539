import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from 'js-cookie';

import {BackTop, Drawer, Icon, Result, Spin} from 'antd';

import * as searchActions from "actions/searchActions";
import * as siteActions from "actions/siteActions";

import Section from "../components/layout/Section";
import SearchFilter from "../components/customer-panel/SearchFilter";
import ParkedVehiclesList from "../components/customer-panel/ParkedVehiclesList";
import Footer from "../components/layout/Footer";

import qs from "querystring";
import LoginForm from "./LoginForm";
import AgreementLink from "./AgreementLink";
import axios from "axios";
import settings from "../settings";
import Button from "antd/es/button";
import Comparsion from "./Comparsion";
import * as customerServicesHelper from "../customerServicesHelper";

class CustomerPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: "suchanfragen",
            loggedOn: false,
            mail: "",
            agreementAccepted: false,
            error: false,
            addCodeUsed: false,
            errorMsg: "",
            visible: false,
            resetFilterView: false,
            rerender: false,
            wait: true
        }
    }

    handleClick = site => {
        axios.post(`${settings.servicePath}/kpi/page?page=${site}`)

        this.setState({ rerender: !this.state.rerender, current: site });
    };

    componentDidMount() {
        axios.post(`${settings.servicePath}/kpi/page?page=customerpanel`);

        this.props.actions.resetSearchRequestData();

        if((sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail"))) {
            const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");
            const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");

            axios.post(`${settings.servicePath}/agreement/login/authorised`, {linkCode: linkCode, mail: mail})
                .then((result) => {
                    if(result.data === "UNAUTHORIZED") {
                        sessionStorage.removeItem("linkCode");
                        sessionStorage.removeItem("mail");
                        localStorage.removeItem("stay");
                        Cookies.remove("addCode");
                        Cookies.remove("linkCode");
                        Cookies.remove("mail");

                        location.reload();
                    } else if(result.data === "ACCEPTED") {
                        axios.post(`${settings.servicePath}/agreement`, {linkCode: linkCode})
                        .then((result) => {
                            this.setState({loggedOn: true, mail: mail, agreementAccepted: result.data.zugestimmt, wait: false});
                        });
                    }
                });
        } else {
            if(qs.decode(location.search.substring(1)).c && !qs.decode(location.search.substring(1)).add) {
                sessionStorage.setItem('linkCode', qs.decode(location.search.substring(1)).c);
                this.setState({wait: false});
            } else if(qs.decode(location.search.substring(1)).c && qs.decode(location.search.substring(1)).add) {
                if(localStorage.getItem("stay") == "true") {
                    Cookies.set('linkCode', qs.decode(location.search.substring(1)).c, { expires: 90});
                    Cookies.set('addCode', qs.decode(location.search.substring(1)).add, { expires: 90});
                } else {
                    sessionStorage.setItem('linkCode', qs.decode(location.search.substring(1)).c);
                    sessionStorage.setItem('addCode', qs.decode(location.search.substring(1)).add);
                }

                const linkCode = qs.decode(location.search.substring(1)).c;
                const addCode = qs.decode(location.search.substring(1)).add;

                axios.post(`${settings.servicePath}/agreement/login/${linkCode}/${addCode}`)
                    .then((result) => {
                        if(!result.data.error) {
                            if(localStorage.getItem("stay") == "true") {
                                Cookies.set('mail', result.data.mail, { expires: 90});
                                localStorage.removeItem("stay");
                            }
                            else { sessionStorage.setItem('mail', result.data.mail); }

                            this.props.loggingIn();
                        }
                        else {
                            this.setState({addCodeUsed: true, wait: false});
                            sessionStorage.setItem('linkCode', result.data.linkCode);
                        }
                    });
            } else {
                this.setState({wait: false});
            }
        }

        if(qs.decode(location.search.substring(1)).mail && qs.decode(location.search.substring(1)).id && qs.decode(location.search.substring(1)).anfrage) {
            let mailId = qs.decode(location.search.substring(1)).mail;
            let suchanfrageId = qs.decode(location.search.substring(1)).id;
            let anfrageId = qs.decode(location.search.substring(1)).anfrage;

            if(suchanfrageId == -1) {
                this.setState({suchanfrageAlreadyCreated: true, wait: false});
            } else {
                axios.post(`${settings.servicePath}/searchfilter/${mailId}/${suchanfrageId}/${anfrageId}/agreementdata`)
                    .then((result) => {
                        if(result.data === "OK") {
                            this.setState({suchanfrageCreated: true, wait: false});
                        }
                        else if(result.data === "FOUND") {
                            this.setState({suchanfrageAlreadyCreated: true, wait: false});
                        } else {
                            this.setState({badLink: true, wait: false});
                        }
                    });
            }
        }

        if(qs.decode(location.search.substring(1)).start === "agreement") {
            this.setState({current: "einverständnis"});
        } else if(qs.decode(location.search.substring(1)).start === "parkplatz") {
            this.setState({current: "parkplatz"});
        }

        history.replaceState(null, "", "/customerpanel");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.site.comparsionVehicles !== this.props.site.comparsionVehicles && this.props.site.comparsionVehicles.length === 0) {
            this.setState({visible: false});
        }
    }

    onSuccess = (mail) => {
        this.props.loggingIn();
    }

    onClickCompare = () => {
        if(this.state.visible) {
            this.setState({visible: false});
        } else {
           this.setState({visible: true});
        }
    }

    onOpenDrawer = () => {
        this.setState({visible: true});
    }

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return(
            <div id="customer-panel">
                <div className={"big-menu"}>
                    <div style={{display: "block", textAlign: "end", borderBottom: "1px solid #d9d9d9",	padding: ".5rem"}}>
                        {(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail")) ?
                            <div>
                                <Button type="link" style={{padding: ".5rem 1.5rem"}} onClick={() => this.handleClick("suchanfragen")}>Meine Suchen <span className={"anzahl-menu"}>({this.props.searchRequestsCount})</span></Button>
                                <Button type="link" style={{padding: ".5rem 1.5rem"}} onClick={() => this.handleClick("parkplatz")}>Parkplatz <span className={"anzahl-menu"}>({this.props.parkedVehicleCount})</span></Button>
                                <Button type="link" style={{padding: ".5rem 1.5rem"}} onClick={() => this.handleClick("einverständnis")}>Mein Konto</Button>
                                <Button onClick={customerServicesHelper.onLogout} type="primary" style={{padding: ".5rem 1.5rem"}}>Abmelden</Button>
                            </div>
                            :
                                <Button type="primary" style={{padding: ".5rem 1.5rem"}}>Anmelden</Button>
                        }
                    </div>

                    <BackTop
                        visibilityHeight={window.innerWidth > 750 ? -1 : 100}
                    >
                        <div className="scroll-to scroll-to-show back-to-search" style={{top: "5rem"}}>
                            <div style={{ color: "white" }} onClick={() => this.props.history.push(`/`)}>
                                <svg height="16px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M28,14H8.8l4.62-4.62C13.814,8.986,14,8.516,14,8c0-0.984-0.813-2-2-2c-0.531,0-0.994,0.193-1.38,0.58l-7.958,7.958  C2.334,14.866,2,15.271,2,16s0.279,1.08,0.646,1.447l7.974,7.973C11.006,25.807,11.469,26,12,26c1.188,0,2-1.016,2-2  c0-0.516-0.186-0.986-0.58-1.38L8.8,18H28c1.104,0,2-0.896,2-2S29.104,14,28,14z"/></svg>
                                &nbsp; Zum Fahrzeugmarkt
                            </div>
                        </div>
                    </BackTop>

                    <a target="_blank" href="https://auer-gruppe.de">
                        <svg id="auerlogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.88 69.21">
                            <title>Auer Gruppe GmbH</title>
                            <g id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                    <path className="cls-1" d="M70,0H41.64a24.48,24.48,0,0,0-6.18.76A23.56,23.56,0,0,0,31.78,2a15,15,0,0,0-3.3,2.12,20.21,20.21,0,0,0-3.74,4.15L0,44H40.9Z" />
                                    <path className="cls-1" d="M57.57,41.74c5.75,5.27,17.23,3.36,22.21,2.07A20,20,0,0,0,90.9,36.09L115.12,0H75.84L55.6,30.09S51.35,36.05,57.57,41.74Z" />
                                    <path className="cls-1" d="M194.91,2.75A10,10,0,0,0,188.66,0H162.91L133.56,44h40.29l21.84-32.66S198.84,6.81,194.91,2.75Z" />
                                    <polygon className="cls-1" points="151.44 9.25 157.52 0 120.14 0 90.81 44.05 128.27 44.05 134.72 34.32 151.44 9.25" />
                                    <polygon className="cls-1" points="27.89 64.37 31.08 64.37 31.08 62.05 27.89 62.05 27.89 59.77 31.8 59.77 31.8 57.48 25.1 57.48 25.1 68.96 32.3 68.96 32.3 66.68 27.89 66.68 27.89 64.37" />
                                    <path className="cls-1" d="M42.58,61a3.5,3.5,0,0,0-3.71-3.47H34.59V69h2.68V64.44h1L41.36,69h3.11l-3.65-5.13A3.16,3.16,0,0,0,42.58,61Zm-4.12,1.3H37.27V59.67h1.19a1.3,1.3,0,1,1,0,2.57Z" />
                                    <path className="cls-1" d="M4.52,57.39,0,69H2.63l.88-2.39H7.29L8.19,69H11L6.44,57.39Zm-.32,7.07L5,62.16A8.2,8.2,0,0,0,5.38,61h0a11.41,11.41,0,0,0,.36,1.17l.83,2.3Z" />
                                    <path className="cls-1" d="M19.52,64.79a2,2,0,0,1-2.23,2,2,2,0,0,1-2.21-2V57.48H12.28V65c0,2.56,2.23,4.18,5,4.18s5-1.62,5-4.18V57.48H19.52Z" />
                                    <polygon className="cls-1" points="105.21 63.83 109.04 63.83 109.04 62.55 105.21 62.55 105.21 58.76 109.82 58.76 109.82 57.48 103.73 57.48 103.73 68.96 110.46 68.96 110.46 67.69 105.21 67.69 105.21 63.83" />
                                    <path className="cls-1" d="M89.45,57.48H86V69h1.46V63.81h2.05a3.18,3.18,0,1,0,0-6.34Zm-.23,5.11h-1.8V58.76H89.2a2,2,0,0,1,2.16,1.93A1.94,1.94,0,0,1,89.22,62.59Z" />
                                    <path className="cls-1" d="M98.34,57.48H94.85V69H96.3V63.81h2.05a3.18,3.18,0,1,0,0-6.34Zm-.23,5.11H96.3V58.76h1.78a2,2,0,0,1,2.16,1.93A1.94,1.94,0,0,1,98.1,62.59Z" />
                                    <path className="cls-1" d="M57.35,64.46H60.2v2.77a4.8,4.8,0,0,1-2.7.68,4.67,4.67,0,1,1,0-9.34,6.15,6.15,0,0,1,3.2.9L61,58.16a7,7,0,0,0-3.65-.9,6,6,0,1,0,.09,11.9,6.6,6.6,0,0,0,4.19-1.22V63.27H57.35Z" />
                                    <path className="cls-1" d="M81.59,64.8a3.12,3.12,0,0,1-6.25,0V57.48H73.86v7.47c0,2.65,1.93,4.21,4.59,4.21S83,67.59,83,64.95V57.48H81.59Z" />
                                    <path className="cls-1" d="M71,60.65a3.21,3.21,0,0,0-3.42-3.17H64.3V69h1.46V63.81h1.57L71,69h1.84l-4-5.38A3,3,0,0,0,71,60.65Zm-3.65,1.94H65.76V58.76h1.6a2,2,0,0,1,2.16,1.93A1.94,1.94,0,0,1,67.38,62.59Z" />
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>

                <div className={"small-menu"}>
                    <a target="_blank" href="https://auer-gruppe.de">
                        <svg id="auerlogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.88 69.21">
                            <title>Auer Gruppe GmbH</title>
                            <g id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                    <path className="cls-1" d="M70,0H41.64a24.48,24.48,0,0,0-6.18.76A23.56,23.56,0,0,0,31.78,2a15,15,0,0,0-3.3,2.12,20.21,20.21,0,0,0-3.74,4.15L0,44H40.9Z" />
                                    <path className="cls-1" d="M57.57,41.74c5.75,5.27,17.23,3.36,22.21,2.07A20,20,0,0,0,90.9,36.09L115.12,0H75.84L55.6,30.09S51.35,36.05,57.57,41.74Z" />
                                    <path className="cls-1" d="M194.91,2.75A10,10,0,0,0,188.66,0H162.91L133.56,44h40.29l21.84-32.66S198.84,6.81,194.91,2.75Z" />
                                    <polygon className="cls-1" points="151.44 9.25 157.52 0 120.14 0 90.81 44.05 128.27 44.05 134.72 34.32 151.44 9.25" />
                                    <polygon className="cls-1" points="27.89 64.37 31.08 64.37 31.08 62.05 27.89 62.05 27.89 59.77 31.8 59.77 31.8 57.48 25.1 57.48 25.1 68.96 32.3 68.96 32.3 66.68 27.89 66.68 27.89 64.37" />
                                    <path className="cls-1" d="M42.58,61a3.5,3.5,0,0,0-3.71-3.47H34.59V69h2.68V64.44h1L41.36,69h3.11l-3.65-5.13A3.16,3.16,0,0,0,42.58,61Zm-4.12,1.3H37.27V59.67h1.19a1.3,1.3,0,1,1,0,2.57Z" />
                                    <path className="cls-1" d="M4.52,57.39,0,69H2.63l.88-2.39H7.29L8.19,69H11L6.44,57.39Zm-.32,7.07L5,62.16A8.2,8.2,0,0,0,5.38,61h0a11.41,11.41,0,0,0,.36,1.17l.83,2.3Z" />
                                    <path className="cls-1" d="M19.52,64.79a2,2,0,0,1-2.23,2,2,2,0,0,1-2.21-2V57.48H12.28V65c0,2.56,2.23,4.18,5,4.18s5-1.62,5-4.18V57.48H19.52Z" />
                                    <polygon className="cls-1" points="105.21 63.83 109.04 63.83 109.04 62.55 105.21 62.55 105.21 58.76 109.82 58.76 109.82 57.48 103.73 57.48 103.73 68.96 110.46 68.96 110.46 67.69 105.21 67.69 105.21 63.83" />
                                    <path className="cls-1" d="M89.45,57.48H86V69h1.46V63.81h2.05a3.18,3.18,0,1,0,0-6.34Zm-.23,5.11h-1.8V58.76H89.2a2,2,0,0,1,2.16,1.93A1.94,1.94,0,0,1,89.22,62.59Z" />
                                    <path className="cls-1" d="M98.34,57.48H94.85V69H96.3V63.81h2.05a3.18,3.18,0,1,0,0-6.34Zm-.23,5.11H96.3V58.76h1.78a2,2,0,0,1,2.16,1.93A1.94,1.94,0,0,1,98.1,62.59Z" />
                                    <path className="cls-1" d="M57.35,64.46H60.2v2.77a4.8,4.8,0,0,1-2.7.68,4.67,4.67,0,1,1,0-9.34,6.15,6.15,0,0,1,3.2.9L61,58.16a7,7,0,0,0-3.65-.9,6,6,0,1,0,.09,11.9,6.6,6.6,0,0,0,4.19-1.22V63.27H57.35Z" />
                                    <path className="cls-1" d="M81.59,64.8a3.12,3.12,0,0,1-6.25,0V57.48H73.86v7.47c0,2.65,1.93,4.21,4.59,4.21S83,67.59,83,64.95V57.48H81.59Z" />
                                    <path className="cls-1" d="M71,60.65a3.21,3.21,0,0,0-3.42-3.17H64.3V69h1.46V63.81h1.57L71,69h1.84l-4-5.38A3,3,0,0,0,71,60.65Zm-3.65,1.94H65.76V58.76h1.6a2,2,0,0,1,2.16,1.93A1.94,1.94,0,0,1,67.38,62.59Z" />
                                </g>
                            </g>
                        </svg>
                    </a>

                    <div style={{display: "block", textAlign: "end", borderBottom: "1px solid #d9d9d9",	padding: ".5rem"}}>
                        {(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail")) ?
                            <div>
                                <Button type="link" style={{padding: ".5rem 1.5rem"}} onClick={() => this.handleClick("suchanfragen")}>Meine Suchen <span className={"anzahl-menu"}>({this.props.searchRequestsCount})</span></Button>
                                <Button type="link" style={{padding: ".5rem 1.5rem"}} onClick={() => this.handleClick("parkplatz")}>Parkplatz <span className={"anzahl-menu"}>({this.props.parkedVehicleCount})</span></Button>
                                <Button type="link" style={{padding: ".5rem 1.5rem"}} onClick={() => this.handleClick("einverständnis")}>Mein Konto</Button>
                                <Button onClick={customerServicesHelper.onLogout} type="primary" style={{padding: ".5rem 1.5rem"}}>Abmelden</Button>
                            </div>
                            :
                                <Button type="primary" style={{padding: ".5rem 1.5rem"}}>Anmelden</Button>
                        }
                    </div>

                    <BackTop
                        visibilityHeight={window.innerWidth > 750 ? -1 : 100}
                    >
                        <div className="scroll-to scroll-to-show back-to-search">
                            <div style={{ color: "white" }} onClick={() => window.location.replace("/")}>
                                <svg height="16px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M28,14H8.8l4.62-4.62C13.814,8.986,14,8.516,14,8c0-0.984-0.813-2-2-2c-0.531,0-0.994,0.193-1.38,0.58l-7.958,7.958  C2.334,14.866,2,15.271,2,16s0.279,1.08,0.646,1.447l7.974,7.973C11.006,25.807,11.469,26,12,26c1.188,0,2-1.016,2-2  c0-0.516-0.186-0.986-0.58-1.38L8.8,18H28c1.104,0,2-0.896,2-2S29.104,14,28,14z"/></svg>
                                &nbsp; Zum Fahrzeugmarkt
                            </div>
                        </div>
                    </BackTop>
                </div>

                <Spin spinning={this.state.wait} tip="Loading...">
                    <div className="content">
                        <div style={{width: "100%", marginTop: "6rem"}} id={"customerPanel"}>
                            {this.state.suchanfrageCreated || this.state.suchanfrageAlreadyCreated || this.state.badLink || sessionStorage.getItem("deletionSuccess") === "true" ?
                                <Section
                                    containerId="customer-panel"
                                    title={sessionStorage.getItem("deletionSuccess") === "true" ? "Kundenkonto erfolgreich gelöscht" : "Suchanfrage anlegen"}
                                >
                                    {this.state.suchanfrageCreated &&
                                        <Result
                                            status="success"
                                            title="Ihr Suchauftrag wurde erfolgreich angelegt."
                                            subTitle=""
                                            extra={[
                                                <Button
                                                    key={"confirmBtn"}
                                                    type="primary"
                                                    onClick={() => this.setState({suchanfrageCreated: false})}
                                                >
                                                    Ok
                                                </Button>,
                                            ]}
                                        />
                                    }
                                    {sessionStorage.getItem("deletionSuccess") === "true" &&
                                        <Result
                                            status="success"
                                            title="Ihr Kundenkonto mitsamt all Ihrer gepflegten Daten wurde erfolgreich gelöscht!"
                                            subTitle="Sie können sich gerne jederzeit erneut in unserem System registrieren."
                                            extra={[
                                                <Button key={"confirmBtn"} style={{display: "block", margin: "0 auto", textAlign: "center"}} onClick={() => {
                                                    sessionStorage.removeItem("deletionSuccess");
                                                    this.props.history.push(`/`);
                                                }}>Zum Fahrzeugmarkt</Button>
                                            ]}
                                        />
                                    }
                                    {this.state.suchanfrageAlreadyCreated &&
                                        <Result
                                            status="info"
                                            title="Es wurde bereits ein identischer Suchauftrag angelegt."
                                            subTitle=""
                                            extra={[
                                                <Button
                                                    key={"confirmBtn"}
                                                    type="primary"
                                                    onClick={() => this.setState({suchanfrageAlreadyCreated: false})}
                                                >
                                                    Ok
                                                </Button>,
                                            ]}
                                        />
                                    }
                                    {this.state.badLink &&
                                        <Result
                                            status="error"
                                            title="Ungültiger Link!"
                                            subTitle=""
                                            extra={[
                                                <Button
                                                    key={"confirmBtn"}
                                                    type="primary"
                                                    onClick={() => this.setState({badLink: false})}
                                                >
                                                    Ok
                                                </Button>,
                                            ]}
                                        />
                                    }
                                </Section>
                            :
                                !this.state.wait ?
                                    <Section
                                        containerId="customer-panel"
                                        title={this.state.loggedOn === false ? "Anmelden / Registrieren" : this.state.current === "einverständnis" ? "Mein Konto" : this.state.current.toUpperCase()}
                                    >
                                        {
                                            this.state.loggedOn === false ?
                                                <LoginForm onSuccess={this.onSuccess} addCodeUsed={this.state.addCodeUsed} history={this.props.history} />
                                                :
                                                this.state.current === "suchanfragen" ?
                                                    <SearchFilter
                                                        actions={this.props.actions}
                                                        changeSite={(site) => this.setState({current: site})}
                                                        history={this.props.history}
                                                        savedFilter={this.props.savedFilter}
                                                        existingFilter={this.props.existingFilter}
                                                        vehicleParked={this.props.vehicleParked}
                                                        vehicleParkedOut={this.props.vehicleParkedOut}
                                                        vehicleCompared={this.props.vehicleCompared}
                                                        searchActions={this.props.actions}
                                                        resetView={this.state.resetFilterView}
                                                        mail={this.state.mail}
                                                        updateSearchRequests={this.props.updateSearchRequests}
                                                        updateParkedVehicles={this.props.updateParkedVehicles}
                                                        rerender={this.state.rerender}
                                                        agreementAccepted={this.state.agreementAccepted}
                                                    />
                                                    :
                                                    this.state.current === "einverständnis" ?
                                                        <AgreementLink history={this.props.history} setAgreementAccepted={(accepted) => this.setState({agreementAccepted: accepted})} updated={this.props.updated}/>
                                                        :
                                                        <ParkedVehiclesList vehicleCompared={this.props.vehicleCompared} vehicleRemovedCompare={this.props.vehicleRemovedCompare} updateParkedVehicles={this.props.updateParkedVehicles} siteActions={this.props.siteActions} onSetComparsionVehicle={this.props.onSetComparsionVehicle} siteData={this.props.site} vehicleParkedOut={this.props.vehicleParkedOut} />
                                        }
                                    </Section>
                                :
                                    null
                            }
                        </div>
                    </div>
                </Spin>

                <Drawer
                    title="Fahrzeuge Vergleichen"
                    placement={window.innerWidth >= 801 ? "right" : "bottom"}
                    closable={true}
                    onClose={this.onClose}
                    visible={this.props.site.comparsionVehicles.length > 0 && this.state.visible}
                    key={"comparsion-drawer"}
                    height={"100%"}
                    width={"fit-content"}
                >
                    <Comparsion searchClass={this.props.search.searchMeta.searchClass} closeDrawer={() => this.setState({visible: false})} comparsionVehicles={this.props.site.comparsionVehicles} history={this.props.history} siteActions={this.props.siteActions} />
                </Drawer>
                <div style={{cursor: "pointer", display: this.props.site.comparsionVehicles.length > 0 ? "block" : "none"}} className={"comparsion-vehicle"} onClick={this.onClickCompare}>
                    Vergleichsfahrzeuge
                </div>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        search: state.search,
        site: state.site
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(searchActions, dispatch),
        siteActions: bindActionCreators(siteActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPanel);