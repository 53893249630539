import settings from 'settings';

export function sendAnalyticsEvent(eventCategory, eventAction, eventLabel = "Fahrzeugmarkt") {
	ga("send", "event", eventCategory, eventAction, eventLabel);
	log.debug("send analytics event", eventCategory, eventAction, eventLabel);
}

export const log = {
	debug: (...content) => {
		if(process.env.NODE_ENV !== undefined && process.env.NODE_ENV === "development") {

		}
	},
	info: (...content) => {
		console.log(...content);
	}
};

export function calculateZuladung(leergewicht, gesamtGewicht) {
	let leergewichtData = leergewicht.split(".");
	let gesamtGewichtData = gesamtGewicht.split(".");
	let zuladung = 0;

	leergewicht = parseInt(leergewichtData[0] + leergewichtData[1]);
	gesamtGewicht = parseInt(gesamtGewichtData[0] + gesamtGewichtData[1]);

	if(leergewicht < gesamtGewicht) {
		zuladung = (gesamtGewicht - leergewicht);
	}

	return zuladung.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export function setFallbackImage(ev) {
	ev.target.src = settings.imagePath + settings.fallbackImage;
}

export const renderEmissionClass = (emissionClass) => {
	switch(emissionClass) {
		case "EURO6C":
			return "Euro 6c";

		case "EURO6D":
			return "Euro 6d";

		case "EURO6T":
			return "Euro 6d-TEMP";

		default:
			return emissionClass;
	}
}

export const getScrollBarSize = () => {
	let inner = document.createElement('p');
	inner.style.width = "100%";
	inner.style.height = "100%";

	let outer = document.createElement('div');
	outer.style.position = "absolute";
	outer.style.top = "0px";
	outer.style.left = "0px";
	outer.style.visibility = "hidden";
	outer.style.width = "100px";
	outer.style.height = "100px";
	outer.style.overflow = "hidden";
	outer.appendChild(inner);  

	document.body.appendChild(outer);

	let w1 = inner.offsetWidth;
	let h1 = inner.offsetHeight;
	outer.style.overflow = 'scroll';  
	let w2 = inner.offsetWidth;  
	let h2 = inner.offsetHeight;
	if (w1 == w2) w2 = outer.clientWidth;
	if (h1 == h2) h2 = outer.clientHeight;   

	document.body.removeChild(outer);  

	return [(w1 - w2), (h1 - h2)];  
}

export const getParameterByName = (name, url) => {
	if (!url) {
		url = window.location.href;
	}

	name = name.replace(/[\[\]]/g, "\\$&");

	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);

	if (!results) return null;
	if (!results[2]) return '';

	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function calcMaxSliderValue(value) {
	if(value > 0) {
		let zehnerValue = '' + value;

		zehnerValue = zehnerValue.substr((zehnerValue.length -2));
		zehnerValue = parseInt(zehnerValue);

		if(zehnerValue !== 50 && zehnerValue !== 0) {
			if(zehnerValue > 50) {
				let addToValue = 100 - zehnerValue;
				value = value + addToValue;
			} else {
				let addToValue = 50 - zehnerValue;
				value = value + addToValue;
			}
		}
	}

	return value;
};

export const formatSearchFilterData = (filterData, searchData, searchMeta, isAnfrage = false) => {
	let newFilterData = Object.assign({}, filterData);

	newFilterData.searchClass = searchMeta.searchClass;

	if(isAnfrage && filterData.brand) {
		let brandValues = [];

		filterData.brand.map(brand => {
			brandValues.push(brand.key);
		});
		newFilterData.brand = brandValues;
	}

	if(filterData.model) {
		let models = [];

		Object.keys(filterData.model).map(key => {
			if(isAnfrage) {
				filterData.model[key].map(model => {
					models.push(model);
				})
			}
			else { models.push({"brand": key, "models": filterData.model[key]}); }
		});

		newFilterData.model = models;
	}

	if(filterData.modelSeries) {
		let modelSeries = [];

		Object.keys(filterData.modelSeries).map(key => {
			if(isAnfrage) {
				filterData.modelSeries[key].map(modelSerie => {
					modelSeries.push(modelSerie);
				})
			}
			else { modelSeries.push({"brand": key, "modelSeries": filterData.modelSeries[key]}); }
		});

		newFilterData.modelSeries = modelSeries;
	}

	if(filterData.height) {
		let newHeight = [];

		if(filterData.height[0] === 0){ newHeight.push(null); }
		else { newHeight.push(filterData.height[0]); }
		if(filterData.height[1] === searchData.maxHeight){ newHeight.push(null); }
		else { newHeight.push(filterData.height[1]); }

		newFilterData.height = newHeight;
	}
	if(filterData.km) {
		let newKm = [];

		if(filterData.km[0] === 0){ newKm.push(null); }
		else { newKm.push(filterData.km[0]); }
		if(filterData.km[1] === settings.kmMax){ newKm.push(null); }
		else { newKm.push(filterData.km[1]); }

		newFilterData.km = newKm;
	}
	if(filterData.length) {
		let newLength = [];

		if(filterData.length[0] === 0){ newLength.push(null); }
		else { newLength.push(filterData.length[0]); }
		if(filterData.length[1] === searchData.maxLength){ newLength.push(null); }
		else { newLength.push(filterData.length[1]); }

		newFilterData.length = newLength;
	}
	if(filterData.power) {
		let newPower = [];

		if(filterData.power[0] === 0){ newPower.push(null); }
		else { newPower.push(filterData.power[0]); }
		if(filterData.power[1] === searchData.maxPower){ newPower.push(null); }
		else { newPower.push(filterData.power[1]); }

		newFilterData.power = newPower;
	}
	if(filterData.price) {
		let newPrice = [];

		if(filterData.price[0] === 0){ newPrice.push(null); }
		else { newPrice.push(filterData.price[0]); }
		if(filterData.price[1] === settings.priceMax){ newPrice.push(null); }
		else { newPrice.push(filterData.price[1]); }

		newFilterData.price = newPrice;
	}
	if(filterData.reg) {
		let newReg = [];

		if(filterData.reg[0] === 1988){ newReg.push(null); }
		else { newReg.push(filterData.reg[0]); }
		if(filterData.reg[1] === settings.regMax){ newReg.push(null); }
		else { newReg.push(filterData.reg[1]); }

		newFilterData.reg = newReg;
	}
	if(filterData.weight) {
		let newWeight = [];

		if(filterData.weight[0] === 0){ newWeight.push(null); }
		else { newWeight.push(filterData.weight[0]); }
		if(filterData.weight[1] === searchData.maxWeight){ newWeight.push(null); }
		else { newWeight.push(filterData.weight[1]); }

		newFilterData.weight = newWeight;
	}
	if(filterData.width) {
		let newWidth = [];

		if(filterData.width[0] === 0){ newWidth.push(null); }
		else { newWidth.push(filterData.width[0]); }
		if(filterData.width[1] === searchData.maxWidth){ newWidth.push(null); }
		else { newWidth.push(filterData.width[1]); }

		newFilterData.width = newWidth;
	}
	if(filterData.category === "" || filterData.category && filterData.category.length === 0) { newFilterData.category = []; }
	if(filterData.usageType === "" || filterData.usageType && filterData.usageType.length === 0) { newFilterData.usageType = []; }
	if(filterData.features && filterData.features.OTHER_FUEL && filterData.features.OTHER_FUEL !== "" && !Array.isArray(filterData.features.OTHER_FUEL)) {
		let features = Object.assign({}, filterData.features);
		features.OTHER_FUEL = [features.OTHER_FUEL];
		newFilterData.features = features;
	}

	return newFilterData;
}

export const formatThousand = (number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}