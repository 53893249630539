import React from 'react';

import Tag from "antd/es/tag";

const VehicleHighlights = ({ highlights }) => {
	if(highlights.some(highlight => highlight && highlight !== "")) {
		return (
			<div className={"row tagsList"}>
				{highlights.map(highlight => {
					return highlight !== "" && <Tag key={highlight}>{highlight}</Tag>
				})}
			</div>
		)
	} else {
		return (null);
	}
};

export default VehicleHighlights;