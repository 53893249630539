import { createStore, applyMiddleware, compose } from 'redux';
import { autoRehydrate } from 'redux-persist'
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

export default function configureStore(initialState) {
	return createStore(
		rootReducer,
		initialState,
		compose(
			applyMiddleware(thunk),
			autoRehydrate()
		)
	);
};