import React, { Component } from "react";
import PropTypes from "prop-types";
import {Checkbox, Select} from "antd";

import SelectSearch from "components/form/input/SelectSearch";
import SliderSearch from "components/form/input/SliderSearch";
import BrandSelect from "components/form/input/BrandSelect";
import RadioSearch from "../../components/form/input/RadioSearch";

const PS = 1.35962;

class SearchForm extends Component {

	static propTypes = {
		// Objects
		searchFilter: PropTypes.object.isRequired,
		searchData: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
		settings: PropTypes.object.isRequired,

		// Functions
		handleCheckboxChange: PropTypes.func.isRequired,
		handleChange: PropTypes.func.isRequired,
		toggleNewCheckbox: PropTypes.func.isRequired,

		// State
		newFilterDisabled: PropTypes.bool.isRequired,
	}

	render() {
		let { searchFilter, searchData, searchMeta, settings } = this.props;
		let todayYear = new Date().getFullYear();

		return (
			<div>
				{/* Neu, Gebraucht, Oldtimer... */}
				{((searchData.conditions.find(condition => condition.key === "NEW") && searchData.conditions.find(condition => condition.key === "USED")) ||
				(searchFilter.condition && searchFilter.condition.includes("USED") && searchData.conditions.find(condition => condition.key === "USED")) ||
				(searchData.categories && searchData.categories.length > 1)) &&
					<div className="row m-margin-1" style={{minHeight: "58px"}}>
						{(searchData.conditions.find(condition => condition.key === "NEW") && searchData.conditions.find(condition => condition.key === "USED")) &&
						<div className="tcol-2 tcol-m-50" style={{
							height: "86.02px",
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center"
						}}>
							<Checkbox
								style={{display: "inline-flex"}}
								onChange={this.props.handleCheckboxChange}
								name="NEW"
								checked={searchFilter.condition && searchFilter.condition.includes("NEW")}
								disabled={this.props.newFilterDisabled}
							/>
							<div style={{display: "inline", marginLeft: "10px"}}
								 onClick={() => this.props.handleCheckboxChange(null, "NEW")}>Neu
							</div>

							<Checkbox
								style={{display: "inline-flex", marginLeft: "50px"}}
								onChange={this.props.handleCheckboxChange}
								name="USED"
								checked={searchFilter.condition && searchFilter.condition.includes("USED")}
							/>
							<div style={{display: "inline", marginLeft: "10px"}}
								 onClick={() => this.props.handleCheckboxChange(null, "USED")}>Gebraucht
							</div>
						</div>
						}

						{(searchFilter.condition && searchFilter.condition.includes("USED") && searchData.conditions.find(condition => condition.key === "USED")) ?
							<div className="tcol-2">
								<span className="label">{searchMeta.searchClass === "Car" ? "Gebrauchtwagenart" : "Gebrauchtfahrzeugart"}</span>
								<SelectSearch
									name="usageType"
									placeholder={searchMeta.searchClass === "Car" ? "Oldtimer, Vorführwagen..." : "Oldtimer, Vorführfahrzeug..."}
									actions={this.props.actions}
									value={searchFilter.usageType}
									valueList={searchData.usageTypes}
									multiple={true}
									handleChange={this.props.handleChange}
									toggleNewCheckbox={this.props.toggleNewCheckbox}
									newFilterDisabled={this.props.newFilterDisabled}
								/>
							</div>
							: null}

						{searchData.categories && searchData.categories.length > 1 &&
						<div className="tcol-2">
							<span className="label">Fahrzeugtyp</span>
							<SelectSearch
								name="category"
								actions={this.props.actions}
								value={searchFilter.category}
								valueList={searchData.categories}
								multiple={true}
								handleChange={this.props.handleChange}
								searchClass={searchMeta.searchClass}
							/>
						</div>
						}
					</div>
				}

				{/* Marke, Modell, Karosserieform */}
				<BrandSelect
					searchFilter={searchFilter}
					searchData={searchData}
					searchMeta={searchMeta}
					handleChange={this.props.handleChange}
					actions={this.props.actions}
				/>

				{/* Preis, Erstzulassung, Kilometerstand */}
				<div className="row">
					<div className="tcol-2">
						<span className="label">Preis</span>
						<SliderSearch
							name="price"
							range={true}
							valueMin={0}
							valueMax={settings.priceMax}
							value={searchFilter.price ? searchFilter.price[1] !== 0 ? searchFilter.price : [searchFilter.price[0], settings.priceMax] : [0, settings.priceMax]}
							actions={this.props.actions}
							step={500}
							tipFormat="€"
							handleChange={this.props.handleChange}
						/>
						<div className="slider-info">
							<span className="left">{searchFilter.price ? searchFilter.price[0] : "0"} €</span>
							<span className="right">{searchFilter.price && searchFilter.price[1] != settings.priceMax ? searchFilter.price[1] + " €" : "Unbegrenzt"}</span>
						</div>
					</div>

					{((searchMeta.searchClass !== "AgriculturalVehicle") || (this.props.searchResults && this.props.searchResults.find(result => result.firstRegistration !== null))) &&
						<div className="tcol-2">
							<span className="label">Erstzulassung</span>
							<SliderSearch
								name="reg"
								range={true}
								valueMin={1988}
								valueMax={settings.regMax}
								value={searchFilter.reg ? searchFilter.reg[1] !== 0 ? searchFilter.reg : [searchFilter.reg[0], settings.regMax] : [1988, settings.regMax]}
								actions={this.props.actions}
								step={1}
								handleChange={this.props.handleChange}
							/>
							<div className="slider-info">
								<span
									className="left">{searchFilter.reg && searchFilter.reg[0] != 1988 ? searchFilter.reg[0] : "Unbegrenzt"}</span>
								<span
									className="right">{searchFilter.reg ? searchFilter.reg[1] : settings.regMax}</span>
							</div>
						</div>
					}

					{((searchMeta.searchClass !== "AgriculturalVehicle") || (this.props.searchResults && this.props.searchResults.find(result => result.mileage !== "0 km"))) &&
						<div className="tcol-2">
							<span className="label">Kilometerstand</span>
							<SliderSearch
								name="km"
								range={true}
								valueMin={0}
								valueMax={settings.kmMax}
								value={searchFilter.km ? searchFilter.km[1] !== 0 ? searchFilter.km : [searchFilter.km[0], settings.kmMax] : [0, settings.kmMax]}
								actions={this.props.actions}
								step={500}
								tipFormat="km"
								handleChange={this.props.handleChange}
							/>
							<div className="slider-info">
								<span className="left">{searchFilter.km ? searchFilter.km[0] : "0"} km</span>
								<span
									className="right">{searchFilter.km && searchFilter.km[1] != settings.kmMax ? searchFilter.km[1] + " km" : "Unbegrenzt"}</span>
							</div>
						</div>
					}
					{searchMeta.searchClass === "AgriculturalVehicle" &&
						<div>
							<div className="tcol-2">
								<span className="label">Leistung</span>
								<SliderSearch
									name="power"
									range={true}
									valueMin={0}
									valueMax={searchData.maxPower}
									value={searchFilter.power ? searchFilter.power[1] !== 0 ? searchFilter.power : [searchFilter.power[0], searchData.maxPower] : [0, searchData.maxPower]}
									actions={this.props.actions}
									step={10}
									tipFormat="kW"
									handleChange={this.props.handleChange}
								/>
								<div className="slider-info">
									<span className="left">{searchFilter.power ? searchFilter.power[0] : "0"} kW ({Math.floor((searchFilter.power ? searchFilter.power[0] : 0) * PS)} PS)</span>
									<span className="right">{searchFilter.power && searchFilter.power[1] != 0 ? searchFilter.power[1] + ` kW (${Math.floor(searchFilter.power[1] * PS)} PS)` : "Unbegrenzt"}</span>
								</div>
							</div>
							<div className="tcol-2">
								<span className="label">Motorart</span>
								<SelectSearch
									name="motorart"
									actions={this.props.actions}
									value={searchFilter.motorart}
									valueList={searchData.motorarten}
									multiple={true}
									handleChange={this.props.handleChange}
								/>
							</div>
						</div>
					}
					{searchMeta.searchClass === "AgriculturalVehicle" &&
					<div>
						{searchData.maxLength > 0 &&
							<div className="tcol-2">
								<span className="label">Länge</span>
								<SliderSearch
									name="length"
									range={true}
									valueMin={0}
									valueMax={searchData.maxLength}
									value={searchFilter.length ? searchFilter.length[1] !== 0 ? searchFilter.length : [searchFilter.length[0], searchData.maxLength] : [0, searchData.maxLength]}
									actions={this.props.actions}
									step={50}
									tipFormat="mm"
									handleChange={this.props.handleChange}
								/>
								<div className="slider-info">
									<span className="left">{searchFilter.length && searchFilter.length[0] != 0 ? searchFilter.length[0] : 0} mm</span>
									<span className="right">{searchFilter.length && searchFilter.length[1] != 0 ? searchFilter.length[1] : searchData.maxLength} mm</span>
								</div>
							</div>
						}
						{searchData.maxWidth > 0 &&
							<div className="tcol-2">
								<span className="label">Breite</span>
								<SliderSearch
									name="width"
									range={true}
									valueMin={0}
									valueMax={searchData.maxWidth}
									value={searchFilter.width ? searchFilter.width[1] !== 0 ? searchFilter.width : [searchFilter.width[0], searchData.maxWidth] : [0, searchData.maxWidth]}
									actions={this.props.actions}
									step={50}
									tipFormat="mm"
									handleChange={this.props.handleChange}
								/>
								<div className="slider-info">
									<span className="left">{searchFilter.width && searchFilter.width[0] != 0 ? searchFilter.width[0] : 0} mm</span>
									<span className="right">{searchFilter.width && searchFilter.width[1] != 0 ? searchFilter.width[1] : searchData.maxWidth} mm</span>
								</div>
							</div>
						}
						{searchData.maxHeight > 0 &&
							<div className="tcol-2">
								<span className="label">Höhe</span>
								<SliderSearch
									name="height"
									range={true}
									valueMin={0}
									valueMax={searchData.maxHeight}
									value={searchFilter.height ? searchFilter.height[1] !== 0 ? searchFilter.height : [searchFilter.height[0], searchData.maxHeight] : [0, searchData.maxHeight]}
									actions={this.props.actions}
									step={50}
									tipFormat="mm"
									handleChange={this.props.handleChange}
								/>
								<div className="slider-info">
									<span className="left">{searchFilter.height && searchFilter.height[0] != 0 ? searchFilter.height[0] : 0} mm</span>
									<span className="right">{searchFilter.height && searchFilter.height[1] != 0 ? searchFilter.height[1] : searchData.maxHeight} mm</span>
								</div>
							</div>
						}
						{searchData.maxWeight > 0 &&
							<div className="tcol-2">
								<span className="label">zul. Gesamtgewicht</span>
								<SliderSearch
									name="weight"
									range={true}
									valueMin={0}
									valueMax={searchData.maxWeight}
									value={searchFilter.weight ? searchFilter.weight[1] !== 0 ? searchFilter.weight : [searchFilter.weight[0], searchData.maxWeight] : [0, searchData.maxWeight]}
									actions={this.props.actions}
									step={50}
									tipFormat="kg"
									handleChange={this.props.handleChange}
								/>
								<div className="slider-info">
									<span className="left">{searchFilter.weight && searchFilter.weight[0] != 0 ? searchFilter.weight[0] : 0} kg</span>
									<span className="right">{searchFilter.weight && searchFilter.weight[1] != 0 ? searchFilter.weight[1] : searchData.maxWeight} kg</span>
								</div>
							</div>
						}
					</div>
				}
				</div>

				{searchMeta.searchClass === "AgriculturalVehicle" &&
					<div className={"row"}>
						<div className="tcol-2">
							<span className="label">Unfallfrei</span>
							<RadioSearch
								name="accidentFree"
								actions={this.props.actions}
								value={searchFilter.accidentFree === undefined ? false : searchFilter.accidentFree}
								options={[
									{ value: true, displayName: "Unfallfrei" },
									{ value: false, displayName: "Beliebig" }
								]}
								handleChange={this.props.handleChange}
							/>
						</div>

						<div className="tcol-2">
							<span className="label">Unbeschädigt</span>
							<RadioSearch
								name="unrepaired"
								actions={this.props.actions}
								value={searchFilter.unrepaired === undefined ? false : searchFilter.unrepaired}
								options={[
									{ value: true, displayName: "Unbeschädigt" },
									{ value: false, displayName: "Beliebig" }
								]}
								handleChange={this.props.handleChange}
							/>
						</div>
					</div>
				}
			</div>
		);
	}

}

export default SearchForm;