import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as searchActions from "actions/searchActions";
import * as siteActions from "actions/siteActions";
import {Button, Form, Input, Col, Row, Select, Checkbox, Divider, Alert, Result} from "antd";
import axios from "axios";
import settings from "../settings";
import FormItem from "antd/es/form/FormItem";
import Cookies from "js-cookie";


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salutation: "",
            firstname: "",
            lastname: "",
            firma: "",
            anredeRequired: false,
            nameRequired: false,
            firmaRequired: false,
            requested: false,
            stayLoggedIn: false,
            mailLogin: "",
            mailRegister: "",
            oldMailRegister: "",
            error: false,
            message: "",
            changeData: false,
        }
    }

    componentDidMount() {
        if(sessionStorage.getItem("linkCode")) {
            if(this.props.addCodeUsed) { this.setState({error: true, message: "Die verwendete URL ist abgelaufen, bitte bestätigen Sie Ihre E-Mail erneut."}); }
            else { this.setState({error: false, message: "Bitte bestätigen Sie Ihre E-Mail."}); }
        }
    }

    onChange = (value, name) => {
        this.setState({
            [name]: value,
            error: false,
            message: "",
        });

        if(name === "salutation" && value !== "") {
            if(value !== undefined) {
                this.setState({
                    anredeRequired: true,
                    nameRequired: value !== "Firma" && value !== undefined ? true : false,
                    firmaRequired: value === "Firma" ? true : false
                });
            } else if(value === undefined && this.state.firstname === "" && this.state.lastname === "") {
                this.setState({
                    anredeRequired: false,
                    nameRequired: false,
                    firmaRequired: false
                });
            }
        }
        if((name === "firstname" || name === "lastname")) {
            if(value !== "") {this.setState({ anredeRequired: true, nameRequired: true, firmaRequired: false })}
            else if((this.state.firstname === "" || (name === "firstname" && value === "")) && (this.state.lastname === "" || (name === "lastname" && value === "")) && (this.state.salutation === undefined || this.state.salutation === "")) {
                this.setState({ anredeRequired: false, nameRequired: false, firmaRequired: false });
            }
        }
        if(name === "firma") {
            if(value !== "") {this.setState({ anredeRequired: true, nameRequired: false, firmaRequired: true })}
            else if(this.state.salutation === undefined || this.state.salutation === "") {this.setState({ anredeRequired: false, nameRequired: false, firmaRequired: false })}
        }
    }

    sendLogin = () => {
        let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(this.state.mailLogin === "") {
            this.setState({error: true, message: "Die E-Mail ist ein Pflichtfeld."});
            return;
        } else if(!regEx.test(this.state.mailLogin)) {
            this.setState({error: true, message: "Die angebene E-Mail ist ungültig."});
            return;
        }

        if(sessionStorage.getItem("linkCode")) {
            axios.post(`${settings.servicePath}/agreement/login/url`, {mail: this.state.mailLogin, linkCode: sessionStorage.getItem("linkCode")})
                .then((result) => {
                    if(result.data === "SUCCESS") {
                        if(this.state.stayLoggedIn) {
                            Cookies.set('mail', this.state.mailLogin, { expires: 90});
                            Cookies.set('linkCode', sessionStorage.getItem("linkCode"), { expires: 90});
                        } else {
                            sessionStorage.setItem("linkCode", sessionStorage.getItem("linkCode"));
                            sessionStorage.setItem("mail", this.state.mailLogin);
                        }

                        this.props.onSuccess(this.state.mail);
                    }
                    else {
                        sessionStorage.removeItem("linkCode");
                        sessionStorage.removeItem("addCode");
                        this.setState({error: true, message: "Die angegebene E-Mail befindet sich nicht in unserem System."});
                    }
                });
        } else {
            axios.post(`${settings.servicePath}/agreement/login/mail`, {mail: this.state.mailLogin})
                .then((result) => {
                    if(result.data === "FOUND") {
                        if(this.state.stayLoggedIn) { localStorage.setItem("stay", true); }
                        this.setState({mailFound: true, requested: true});
                        localStorage.setItem("loggedIn", true);
                    } else if(result.data === "NOT_FOUND") {
                        this.setState({
                            message: `Die angegebene E-Mail befindet sich nicht in unserem System.`,
                            error: true
                        });
                    } else {
                        this.setState({error: true, message: "Uns ist ein Fehler unterlaufen, bitte wiederholen Sie den Vorgang erneut."});
                    }
                });
        }
    }

    register = () => {
        let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(this.state.mailRegister === "") {
            this.setState({error: true, message: "E-Mail ist ein Pflichtfeld."});
            return;
        } else if(!regEx.test(this.state.mailRegister)) {
            this.setState({error: true, message: "Die angebene E-Mail ist ungültig."});
            return;
        } else if(this.state.nameRequired && (this.state.firstname === "" || this.state.lastname === "")) {
            this.setState({error: true, message: "Vorname und Nachname sind Pflichtfelder, wenn Sie Ihre Anrede angeben möchten."});
            return;
        } else if(this.state.anredeRequired && this.state.salutation === undefined) {
            this.setState({error: true, message: "Anrede ist ein Pflichtfeld, wenn Sie Ihren Namen angeben möchten."});
            return;
        } else if(this.state.firmaRequired && this.state.firma === "") {
            this.setState({error: true, message: "Firma ist ein Pflichtfeld, wenn Sie Ihre Anrede angeben möchten."});
            return;
        }

        axios.post(`${settings.servicePath}/agreement/register`, {
                mail: this.state.mailRegister,
                anrede: this.state.salutation,
                name: this.state.lastname,
                firstName: this.state.firstname,
                company: this.state.firma,
                oldMail: this.state.oldMailRegister
        })
        .then((result) => {
            if(result.data === "OK") {
                this.setState({requested: true, changeData: false});
                localStorage.setItem("loggedIn", true);
            }else if(result.data === "FOUND") {
                this.setState({error: true, message: "Die angegebene E-Mail befindet sich bereits in unserem System.", changeData: false});
            } else {
                this.setState({error: true, message: "Uns ist ein Fehler unterlaufen, bitte wiederholen Sie den Vorgang erneut.", changeData: false});
            }
        });
    }



    render() {
        if(sessionStorage.getItem("linkCode")) {
               return (
                   <div className="content">
                       {this.state.message !== "" &&
                           <Row gutter={24}>
                               <Col className={"gutter-row"} span={24}>
                                   <Alert
                                       message=""
                                       description={this.state.message}
                                       type={this.state.error ? "error" : "info"}
                                   />
                               </Col>
                           </Row>
                       }
                       <Row gutter={24}>
                           <Col className="gutter-row" span={24}>
                               <Form>
                                   <Form.Item
                                       label="E-Mail Adresse"
                                       name="mailLogin"
                                       style={{marginBottom: "0"}}
                                       required={true}
                                   >
                                       <Input value={this.state.mailLogin} onChange={(e) => this.onChange(e.target.value, "mailLogin")} />
                                   </Form.Item>
                                   <Form.Item
                                       name="stayLoggedIn"
                                       style={{marginBottom: "1rem"}}
                                   >
                                       <Checkbox checked={this.state.stayLoggedIn} onChange={(e) => this.setState({stayLoggedIn: e.target.checked})}>Angemeldet bleiben</Checkbox>
                                   </Form.Item>
                               </Form>
                           </Col>
                       </Row>
                       <Row gutter={24}>
                           <Col className="gutter-row" span={24}>
                               <Button onClick={this.sendLogin} type="primary">Senden</Button>
                           </Col>
                       </Row>
                   </div>
               )
            } else {

        return (
            !this.state.requested ?
                <div className="content">
                    <Row gutter={24}>
                        <Col className={"gutter-row"} span={24}>
                            <Divider orientation="left">INFORMATIONEN</Divider>
                            <p>- Speichern Sie beliebig viele Suchaufträge</p>
                            <p>- Parken Sie beliebig viele Fahrzeuge</p>
                            <p>- Anmeldung an unserem Benachrichtigungssystem.
                                Erfahren Sie sofort, wenn wir ein neues Fahrzeug passend zu Ihrem Suchauftrag in Bestand nehmen,
                                oder sobald eines Ihrer geparkten Fahrzeuge reduziert wird.
                            </p>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className={"gutter-row"} span={24}>
                            {this.state.error &&
                                <Alert
                                    message=""
                                    description={this.state.message}
                                    type="error"
                                />
                            }
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className="gutter-row" sm={24} md={12}>
                            <Divider orientation="left">ANMELDEN</Divider>
                            <Form>
                                <Form.Item
                                    label="E-Mail Adresse"
                                    name="mailLogin"
                                    style={{marginBottom: "0"}}
                                    required={true}
                                >
                                    <Input value={this.state.mailLogin} onChange={(e) => this.onChange(e.target.value, "mailLogin")} />
                                </Form.Item>
                                <Form.Item
                                    name="stayLoggedIn"
                                    style={{marginBottom: "1rem"}}
                                >
                                    <Checkbox checked={this.state.stayLoggedIn} onChange={(e) => this.setState({stayLoggedIn: e.target.checked})}>Angemeldet bleiben</Checkbox>
                                </Form.Item>
                            </Form>
                            <Button onClick={this.sendLogin} type="primary">Anmelden</Button>
                        </Col>
                        <Col className="gutter-row" sm={24} md={12}>
                            <Divider orientation="left">REGISTRIEREN</Divider>
                            <Form>
                                <Form.Item
                                    label="E-Mail Adresse"
                                    name="mailRegister"
                                    style={{marginBottom: "0"}}
                                    required={true}
                                >
                                    <Input value={this.state.mailRegister} onChange={(e) => this.onChange(e.target.value, "mailRegister")} />
                                </Form.Item>
                                <Row gutter={24}>
                                    <Col className="gutter-row" sm={24} md={7}>
                                        <FormItem label={`Anrede${!this.state.anredeRequired ? " (Optional)" : ""}`} style={{marginBottom: "0"}} required={this.state.anredeRequired}>
                                            <Select
                                                placeholder="Anrede"
                                                allowClear={true}
                                                onChange={(value) => this.onChange(value, "salutation")}
                                                value={this.state.salutation}
                                            >
                                                <Select.Option value="Herr">Herr</Select.Option>
                                                <Select.Option value="Frau">Frau</Select.Option>
                                                <Select.Option value="Divers">Divers</Select.Option>
                                                <Select.Option value="Firma">Firma</Select.Option>
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                {this.state.salutation !== "Firma" ?
                                    <Row gutter={24}>
                                        <Col className="gutter-row" sm={24} md={12}>
                                            <Form.Item
                                                label={`Vorname${!this.state.nameRequired ? " (Optional)" : ""}`}
                                                name="firstname"
                                                style={{marginBottom: "0"}}
                                                required={this.state.nameRequired}
                                            >
                                                <Input value={this.state.firstname} onChange={(e) => this.onChange(e.target.value, "firstname")} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" sm={24} md={12}>
                                            <Form.Item
                                                label={`Nachname${!this.state.nameRequired ? " (Optional)" : ""}`}
                                                name="lastname"
                                                style={{marginBottom: "1rem"}}
                                                required={this.state.nameRequired}
                                            >
                                                <Input value={this.state.lastname} onChange={(e) => this.onChange(e.target.value, "lastname")} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    :
                                    <Form.Item
                                        label={`Firmenname${!this.state.firmaRequired ? " (Optional)" : ""}`}
                                        name="firma"
                                        style={{marginBottom: "1rem"}}
                                        required={this.state.firmaRequired}
                                    >
                                        <Input value={this.state.firma} onChange={(e) => this.onChange(e.target.value, "firma")} />
                                    </Form.Item>
                                }
                            </Form>
                            <Button onClick={this.register} type="primary">Registrieren</Button>
                        </Col>
                    </Row>
                </div>
            :
                <div className={"content"}>
                    <Result
                        status="success"
                        title={`Bitte bestätigen Sie den Link, den wir an ${this.state.mailLogin !== "" ? this.state.mailLogin : this.state.mailRegister} versendet haben.
                            Haben Sie keine E-Mail erhalten klicken Sie auf Angaben ändern, wenn die angegebene E-Mail nicht korrekt ist.`}
                        extra={[
                            <Button key={"change-btn"} onClick={() => this.setState({requested: false, mailFound: undefined, oldMailRegister: this.state.mailRegister})} type="primary">Angaben ändern</Button>
                        ]}
                    />
                </div>
        );
    }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        search: state.search,
        site: state.site
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(searchActions, dispatch),
        siteActions: bindActionCreators(siteActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);