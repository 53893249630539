import React from 'react';

const Disclaimer = () => (
	<div className="container emission-disclaimer">
		<div className="row">
			<div className="tcol-6">
				<p>* Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können Sie dem ‚Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer Personenkraftwagen‘ entnommen werden, der an allen Verkaufsstellen und bei der „Deutschen Automobil Treuhand GmbH“ unter <a href="http://www.dat.de" target="_blank">www.dat.de</a> unentgeltlich erhältlich sind.</p>
				<p>Die Angaben zu Kraftstoffverbrauch, CO2-Emissionen und Stromverbrauch wurden nach dem vorgeschriebenen Messverfahren VO (EU) 715/2007 in der jeweils geltenden Fassung ermittelt. Die Angaben berücksichtigen bei Spannbreiten Unterschiede in der gewählten Rad- und Reifengröße.</p>
			</div>
		</div>
	</div>
);

export default Disclaimer;