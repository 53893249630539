import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import update from 'react-addons-update';
import {Drawer, Modal} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import settings from 'settings';
import * as helper from 'helper';
import * as resultActions from 'actions/resultActions';
import * as siteActions from 'actions/siteActions';
import SuggestionList from 'components/SuggestionList';
import AuerGoogleMap from 'components/layout/AuerGoogleMap';
import ContactForm from 'components/form/ContactForm';
import Footer from 'components/layout/Footer';
import Section from 'components/layout/Section';

import ResultHeader from 'containers/Result/ResultHeader';
import VehicleTitle from 'containers/Result/VehicleTitle';
import VehicleDisplay from 'containers/Result/VehicleDisplay';
import VehicleSpecifications from 'containers/Result/VehicleSpecifications';
import VehicleDescription from 'containers/Result/VehicleDescription';
import VehicleEmissionInfo from 'containers/Result/VehicleEmissionInfo';
import VehicleFeatures from 'containers/Result/VehicleFeatures';
import Disclaimer from 'containers/Result/Disclaimer';
import PropTypes from "prop-types";
import Button from "antd/es/button";
import axios from "axios";
import Comparsion from "./Comparsion";
import Cookies from "js-cookie";
import jump from "jump.js";
import {easeInOutQuart} from "ez.js";

let initialFormState = {
	salutation: "",
	firstName: "",
	name: "",
	company: "",
	address: "",
	zip: "",
	city: "",
	country: "",
	phonePrivate: "",
	phoneBusiness: "",
	phoneMobile: "",
	fax: "",
	email: "",
	contact: "",
	sendCopy: false,
	text: "",
	isTestDriveRequest: false,
	errors: {},
	notRegistered: false
};

class Result extends Component {
	static propTypes = {
		// Objects
		searchData: PropTypes.object.isRequired
	}

	constructor(props) {
		super(props);
		this.state = {
			financingModal: false,
			chfPricingModal: false,
			financing: null,
			imgAutoPlay: false,
			form: Object.assign({}, initialFormState),
			parkedState: null,
			visible: false
		};
	}

	toggleModal = (name, financing = null) => {
		let value = !this.state[name];
		this.setState(prev => update(prev, {
			[name]: { $set: value },
			financing: { $set: financing }
		}), () => {
			document.body.style.overflow = this.state[name] ? "hidden" : "auto";
			document.body.style.paddingRight = this.state[name] ? helper.getScrollBarSize()[0] + "px" : "0px";
		});
	}

	closeModal = (name) => {
		this.setState(prev => update(prev, {
			[name]: { $set: false }
		}), () => {
			document.body.style.overflow = this.state[name] ? "hidden" : "auto";
			document.body.style.paddingRight = this.state[name] ? helper.getScrollBarSize()[0] + "px" : "0px";
		});
	}

	handleFieldChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState(prev => update(prev, {
			form: {
				[name]: { $set: value }
			}
		}));
	}

	handleSelectChange = (value, name) => {
		this.setState(prev => update(prev, {
			form: {
				[name]: { $set: value }
			}
		}));
	}

	handleCheckboxChange = (e) => {
		let name = e.target.name;
		this.setState(prev => update(prev, {
			form: {
				[name]: { $set: !this.state[name] }
			}
		}));
	}

	handleSwitchChange = (name, value) => {
		this.setState(prev => update(prev, {
			form: {
				[name]: { $set: value }
			}
		}));
	}

	handleSubmit = (body, vehiclePrice) => {
		return new Promise((resolve, reject) => {
			let emailBody = Object.assign({}, body);
			let filterData = Object.assign({}, this.state.prevSearchFilter);

			if(emailBody.isTestDriveRequest) {
				emailBody.inquiryType = "2";
			}

			delete filterData.backToList;
			delete filterData.pageSize;
			delete filterData.searchPage;
			delete filterData.sortDescending;
			delete filterData.sortField;

			emailBody.filter = helper.formatSearchFilterData(filterData, this.props.search.searchData, this.props.search.searchMeta);
			emailBody.preisVorschlag = parseFloat(vehiclePrice.replace(".", "").replace(",", "."));

			delete emailBody.errors;
			delete emailBody.isTestDriveRequest;

			this.props.actions.sendMail(emailBody, this.props.result.vehicle.data.adId)
				.catch(error => {
					reject(error);
				})
				.then(() => {
					if(this.props.result.mail.success) {
						//message.success(this.props.result.mail.msg);
						resolve(this.props.result.mail.msg);
						helper.sendAnalyticsEvent("Fahrzeug", "Fahrzeuganfrage", this.props.result.vehicle.data.adId);
					} else {
						//message.error(this.props.result.mail.msg);
						reject(this.props.result.mail.msg);
					}
				});
		});
	}

	handleReset = () => {
		this.setState(prev => update(prev, {
			form: { $set: initialFormState }
		}));
	}

	loadVehicle = (adId) => {
		this.props.actions.loadSingleVehicle(adId)
		.then(({ vehicle, vehicleText }) => {
			this.setState(prev => update(prev, {
				form: {
					text: { $set: vehicleText }
				}
			}), () => {
				if(this.props.site.isLoading) {
					// Kleiner Delay, weil das DOM noch beschäftigt ist, die
					// Seite aufzubauen.
					setTimeout(() => {
						this.props.siteActions.setPageLoadingStatus(false);
					}, 100);
				}
			});
			helper.sendAnalyticsEvent("Fahrzeug", "Fahrzeugaufruf", this.props.result.vehicle.data.adId);
		});
	}

	scrollToResult = () => {
		document.getElementById("contact-request").scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
	}

	componentWillMount() {
		this.loadVehicle(this.props.match.params.vehicleId);
	}

	componentDidMount() {
		axios.post(`${settings.servicePath}/kpi/page?page=vehicle`);
		
		let searchMetaFilter = JSON.parse(localStorage.getItem("SEARCH_META_STATE"));
		let searchFilterState = JSON.parse(localStorage.getItem("SEARCH_FILTER_STATE"));
		let prevSearchFilter;

		if(searchMetaFilter || searchFilterState) {
			prevSearchFilter = searchFilterState ? searchFilterState : {};
			prevSearchFilter.backToList = true;
			prevSearchFilter.searchPage = searchMetaFilter.page;
			prevSearchFilter.pageSize = searchMetaFilter.pageSize;
			prevSearchFilter.searchClass = searchMetaFilter.searchClass;
			prevSearchFilter.sortDescending = searchMetaFilter.sortDescending;
			prevSearchFilter.sortField = searchMetaFilter.sortField;
		}

		this.setState({prevSearchFilter: prevSearchFilter, filterToResetSearch: prevSearchFilter});

		localStorage.setItem("lastMountedComponent", "Result");
		localStorage.setItem("adID", this.props.match.params.vehicleId);
		window.scrollTo(0, 0);

		if((sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail"))) {
			const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");
			const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

			axios.post(`${settings.servicePath}/customerpanel/parked/vehicle/${this.props.match.params.vehicleId}`, {
				mail: mail,
				linkCode: linkCode
			})
				.then((result) => {
					this.setState({parkedState: result.data});
				});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.match.params.vehicleId !== nextProps.match.params.vehicleId) {
			this.loadVehicle(nextProps.match.params.vehicleId);
		}
	}

	componentWillUnmount() {
		this.props.actions.resetVehicle();
	}

	async onPark(e, adId) {
		e.preventDefault();

		const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");
		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		if(!mail && !linkCode) {
			this.setState({notRegistered: true});
		}

		if(!this.state.parkedState && !this.props.isParkedVehicleSite) {
			axios.post(`${settings.servicePath}/ad/${adId}/park`, {mail: mail, linkCode: linkCode})
				.then((result) => {
					if(result.data == "OK") {
						axios.post(`${settings.servicePath}/customerpanel/parked/vehicle/${adId}`, {
							mail: mail,
							linkCode: linkCode
						})
							.then((result) => {
								this.setState({parkedState: result.data});
								this.props.updateParkedVehicles(true);
								this.props.vehicleParked();
							});
					}
				});
		} else {
			const kundenMailParkedVehicleId = !this.props.isParkedVehicleSite ? this.state.parkedState.kundenMailParkedVehicleId : this.props.vehicle.kundenMailParkedVehicleId;

			axios.post(`${settings.servicePath}/customerpanel/parked/vehicle/${kundenMailParkedVehicleId}/delete`, {mail: mail, linkCode: linkCode})
				.then((result) => {
					if(result.data == "OK") {
						this.setState({parkedState: null});
						this.props.updateParkedVehicles(false);
						this.props.vehicleParkedOut();
					}
				});
		}
	}

	onClickCompare = () => {
		if(this.state.visible) {
			this.setState({visible: false});
		} else {
			//this.props.store.dispatch(resultActions.loadComparsionVehicles(this.state.comparsionVehicles));
			this.setState({visible: true});
		}
	}

	onOpenDrawer = () => {
		this.setState({visible: true});
	}

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.site.comparsionVehicles !== this.props.site.comparsionVehicles && this.props.site.comparsionVehicles.length === 0) {
			this.setState({visible: false});
		}
	}

	onClickVorschlag = () => {
		jump("#contact-request", {
			duration: 1500,
			offset: -25,
			easing: easeInOutQuart
		});
	}

	render() {
		// Fahrzeug hat noch nicht geladen.
		if(this.props.result.vehicle.loading) {
			return null;
		}

		// Fehler beim Laden.
		if(this.props.result.vehicle.error) {
			return (
				<div className="no-result">
					<h1>Dieses Fahrzeug wurde nicht gefunden.</h1>
					<a href={`../`}>Zurück zur Suche</a>
				</div>
			)
		}

		let images = [];
		let vehicle = this.props.result.vehicle.data;
		let panoramaImages = vehicle.panoramaInterior;
		let showThumbnails = vehicle.images.length > 1;
		let defaultImage = settings.imagePath + settings.fallbackImage;
		let visibilityHeight = window.innerWidth > 750 ? -1 : 100;

		vehicle.images.map(item => {
			images.push({ original: `${settings.imagePath}${item}`, thumbnail: `${settings.imagePath}${item}` });
		});

		 // Default image if no image is available.
		if (images.length == 0) images.push({original: defaultImage, thumbnail: defaultImage });

		return (
			<div id="vehicle-fullpage" style={{opacity: document.getElementById("cookie-banner") && ".5"}}>
				<Helmet>
					<title>{vehicle.make + " " + vehicle.shortDescription} | Fahrzeugmarkt - Auer Gruppe</title>
					<meta name="description" content={vehicle.description} />
					<meta name="keywords" content="Fahrzeugmarkt, Auer Gruppe, Fahrzeugbörse, BMW, BMW i, BMW M, Jaguar, Land Rover, Lotus, KTM, Bodensee, Autohaus, Stockach" />

					<meta property="og:type" content="website" />
					<meta property="og:url" content={`https://fahrzeugmarkt.auer-gruppe.de/fahrzeug/${vehicle.adId}`} />
					<meta property="og:title" content="Fahrzeugmarkt | Auer Gruppe" />
					<meta property="og:image" content={images[0].original} />
					<meta property="og:description" content={vehicle.description} />
					<meta property="og:site_name" content="Fahrzeugmarkt" />
					<meta property="og:locale" content="de_DE" />
				</Helmet>
				<Modal footer={null} okButtonProps={{ display: "none" }} style={{width: "fit-content"}} title="Fahrzeug kann nicht geparkt werden" visible={this.state.notRegistered} onCancel={() => this.setState({notRegistered: false})}>
					<p>Um ein Fahrzeug zu parken müssen Sie sich mit Ihrer E-Mail Adresse in unserem System anmelden.</p>

					<h2>Ihre Vorteile</h2>

					<p>- Beliebig viele Fahrzeuge parken<br/>
					- Beliebig viele Suchaufträge speichern</p>
					<p>- Anmeldung an unserem Benachrichtigungssystem.<br/>
					Erfahren Sie sofort, wenn wir ein neues Fahrzeug passend zu Ihrem Suchauftrag in Bestand nehmen,
					oder bei Preissenkungen an einem von Ihnen geparkten Fahrzeug.</p>

					<Button style={{marginTop: ".5em"}} type={"primary"} onClick={(e) => this.props.history.push(`/customerpanel`)}>Zur Anmeldung</Button>
					<Button style={{marginLeft: ".5em"}} onClick={() => this.setState({notRegistered: false})}>Abbrechen</Button>
				</Modal>
				<ResultHeader
					history={this.props.history}
					vehicle={vehicle}
					financing={this.state.financing}
					visibilityHeight={visibilityHeight}
					chfPricingModal={this.state.chfPricingModal}
					financingModal={this.state.financingModal}
					closeModal={this.closeModal}
					siteActions={this.props.siteActions}
					prevSearchFilter={this.state.prevSearchFilter}
					parkedVehicleCount={this.props.parkedVehicleCount}
					searchRequestsCount={this.props.searchRequestsCount}
				/>
				<div className="container" id="main">
					<VehicleTitle
						make={vehicle.make}
						shortDescription={vehicle.shortDescription}
						unrepaired={vehicle.unrepaired}
						dealersOnly={vehicle.dealersOnly}
					/>
					<div className="row no-margin">
						<div className="tcol-4 no-padding">
							<VehicleDisplay
								vehicle={vehicle}
								images={images}
								panoramaImages={panoramaImages}
								imgAutoPlay={this.state.imgAutoPlay}
								showThumbnails={showThumbnails}
								toggleModal={this.toggleModal}
								parkedState={this.state.parkedState}
								onPark={(e) => this.onPark(e, vehicle.adId)}
								onVorschlag={this.onClickVorschlag}
							/>
						</div>
						<div className="tcol-2 no-padding">
							<VehicleSpecifications vehicle={vehicle} />
						</div>
					</div>
				</div>
				<VehicleDescription
					description={vehicle.description}
					phoneNumber={vehicle.contactInfo.phone}
				/>
				{(vehicle.emissionFuelConsumption && (vehicle.emissionFuelConsumption.energyEfficiencyClass || vehicle.emissionFuelConsumption.consumptionInner || vehicle.emissionFuelConsumption.consumptionOuter || vehicle.emissionFuelConsumption.consumptionCombined || vehicle.emissionFuelConsumption.co2Emission) || helper.renderEmissionClass(vehicle.emissionClass) || (vehicle.emissionSticker && vehicle.emissionSticker !== "Keine")) &&
					<VehicleEmissionInfo
						emissionFuelConsumption={vehicle.emissionFuelConsumption}
						site={vehicle.contactInfo.site}
						emissionClass={vehicle.emissionClass}
						emissionSticker={vehicle.emissionSticker}
					/>
				}

				<br />
				<VehicleFeatures features={vehicle.features} categorizedFeatures={vehicle.categorizedFeatures} />
				<br />
				<AuerGoogleMap filialeId={vehicle.contactInfo.site} />
				<Section
					containerId="contact-request"
					title="Kontakt aufnehmen"
				>
					<ContactForm sendForm={this.handleSubmit} vehiclePrice={vehicle.price} vehicleText={this.state.form.text} contactPhone={vehicle.contactInfo.phone} showResult={this.scrollToResult} />
				</Section>
				<br />
				<SuggestionList
					interesting={this.props.result.interesting}
					resultActions={this.props.actions}
					siteActions={this.props.siteActions}
				/>
				<Disclaimer vehicle={vehicle} />
				<Drawer
					title="Fahrzeuge Vergleichen"
					placement={window.innerWidth >= 801 ? "right" : "bottom"}
					closable={true}
					onClose={this.onClose}
					visible={this.props.site.comparsionVehicles.length > 0 && this.state.visible}
					key={"comparsion-drawer"}
					height={"100%"}
					width={"fit-content"}
				>
					<Comparsion searchClass={this.props.search.searchMeta.searchClass} closeDrawer={() => this.setState({visible: false})} comparsionVehicles={this.props.site.comparsionVehicles} history={this.props.history} siteActions={this.props.siteActions} />
				</Drawer>
				<div style={{cursor: "pointer", display: this.props.site.comparsionVehicles.length > 0 ? "block" : "none"}} className={"comparsion-vehicle"} onClick={this.onClickCompare}>
					Vergleichsfahrzeuge
				</div>
				<Footer />
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		result: state.result,
		site: state.site,
		search: state.search
	};
}

function mapDispatchToProps(dispatch) {
    return {
		actions: bindActionCreators(resultActions, dispatch),
		siteActions: bindActionCreators(siteActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Result);