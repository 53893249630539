import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectSearch from 'components/form/input/SelectSearch';
import ColorSelect from 'components/color-select/ColorSelect';
import SliderSearch from 'components/form/input/SliderSearch';
import RadioSearch from 'components/form/input/RadioSearch';
import DetailSearchFeatures from 'containers/Search/DetailSearchFeatures';
// import 'react-accessible-accordion/dist/fancy-example.css';

class DetailSearch extends Component {
	constructor() {
		super();
		this.state = {
			toggledPanels: []
		}
	}

	static propTypes = {
		// Objects
		searchFilter: PropTypes.object.isRequired,
		searchData: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
		settings: PropTypes.object.isRequired,
		toggledPanels: PropTypes.array.isRequired,

		// Functions
		toggleDetailedSearch: PropTypes.func.isRequired,
		handleChange: PropTypes.func.isRequired,
		handleColorChange: PropTypes.func.isRequired,
		togglePanel: PropTypes.func.isRequired,

		// State
		showDetailedSearch: PropTypes.bool.isRequired
	}

	render() {
		let { searchFilter, searchData, searchMeta, settings } = this.props;

		return (
			<div className="detail-search-parent">
				<div
					onClick={this.props.toggleDetailedSearch}
					className="detail-search"
					style={{marginTop: "2rem"}}
				>
					Detaillierte Suche {this.props.showDetailedSearch ? "einklappen" : "ausklappen"}
				</div>
				<div style={{ padding: "0.5rem" }}>
					<div className="detail-items">
						{searchData.features != null && Object.keys(searchData.features).length > 0 ? (
							<DetailSearchFeatures
								searchFilter={searchFilter}
								searchData={searchData}
								searchMeta={searchMeta}
								actions={this.props.actions}
								handleChange={this.props.handleChange}
								handleColorChange={this.props.handleColorChange}
								toggledPanels={this.props.toggledPanels}
								togglePanel={this.props.togglePanel}
							/>
						) : null}
					</div>
				</div>
			</div>
		);
	}

}

export default DetailSearch;