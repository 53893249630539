import React, { Component } from "react";
import { Input } from "antd";

class InputSearch extends Component {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		if (this.props.name === "modelDescription") {
			let brands = Object.assign([], this.props.searchFilter.brand);
			
			brands[this.props.index] = {
				...brands[this.props.index],
				modelDescription: e.target.value
			};

			this.props.actions.setSearchFilterKey("brand", brands);
		} else {
			this.props.actions.setSearchFilterKey(this.props.name, e.target.value);
		}
		
		this.props.handleChange();
	}

	render() {
		return (
			<Input
				size={this.props.size}
				value={this.props.value}
				onChange={this.onChange}
				name={this.props.name}
				placeholder={this.props.placeholder}
				style={this.props.style}
			/>
		);
	}

}

/*
	proptypes
	name
	value
	placeholder
	onchange
	size
	style
	actions
	?index
	?searchFilter
*/

export default InputSearch;