import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { Icon, Button, Modal } from 'antd';
import { Pannellum } from "pannellum-react";

import VehiclePrice from 'components/vehicle/VehiclePrice';
import * as helper from 'helper';
import settings from 'settings';
import chfFlag from "../../../styles/img/chf.jpg";
import VehicleHighlights from "./VehicleHighlights";

class VehicleDisplay extends Component {

	static propTypes = {
		vehicle: PropTypes.object.isRequired,
		images: PropTypes.array.isRequired,
		imgAutoPlay: PropTypes.bool.isRequired,
		showThumbnails: PropTypes.bool.isRequired,
		toggleModal: PropTypes.func.isRequired
	};

	state = {
		visible: false,
	};

	open3DImage = () => {

	};

	closeAction = () => {

	};

	render() {
		return (
			<div>
				<Modal
					title="360° Innenansicht"
					visible={this.state.visible}
					onCancel={() => this.setState({visible: false})}
					footer={[]}
					width="950px"
				>
					<Pannellum
						width="100%"
						height="500px"
						image={this.props.panoramaImages ? settings.imagePath + this.props.panoramaImages[0] : ""}
						pitch={10}
						yaw={180}
						hfov={110}
						autoLoad
					>
					</Pannellum>
				</Modal>
				<VehicleHighlights
					highlights={[this.props.vehicle.highlight1, this.props.vehicle.highlight2, this.props.vehicle.highlight3]}
				/>
				<ImageGallery
					items={this.props.images}
					slideInterval={4500}
					autoPlay={this.props.imgAutoPlay}
					showThumbnails={this.props.showThumbnails}
					onImageError={helper.setFallbackImage}
					ref="gallery"
					renderLeftNav={(onClick, disabled) => {
						return (
							<button
								className='image-gallery-left-nav'
								disabled={disabled}
								onClick={() => {
									this.refs.gallery.pause();
									onClick();
								}}
							/>
						);
					}}
					renderRightNav={(onClick, disabled) => {
						return (
							<button
								className='image-gallery-right-nav'
								disabled={disabled}
								onClick={() => {
									this.refs.gallery.pause();
									onClick();
								}}
							/>										
						);
					}}
				/>
				<div>
					<Button style={{display: this.props.panoramaImages.length > 0 ? "block" : "none"}} type={"primary"} size={"large"} onClick={() => this.setState({visible: true})}>360° Ansicht</Button>
				</div>
				<Button
					type={"primary"}
					className={this.props.parkedState ? "vehicle-is-parked-btn no-border" : ""}
					style={{borderRadius: "0"}}
					onClick={this.props.onPark}
				>
					{this.props.parkedState ? "Ausparken" : "Parken"}
				</Button>
				<VehiclePrice vehicle={this.props.vehicle} isFinancing={this.props.vehicle.financing} toggleFinancing={this.props.toggleModal} onVorschlag={this.props.onVorschlag} />
				{/*{this.props.vehicle.financing ?
					<div style={{ textAlign: "center" }}>
						<div className="button" onClick={() => this.props.toggleModal("financingModal")} style={{ margin: "1rem 0" }}>Finanzierungsbeispiel anzeigen</div>
					</div>
				: null}*/}
			</div>
		);
	}

}

export default VehicleDisplay;