import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Collapse, Radio } from 'antd';
import { Button } from 'antd/lib/radio';
import FeatureSelectSearch from "../../components/form/input/FeatureSelectSearch";
import update from "react-addons-update";
import search from '../../reducers/search';
import SelectSearch from "../../components/form/input/SelectSearch";
import ColorSelect from "../../components/color-select/ColorSelect";
import SliderSearch from "../../components/form/input/SliderSearch";
import RadioSearch from "../../components/form/input/RadioSearch";

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;
const PS = 1.35962;

const FeaturePanel = ({ sectionList, handleCheckboxChange, toggleDetails, searchData, searchFilter, keys, preRender }) => (
    <div className="row">
        {preRender}
        {keys.map(sectionListKey => (
            <div key={sectionListKey}>
                {searchData.features[sectionListKey].map(feature => (
                    <span key={feature.key} className={sectionList[sectionListKey].showDetails ? "tcol-2 last-items-hidden" : "tcol-2"} style={{
                        display: "flex"
                    }}>
                        <Checkbox
                            style={{ display: "inline-flex", alignItems: "center" }}
                            onChange={(e) => handleCheckboxChange(e, feature.key, feature.value, sectionListKey)}
                            name={feature.key}
                            checked={searchFilter.features && searchFilter.features[sectionListKey] && searchFilter.features[sectionListKey].includes(feature.key) ? true : false}
                        >
                            {feature.value}
                        </Checkbox>
                    </span>
                ))}
            </div>
        ))}
    </div>
);

class DetailSearchFeatures extends Component {

	static propTypes = {
        searchFilter: PropTypes.object.isRequired,
        searchData: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
    };
    
	constructor(props) {
		super(props);
		this.state = {
            comfortDetails: false,
            otherDetails: false,
            infotainmentDetails: false,
            sectionList: {
                "INFOTAINMENT": { value: "Infotainment", showDetails: false },
                "COMFORT": { value: "Komfort", showDetails: false },
                "OTHER": { value: "Sonstiges", showDetails: false },
                "SECURITY": { value: "Sicherheit", showDetails: false },
                "OTHER_FUEL": { value: "Energieverbrauch / Emissionen / Kraftstoffe", showDetails: false },
                "INTERIOR_EXTERIOR": { value: "Innenausstattung und Exterieur", showDetail: false }
            }
        };
        
        this.toggleComfortDetails = this.toggleComfortDetails.bind(this);
        this.toggleInfotainmentDetails = this.toggleInfotainmentDetails.bind(this);
        this.toggleOtherDetails = this.toggleOtherDetails.bind(this);
    }

    toggleComfortDetails() {
		this.setState({ comfortDetails: !this.state.comfortDetails });
    }

    toggleInfotainmentDetails() {
		this.setState({ infotainmentDetails: !this.state.infotainmentDetails });
    }

    toggleOtherDetails() {
	    this.setState({ otherDetails: !this.state.otherDetails });
    }

    handleCheckboxChange = (e, key, value, sectionListKey) => {
        let featureListClone = this.props.searchFilter.features && this.props.searchFilter.features[sectionListKey] ? Object.assign([], this.props.searchFilter.features[sectionListKey]) : [];
        let newList = [];

        if(featureListClone.includes(key)) {
            newList = featureListClone.filter(feature => feature !== key);
        } else {
            newList = [...featureListClone, key];
        }

        this.props.actions.setSearchFilterKey("features", Object.assign({}, this.props.searchFilter.features, { [sectionListKey]: newList }));
        this.props.handleChange();
    }

    toggleDetails = (key) => {
        this.setState(prevState => update(prevState, {
            sectionList: {
                [key]: { $set: { ...this.state.sectionList[key], showDetails: !this.state.sectionList[key].showDetails } }
            }
        }));
    }

    handleRadioChange = e => {
        let value = e.target.value;

        let featureListClone = Object.assign([], this.props.searchFilter.features);
        let newList = [];

        newList = featureListClone.filter(key => key !== key);

        newList = [...newList, value];

        this.props.actions.setSearchFilterKey("features", newList);
        this.props.handleChange();
    }

    getRadioGroupValue = key => {
        let featureListClone = Object.assign([], this.props.searchFilter.features);
        let searchData = this.props.searchData.features[key];
        let desiredValue = "ALL";

        searchData.map(item => {
            if(featureListClone.includes(item.key)) {
                desiredValue = item.key;
            }
        });

        return desiredValue;
    }

    render() {
        let { searchData, searchFilter, searchMeta, toggledPanels } = this.props;

		return (
            <Collapse activeKey={toggledPanels} onChange={(toggledPanelArr) => this.props.togglePanel(toggledPanelArr)}>
                <Panel key={"1"} header="Fahrzeugdaten und Filiale">
                    <div className="row">
                        {searchData.doorCount.length > 0 ?
                            <div className="tcol-2">
                                <span className="label">Türen</span>
                                <SelectSearch
                                    name="doorCount"
                                    actions={this.props.actions}
                                    value={searchFilter.doorCount}
                                    valueList={searchData.doorCount}
                                    multiple={true}
                                    handleChange={this.props.handleChange}
                                />
                            </div>
                            :
                            ""
                        }
                        <div className="tcol-2">
                            <span className="label">Motorart</span>
                            <SelectSearch
                                name="motorart"
                                actions={this.props.actions}
                                value={searchFilter.motorart}
                                valueList={searchData.motorarten}
                                multiple={true}
                                handleChange={this.props.handleChange}
                            />
                        </div>

                        {searchData.gearboxes.length > 1 &&
                        <div className="tcol-2">
                            <span className="label">Getriebeart</span>
                            <SelectSearch
                                name="gearBox"
                                actions={this.props.actions}
                                value={searchFilter.gearBox}
                                valueList={searchData.gearboxes}
                                multiple={true}
                                handleChange={this.props.handleChange}
                            />
                        </div>
                        }

                    </div>

                    <div className="row">

                        {searchData.colors.length > 1 &&
                        <div className="tcol-2">
                            <span className="label">Farbe</span>
                            <ColorSelect
                                colors={searchData.colors}
                                selectedColors={searchFilter.exteriorColor}
                                onValueChange={this.props.handleColorChange}
                            />
                        </div>
                        }

                        {searchMeta.searchClass === "Car" ?
                            <div className="tcol-2">
                                <span className="label">Filiale</span>
                                <SelectSearch
                                    name="sites"
                                    actions={this.props.actions}
                                    value={searchFilter.sites}
                                    valueList={searchData.sites}
                                    multiple={true}
                                    handleChange={this.props.handleChange}
                                />
                            </div>
                            : null}

                        {searchMeta.searchClass === "Car" ?
                            <div className="tcol-2">
                                <span className="label">Umweltplakette</span>
                                <SelectSearch
                                    name="emissionStickers"
                                    actions={this.props.actions}
                                    value={searchFilter.emissionStickers}
                                    valueList={searchData.emissionStickers}
                                    multiple={true}
                                    handleChange={this.props.handleChange}
                                />
                            </div>
                            : null}
                    </div>

                    {searchMeta.searchClass === "Car" || searchMeta.searchClass === "Motorhome" ? (
                        <div>
                            <div className="row">
                                <div className="tcol-2">
                                    <span className="label">Leistung</span>
                                    <SliderSearch
                                        name="power"
                                        range={true}
                                        valueMin={0}
                                        valueMax={searchData.maxPower}
                                        value={searchFilter.power ? searchFilter.power[1] !== 0 ? searchFilter.power : [searchFilter.power[0], searchData.maxPower] : [0, searchData.maxPower]}
                                        actions={this.props.actions}
                                        step={10}
                                        tipFormat="kW"
                                        handleChange={this.props.handleChange}
                                    />
                                    <div className="slider-info">
                                        <span className="left">{searchFilter.power ? searchFilter.power[0] : "0"} kW ({Math.floor((searchFilter.power ? searchFilter.power[0] : 0) * PS)} PS)</span>
                                        <span className="right">{searchFilter.power && searchFilter.power[1] != 0 ? searchFilter.power[1] + ` kW (${Math.floor(searchFilter.power[1] * PS)} PS)` : "Unbegrenzt"}</span>
                                    </div>
                                </div>
                                {searchData.maxWeight > 0 &&
                                <div className="tcol-2">
                                    <span className="label">zul. Gesamtgewicht</span>
                                    <SliderSearch
                                        name="weight"
                                        range={true}
                                        valueMin={0}
                                        valueMax={searchData.maxWeight}
                                        value={searchFilter.weight ? searchFilter.weight[1] !== 0 ? searchFilter.weight : [searchFilter.weight[0], searchData.maxWeight] : [0, searchData.maxWeight]}
                                        actions={this.props.actions}
                                        step={50}
                                        tipFormat="kg"
                                        handleChange={this.props.handleChange}
                                    />
                                    <div className="slider-info">
                                        <span className="left">{searchFilter.weight && searchFilter.weight[0] != 0 ? searchFilter.weight[0] : 0} kg</span>
                                        <span className="right">{searchFilter.weight && searchFilter.weight[1] != 0 ? searchFilter.weight[1] : searchData.maxWeight} kg</span>
                                    </div>
                                </div>
                                }

                                <div className="tcol-2">
                                    <span className="label">Antriebsart</span>
                                    <SelectSearch
                                        name="drivingModes"
                                        actions={this.props.actions}
                                        value={searchFilter.drivingModes}
                                        valueList={searchData.drivingModes}
                                        multiple={true}
                                        handleChange={this.props.handleChange}
                                    />
                                </div>
                            </div>
                            <div className={"row"}>
                                {searchData.maxLength > 0 &&
                                <div className="tcol-2">
                                    <span className="label">Länge</span>
                                    <SliderSearch
                                        name="length"
                                        range={true}
                                        valueMin={0}
                                        valueMax={searchData.maxLength}
                                        value={searchFilter.length ? searchFilter.length[1] !== 0 ? searchFilter.length : [searchFilter.length[0], searchData.maxLength] : [0, searchData.maxLength]}
                                        actions={this.props.actions}
                                        step={50}
                                        tipFormat="mm"
                                        handleChange={this.props.handleChange}
                                    />
                                    <div className="slider-info">
                                        <span className="left">{searchFilter.length && searchFilter.length[0] != 0 ? searchFilter.length[0] : 0} mm</span>
                                        <span className="right">{searchFilter.length && searchFilter.length[1] != 0 ? searchFilter.length[1] : searchData.maxLength} mm</span>
                                    </div>
                                </div>
                                }
                                {searchData.maxWidth > 0 &&
                                <div className="tcol-2">
                                    <span className="label">Breite</span>
                                    <SliderSearch
                                        name="width"
                                        range={true}
                                        valueMin={0}
                                        valueMax={searchData.maxWidth}
                                        value={searchFilter.width ? searchFilter.width[1] !== 0 ? searchFilter.width : [searchFilter.width[0], searchData.maxWidth] : [0, searchData.maxWidth]}
                                        actions={this.props.actions}
                                        step={50}
                                        tipFormat="mm"
                                        handleChange={this.props.handleChange}
                                    />
                                    <div className="slider-info">
                                        <span className="left">{searchFilter.width && searchFilter.width[0] != 0 ? searchFilter.width[0] : 0} mm</span>
                                        <span className="right">{searchFilter.width && searchFilter.width[1] != 0 ? searchFilter.width[1] : searchData.maxWidth} mm</span>
                                    </div>
                                </div>
                                }
                                {searchData.maxHeight > 0 &&
                                <div className="tcol-2">
                                    <span className="label">Höhe</span>
                                    <SliderSearch
                                        name="height"
                                        range={true}
                                        valueMin={0}
                                        valueMax={searchData.maxHeight}
                                        value={searchFilter.height ? searchFilter.height[1] !== 0 ? searchFilter.height : [searchFilter.height[0], searchData.maxHeight] : [0, searchData.maxHeight]}
                                        actions={this.props.actions}
                                        step={50}
                                        tipFormat="mm"
                                        handleChange={this.props.handleChange}
                                    />
                                    <div className="slider-info">
                                        <span className="left">{searchFilter.height && searchFilter.height[0] != 0 ? searchFilter.height[0] : 0} mm</span>
                                        <span className="right">{searchFilter.height && searchFilter.height[1] != 0 ? searchFilter.height[1] : searchData.maxHeight} mm</span>
                                    </div>
                                </div>
                                }
                            </div>

                            <div className="row">
                                <div className="tcol-2">
                                    <span className="label">Unfallfrei</span>
                                    <RadioSearch
                                        name="accidentFree"
                                        actions={this.props.actions}
                                        value={searchFilter.accidentFree === undefined ? false : searchFilter.accidentFree}
                                        options={[
                                            { value: true, displayName: "Unfallfrei" },
                                            { value: false, displayName: "Beliebig" }
                                        ]}
                                        handleChange={this.props.handleChange}
                                    />
                                </div>

                                <div className="tcol-2">
                                    <span className="label">Unbeschädigt</span>
                                    <RadioSearch
                                        name="unrepaired"
                                        actions={this.props.actions}
                                        value={searchFilter.unrepaired === undefined ? false : searchFilter.unrepaired}
                                        options={[
                                            { value: true, displayName: "Unbeschädigt" },
                                            { value: false, displayName: "Beliebig" }
                                        ]}
                                        handleChange={this.props.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="row">
                                <div className="tcol-2">
                                    <span className="label">Leistung</span>
                                    <SliderSearch
                                        name="power"
                                        range={true}
                                        valueMin={0}
                                        valueMax={searchData.maxPower}
                                        value={searchFilter.power ? searchFilter.power[1] !== 0 ? searchFilter.power : [searchFilter.power[0], searchData.maxPower] : [0, searchData.maxPower]}
                                        actions={this.props.actions}
                                        step={10}
                                        tipFormat="kW"
                                        handleChange={this.props.handleChange}
                                    />
                                    <div className="slider-info">
                                        <span className="left">{searchFilter.power ? searchFilter.power[0] : "0"} kW ({Math.floor((searchFilter.power ? searchFilter.power[0] : 0) * PS)} PS)</span>
                                        <span className="right">{searchFilter.power && searchFilter.power[1] != 0 ? searchFilter.power[1] + ` kW (${Math.floor(searchFilter.power[1] * PS)} PS)` : "Unbegrenzt"}</span>
                                    </div>
                                </div>

                                <div className="tcol-2">
                                    <span className="label">Unfallfrei</span>
                                    <RadioSearch
                                        name="accidentFree"
                                        actions={this.props.actions}
                                        value={searchFilter.accidentFree}
                                        options={[
                                            { value: true, displayName: "Unfallfrei" },
                                            { value: false, displayName: "Beliebig" }
                                        ]}
                                        handleChange={this.props.handleChange}
                                    />
                                </div>

                                <div className="tcol-2">
                                    <span className="label">Unbeschädigt</span>
                                    <RadioSearch
                                        name="unrepaired"
                                        actions={this.props.actions}
                                        value={searchFilter.unrepaired}
                                        options={[
                                            { value: true, displayName: "Unbeschädigt" },
                                            { value: false, displayName: "Beliebig" }
                                        ]}
                                        handleChange={this.props.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Panel>
                <Panel key={"2"} header="Sicherheit und Fahrassistenzsysteme">
                    <FeaturePanel
                        preRender={
                            <div className="row no-margin">
                                {searchData.features.HEADLIGHTS ?
                                    <div className="tcol-2">
                                        <label><strong>Schweinwerfer</strong></label>
                                        <FeatureSelectSearch
                                            name="HEADLIGHTS"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.HEADLIGHTS : []}
                                            valueList={searchData.features.HEADLIGHTS}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                                {searchData.features.DAYTIME_RUNNING_LIGHTS ?
                                    <div className="tcol-2">
                                        <label><strong>Tagfahrlicht</strong></label>
                                        <FeatureSelectSearch
                                            name="DAYTIME_RUNNING_LIGHTS"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.DAYTIME_RUNNING_LIGHTS : []}
                                            valueList={searchData.features.DAYTIME_RUNNING_LIGHTS}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                                {searchData.features.CRUISE_CONTROL ?
                                    <div className="tcol-2">
                                        <label><strong>Tempomat</strong></label>
                                        <FeatureSelectSearch
                                            name="CRUISE_CONTROL"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.CRUISE_CONTROL : []}
                                            valueList={searchData.features.CRUISE_CONTROL}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                                {searchData.features.PARK_ASSIST ?
                                    <div className="tcol-2">
                                        <label><strong>Parkassistenz</strong></label>
                                        <FeatureSelectSearch
                                            name="PARK_ASSIST"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.PARK_ASSIST : []}
                                            valueList={searchData.features.PARK_ASSIST}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                                {searchData.features.BENDING_LIGHTS ?
                                    <div className="tcol-2">
                                        <label><strong>Kurvenlicht</strong></label>
                                        <FeatureSelectSearch
                                            name="BENDING_LIGHTS"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.BENDING_LIGHTS : []}
                                            valueList={searchData.features.BENDING_LIGHTS}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                :
                                ""
                                }
                            </div>
                        }
                        searchData={searchData}
                        handleCheckboxChange={this.handleCheckboxChange}
                        sectionList={this.state.sectionList}
                        toggleDetails={this.toggleDetails}
                        keys={["SECURITY"]}
                        searchFilter={searchFilter}
                    />
                </Panel>
                <Panel key={"3"} header="Funktionalität und Komfort">
                    <FeaturePanel
                        preRender={
                            <div className="row no-margin">
                                {searchData.features.TRAILER_COUPLING ?
                                    <div className="tcol-2">
                                        <label><strong>Anhängerkupplung</strong></label>
                                        <FeatureSelectSearch
                                            name="TRAILER_COUPLING"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.TRAILER_COUPLING : []}
                                            valueList={searchData.features.TRAILER_COUPLING}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                                {searchData.features.CLIMATISATION ?
                                    <div className="tcol-2">
                                        <label><strong>Klimatisierung</strong></label>
                                        <FeatureSelectSearch
                                            name="CLIMATISATION"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.CLIMATISATION : []}
                                            valueList={searchData.features.CLIMATISATION}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                        }
                        searchData={searchData}
                        handleCheckboxChange={this.handleCheckboxChange}
                        sectionList={this.state.sectionList}
                        toggleDetails={this.toggleDetails}
                        keys={["COMFORT"]}
                        searchFilter={searchFilter}
                    />
                </Panel>
                <Panel key={"4"} header="Infotainment und Kommunikation">
                    <FeaturePanel
                        preRender={
                            <div className="row no-margin">
                                {searchData.features.CLIMATISATION ?
                                    <div className="tcol-2">
                                        <label><strong>Radio</strong></label>
                                        <FeatureSelectSearch
                                            name="RADIO"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.RADIO : []}
                                            valueList={searchData.features.RADIO}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                        }
                        searchData={searchData}
                        handleCheckboxChange={this.handleCheckboxChange}
                        sectionList={this.state.sectionList}
                        toggleDetails={this.toggleDetails}
                        keys={["INFOTAINMENT"]}
                        searchFilter={searchFilter}
                    />
                </Panel>
                <Panel key={"5"} header="Innenausstattung und Exterieur">
                    <FeaturePanel
                        searchData={searchData}
                        handleCheckboxChange={this.handleCheckboxChange}
                        sectionList={this.state.sectionList}
                        toggleDetails={this.toggleDetails}
                        keys={["INTERIOR_EXTERIOR"]}
                        searchFilter={searchFilter}
                    />
                </Panel>
                <Panel key={"6"} header="Sonstiges">
                    <FeaturePanel
                        preRender={
                            <div className="row no-margin">
                                {searchData.features.EMERGENCY ?
                                    <div className="tcol-2">
                                        <label><strong>Pannenhilfe</strong></label>
                                        <FeatureSelectSearch
                                            name="EMERGENCY"
                                            placeholder="Auswählen"
                                            actions={this.props.actions}
                                            value={searchFilter.features ? searchFilter.features.EMERGENCY : []}
                                            valueList={searchData.features.EMERGENCY}
                                            multiple={false}
                                            allowClear={true}
                                            handleChange={this.props.handleChange}
                                        />
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                        }
                        searchData={searchData}
                        handleCheckboxChange={this.handleCheckboxChange}
                        sectionList={this.state.sectionList}
                        toggleDetails={this.toggleDetails}
                        keys={["OTHER", "OTHER_FUEL"]}
                        searchFilter={searchFilter}
                    />
                </Panel>
            </Collapse>
		);
	}
}

export default DetailSearchFeatures;