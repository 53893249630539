import React, { Component } from 'react';
import { Icon } from 'antd';

import jump from 'jump.js';
import { easeInOutQuart } from 'ez.js';

import Popup from 'components/modal/Popup';

const ContactContent = ({ vehicle, toggleModal }) => {
	let linkPhoneNumber = vehicle.contactInfo.phone.replace("/", "").replace("-", "");
	return (
		<div>
			<div className="title">Kontakt</div>
			<div className="content">
				<div style={{ width: "100%" }}>
					<table cellSpacing={0}>
						<tbody>
							{vehicle.contactInfo.phone && <tr>
								<td><Icon type="phone" /> Telefon</td>
								<td><a style={{ fontWeight: "bolder", color: "#49A9EE" }} href={"tel:" + linkPhoneNumber}>{vehicle.contactInfo.phone}</a></td>
							</tr>}

							{vehicle.contactInfo.site && <tr>
								<td><Icon type="home" /> Filiale</td>
								<td>{vehicle.contactInfo.site}</td>
							</tr>}

						</tbody>
					</table>
					<div className="contact-request" onClick={() => {
						jump("#contact-request", {
							duration: 1500,
							offset: -25,
							easing: easeInOutQuart
						});
						//this.toggleModal();
					}} style={{ textAlign: "center", margin: "1rem 0" }}>
						<b><Icon type="message" /> Kontaktanfrage »</b>
					</div>

				</div>
			</div>
		</div>
	);
}

class Contact extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			isMounted: false
		};
		this.handleClick = this.handleClick.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.renderModal = this.renderModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	handleClick(e) {
		e.preventDefault();
		this.toggleModal();
	}

	toggleModal() {
		this.setState({ showModal: !this.state.showModal });
	}

	closeModal() {
		this.setState({ showModal: false });
	}

	renderModal() {
		if(window.innerWidth <= 1800) {
			return (
				<div id="contact-wrapper" style={{ pointerEvents: this.state.showModal ? "auto" : "none" }}>
					<div onClick={this.handleClick} className="title title-shadow" style={{ pointerEvents: "visible", margin: "0", width: "50px", height: "50px", fontSize: "1.3rem" }}><Icon type="mail" /></div>
					<div id="contact-header" style={{ position: "relative", left: "0" }}>
						<Popup toggleModal={this.toggleModal} closeModal={this.closeModal} isOpened={this.state.showModal} animated={true}>
							<ContactContent toggleModal={this.toggleModal} vehicle={this.props.vehicle} />
						</Popup>
					</div>
				</div>
			);
		}

		return (
			<div id="contact-header">
				<ContactContent vehicle={this.props.vehicle} />
			</div>
		);
	}

	componentDidMount() {
		this.setState({ isMounted: true });
		window.addEventListener("resize", e => {
			if(this.state.isMounted) {
				this.forceUpdate();
			}
		});
	}

	componentWillUnmount() {
		this.setState({ isMounted: false });
		// window.removeEventListener("resize");
	}

	render() {
		return (
			<div>
				{this.renderModal()}
			</div>
		);
	}

	/*
		proptypes
		- vehicle
	*/

}

export default Contact;