import React from 'react';

const DetailItem = ({ value, name, plugInHybrid }) => {
	if(value && value != null && !value.includes("null")) {
		return (
			<li>
				<div className="detail-item">
					<span>{name}</span>
					<h4>{value}{plugInHybrid ? ", Plug-in-Hybrid" : " "}</h4>
				</div>
			</li>
		);
	} else {
		return null;
	}
}

export default DetailItem;