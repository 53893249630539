import { combineReducers } from 'redux';
import search from './search';
import result from './result';
import site from './site';

const rootReducer = combineReducers({
	search,
	result,
	site
});

export default rootReducer;