import React from 'react';
import EmissionQuickView from 'components/vehicle/EmissionQuickView';
import { setFallbackImage } from '../helper';

const SuggestionItem = ({ suggestedVehicle, img, id }) => {
	return (
		<div className="suggestion-item">
			<div className="bg" onError={setFallbackImage} alt={suggestedVehicle.shortDescription} style={{backgroundImage: `url(${img})`}} />
			<h2>{`${suggestedVehicle.shortDescription.substring(0, 25)}...`}</h2>
			<div className="condition">
				{suggestedVehicle.condition === "Neu" ? "Neufahrzeug" : suggestedVehicle.condition}
				{suggestedVehicle.usageType != null ? ` | ${suggestedVehicle.usageType}` : null}
			</div>
			<h3>{suggestedVehicle.price}</h3>
			{!!suggestedVehicle.reducedBy && (<h4 className="reduced-price-suggestion">Reduziert um {suggestedVehicle.reducedBy}</h4>)}
			{!!suggestedVehicle.vatReclaimable && (<h4>MwSt. ausweisbar</h4>)}
			<EmissionQuickView vehicle={suggestedVehicle} />
		</div>
	);
}

export default SuggestionItem;