import React, { Component } from "react";
import PropTypes from "prop-types";
import animejs from "animejs";

class TopAnnotation extends Component {
	
	static propTypes = {
		text: PropTypes.any.isRequired,
		top: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		onClick: PropTypes.func.isRequired
	}

	state = {
		isAnimating: false,
		isShown: false
	}

	onClick = (e) => {
		e.preventDefault();
		this.props.onClick();
	}

	handleScrollChange = () => {
		if(window.scrollY > 450) {
			document.getElementById(this.props.id).className="scroll-to scroll-to-show";
		} else {
			document.getElementById(this.props.id).className="scroll-to scroll-to-hide";
		}
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScrollChange);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScrollChange);
	}

	render() {
		return (
				<div onClick={this.props.onClick} id={this.props.id} className="scroll-to" style={{ top: this.props.top }}>{this.props.text}</div>
		);
	}

}

export default TopAnnotation;