import React, { Component } from "react";
import {Form, Input, Button, Checkbox, Select, Switch, Spin, Icon, Result, InputNumber} from "antd";
import { ReCaptcha } from 'react-recaptcha-google'
import jump from "jump.js";
import PropTypes from "prop-types";
import {formatThousand} from "../../helper";
import * as helper from "../../helper";

const FormItem = Form.Item;
const Option = Select.Option;

const ContactSection = ({ title, children }) => (
	<div className="tcol-6">
		{title && <div className="tcol-6"><b>{title}</b></div>}
		{children}
	</div>
);

// http://www.fadoe.de/blog/regulaerer-ausdruck-fuer-deutsche-postleitzahlen/
const PLZ_REGEX = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;
const BAD_CHARACTERS_REGEX = /^[^`^<>]+$/;
const BAD_CHARACTERS_VALIDATION = { pattern: BAD_CHARACTERS_REGEX, message: "Ihre Eingabe ist ungültig." };

/*
 * Die Keys sind nicht die wirklichen Ländercodes. Das brauchen wir nur,
 * um React zufrieden zu stellen.
 */
const COUNTRY_OPTIONS = [
	<Option key="AL" value="Albanien">Albanien</Option>,
	<Option key="AN" value="Andorra">Andorra</Option>,
	<Option key="BE" value="Belgien">Belgien</Option>,
	<Option key="BO" value="Bosnien und Herzegowina">Bosnien und Herzegowina</Option>,
	<Option key="BU" value="Bulgarien">Bulgarien</Option>,
	<Option key="DN" value="Dänemark">Dänemark</Option>,
	<Option key="DE" value="Deutschland">Deutschland</Option>,
	<Option key="EN" value="England">England</Option>,
	<Option key="ES" value="Estland">Estland</Option>,
	<Option key="FI" value="Finnland">Finnland</Option>,
	<Option key="FR" value="Frankreich">Frankreich</Option>,
	<Option key="GR" value="Griechenland">Griechenland</Option>,
	<Option key="IR" value="Irland">Irland</Option>,
	<Option key="IS" value="Island">Island</Option>,
	<Option key="IT" value="Italien">Italien</Option>,
	<Option key="KA" value="Kasachstan">Kasachstan</Option>,
	<Option key="KR" value="Kroatien">Kroatien</Option>,
	<Option key="LE" value="Lettland">Lettland</Option>,
	<Option key="LI" value="Liechtenstein">Liechtenstein</Option>,
	<Option key="LIT" value="Litauen">Litauen</Option>,
	<Option key="LU" value="Luxemburg">Luxemburg</Option>,
	<Option key="MA" value="Malta">Malta</Option>,
	<Option key="MAZ" value="Mazedonien">Mazedonien</Option>,
	<Option key="MO" value="Moldawien">Moldawien</Option>,
	<Option key="MON" value="Monaco">Monaco</Option>,
	<Option key="MONT" value="Montenegro">Montenegro</Option>,
	<Option key="NI" value="Niederlande">Niederlande</Option>,
	<Option key="NO" value="Norwegen">Norwegen</Option>,
	<Option key="OE" value="Österreich">Österreich</Option>,
	<Option key="PL" value="Polen">Polen</Option>,
	<Option key="PO" value="Portugal">Portugal</Option>,
	<Option key="RU" value="Rumänien">Rumänien</Option>,
	<Option key="RUS" value="Russland">Russland</Option>,
	<Option key="SA" value="San Marino">San Marino</Option>,
	<Option key="SC" value="Schweden">Schweden</Option>,
	<Option key="CH" value="Schweiz">Schweiz</Option>,
	<Option key="SE" value="Serbien">Serbien</Option>,
	<Option key="SL" value="Slowakei">Slowakei</Option>,
	<Option key="SLO" value="Slowenien">Slowenien</Option>,
	<Option key="ES" value="Spanien">Spanien</Option>,
	<Option key="TS" value="Tschechien">Tschechien</Option>,
	<Option key="TUE" value="Türkei">Türkei</Option>,
	<Option key="UN" value="Ungarn">Ungarn</Option>,
	<Option key="UK" value="Ukraine">Ukraine</Option>,
	<Option key="VAT" value="Vatikan">Vatikan</Option>,
	<Option key="WR" value="Weißrussland">Weißrussland</Option>
];

class ContactForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
		this.verifyCaptcha = this.verifyCaptcha.bind(this);
		this.expireCaptcha = this.expireCaptcha.bind(this);
	}

	state = {
		isLoading: false,
		hasErrors: false,
		hasLoadedText: false,
		timeoutRunning: false,
		wasSuccessful: undefined,
		resultState: undefined,
		formData: {},
		vehiclePrice: 0,

		/**
		 * Je nachdem welche Art der Kontaktaufnahme ausgewählt wurde
		 * soll entsprechend validiert werden.
		 */
		contactValidationType: null,

		/**
		 * Ob der Kunde eine Kopie an seine E-Mail bekommen soll.
		 */
		copyEmailToClient: false,
		verified: false,
		humanKey: null
	};



	onLoadRecaptcha() {
		if (this.captchaDemo) {
			this.captchaDemo.reset();
		}
	}

	verifyCaptcha = (res) => {
		if(res) {
			this.setState({ verified: true, humanKey: res });
		}
		this.validateForm();
	};

	// ReCAPTCHA Expired
	expireCaptcha = () => {
		this.setState({ human: false, humanKey: null });
	};

	handleSubmit = (e, sendAgain = false) => {
		e.preventDefault();

		if(!this.state.isLoading) {
			this.setState({ isLoading: true });

			if(sendAgain) {
				this.props.sendForm(this.state.formData, this.state.vehiclePrice)
					.then(() => {
						this.setState({ isLoading: false, resultState: "success" });
					})
					.catch((e) => {
						this.setState({ isLoading: false, resultState: "error" });
					})
			} else {
				this.props.sendForm(this.state.formData, this.state.vehiclePrice)
					.then(() => {
						this.setState({ isLoading: false, resultState: "success" });
					})
					.catch((e) => {
						this.setState({ isLoading: false, resultState: "error" });
					})
			}

			this.props.showResult();

			this.handleTimeOut();
		}
	};

	handleTimeOut = () => {
		this.setState({timeoutRunning: true});

		setTimeout(() => {
			this.setState({timeoutRunning: false});
		}, 5000)
	};

	handleContactTypeSelection = (value) => {
		let mapper = {
			"TP": "phonePrivate",
			"TB": "phoneBusiness",
			"TM": "phoneMobile",
			"FA": "fax",
			"EM": "email"
		};

		/*
		 * Nach einem State Update müssen wir die Felder der Kontaktdaten neu validieren,
		 * damit die jeweilige Nachricht angezeigt wird.
		 */
		this.setState({ contactValidationType: mapper[value] }, () => {
			this.props.form.validateFields([
				"phonePrivate",
				"phoneBusiness",
				"phoneMobile",
				"fax",
				"email"
			], { force: true });
		});
		this.handleSelectChange("contact", value);
	};

	handleCheckboxChange = (e) => {
		this.setState({ copyEmailToClient: e.target.checked }, () => {
			this.props.form.validateFields([
				"email"
			], { force: true });
		});
	};

	handleReset = (e) => {
		e.preventDefault();
		this.props.form.resetFields();
		this.props.form.setFields({
			text: {
				value: this.props.vehicleText
			}
		});
	};

	handleSendRequest = () => {
		this.props.form.validateFields((err, values) => {
			if (!err && this.state.verified) {
				this.setState({hasErrors: false});
				this.setState({resultState: "info"});
				this.updateFormData();
				jump('#contact-request')
			} else {
				this.setState({ hasErrors: true, isLoading: false});
			}
		})
	}

	componentDidMount() {
		if (this.captchaDemo) {
			console.log("started, just a second...")
			this.captchaDemo.reset();
		}

		setTimeout(() => {
			let anrede = "";

			if(sessionStorage.getItem("anrede")) {
				switch (sessionStorage.getItem("anrede")) {
					case "Herr": anrede = "HE"; break;
					case "Frau": anrede = "FR"; break;
					case "Firma": anrede = "FI"; break;
					case "Divers": anrede = "DIV"; break;
				}

				this.handleSelectChange("salutation", anrede);
			}
			if(anrede !== "FI" && sessionStorage.getItem("firstname")) { this.handleSelectChange("firstName", sessionStorage.getItem("firstname")); }
			if(anrede !== "FI" && sessionStorage.getItem("lastname")) { this.handleSelectChange("name", sessionStorage.getItem("lastname")); }
			if(anrede === "FI" && sessionStorage.getItem("company")) { this.handleSelectChange("company", sessionStorage.getItem("company")); }
			if(sessionStorage.getItem("mail")) { this.handleSelectChange("email", sessionStorage.getItem("mail")); }
		}, 1500);

		this.setState({vehiclePrice: this.props.vehiclePrice.replace("€", "")});
	}

	componentWillReceiveProps(nextProps) {
		if(!this.state.hasLoadedText) {
			this.props.form.setFields({
				text: {
					value: nextProps.vehicleText
				}
			});
			this.setState({
				hasLoadedText: true,
			});
		}
	}

	formatContact(value) {
		switch(value) {
			case "TP": return "Telefon Privat";
			case "TB": return "Telefon Geschäft";
			case "TM": return "Mobil";
			case "FA": return "Fax";
			case "EM": return "E-Mail";
			default: return "Nicht angegeben";
		}
	}
	formatSalutation(value) {
		switch(value) {
			case "HE": return "Herr";
			case "FR": return "Frau";
			case "FI": return "Firma";
			case "DIV": return "Divers";
			default: return "Nicht angegeben";
		}
	}
	updateFormData() {
		this.setState({formData: this.props.form.getFieldsValue()});
	}
	editForm() {
		setTimeout(() => {
			this.props.form.setFieldsInitialValue(this.state.formData);
			this.setState({isLoading: false, verified: false, humanKey: null});
		}, 300);

		this.setState({resultState: undefined});
	}
	validateForm() {
		if(this.state.hasErrors) {
			this.props.form.validateFields((err, values) => {
				if (!err) {
					this.setState({hasErrors: false});
				}
			});
		}
	}

	handleSelectChange(name, value) {
		this.props.form.setFieldsValue({
			[name]: value
		})

		this.validateForm();
	}

	printSubmittedFormData() {
		return(
			<div className={"row"}>
				<div className={"tcol-3"}>
					<h3>Name und Adresse</h3>
					{(this.state.formData.salutation || this.state.formData.name) && <p className={"no-margin"}>{`${this.state.formData.salutation && this.state.formData.salutation !== "DIV" ?  this.formatSalutation(this.state.formData.salutation) : ""} ${this.state.formData.name ? this.state.formData.name : ""}`}</p>}
					{this.state.formData.address && <p className={"no-margin"}>{this.state.formData.address}</p>}
					{(this.state.formData.zip || this.state.formData.city) && <p className={"no-margin"}>{`${this.state.formData.zip ? this.state.formData.zip : ""} ${this.state.formData.city ? this.state.formData.city : ""}`}</p>}
					{this.state.formData.country && <p className={"no-margin"}>{this.state.formData.country}</p>}
				</div>
				<div className={"tcol-3"}>
					<h3>Kontaktdaten</h3>
					{this.state.formData.phonePrivate && <p className={"no-margin"}>{`Telefon Privat: ${this.state.formData.phonePrivate}`}</p>}
					{this.state.formData.phoneBusiness && <p className={"no-margin"}>{`Telefon Geschäft: ${this.state.formData.phoneBusiness}`}</p>}
					{this.state.formData.phoneMobile && <p className={"no-margin"}>{`Mobil: ${this.state.formData.phoneMobile}`}</p>}
					{this.state.formData.fax && <p className={"no-margin"}>{`Fax: ${this.state.formData.fax}`}</p>}
					{this.state.formData.email && <p className={"no-margin"}>{`E-Mail: ${this.state.formData.email}`}</p>}
					<p className={"no-margin"}>{`Gewünschte Kontaktaufnahme: ${this.formatContact(this.state.formData.contact)}`}</p>
				</div>
				<div className={"tcol-6"}>
					<p>{`Ihr Preisvorschlag: ${this.state.vehiclePrice} €`}</p>
					{this.state.formData.isTestDriveRequest && <p>Sie wünschen eine Probefahrt</p>}
				</div>
				<div className={"tcol-6"}>
					<h3>Nachricht</h3>
					{this.state.formData.text && <p>{this.state.formData.text}</p>}
				</div>
			</div>
		);
	}

	//Formatieren tausender Zeichen und dann RegEx prüfen
	onChangePrice = (value) => {
		let formattedValue = value.replace(".", "").trimEnd();
		let pricePattern = /^-?\d*(?:,\d{0,2})?$/gm;

		if(!formattedValue.match(pricePattern)) {return;}
		else {this.setState({vehiclePrice: helper.formatThousand(formattedValue)});}
	}

	render() {
		const { getFieldDecorator } = this.props.form;

		if(this.state.resultState !== undefined) {
			return (
				this.state.resultState === "info" ?
					<div id={"no-icon-result"}>
						<Result
							status=""
							title="Überprüfen Sie Ihre Angaben, damit wir Sie erfolgreich kontaktieren können. "
							subTitle="Folgende Informationen werden an uns übermittelt."
							extra={[
								<div key={"error-result"} >
									<div className={"errResultButtons"} style={{display: "flex", flexWrap: "wrap"}}>
										<Button
											type="primary"
											onClick={(e) => this.handleSubmit(e, false)}
											disabled={this.state.hasErrors || this.state.timeoutRunning && true}
										>
											Endgültig Absenden
											{this.state.isLoading && <Icon type="loading" style={{ fontSize: 24 }} spin />}
										</Button>
										<Button onClick={() => this.editForm()}>Formular überarbeiten</Button>
									</div>
									<div style={{
										marginTop: 20,
										border: "dotted",
										borderColor: "#ffaa52"
									}}>
										{this.printSubmittedFormData()}
									</div>
								</div>,
							]}
						/>
					</div>
					:
					this.state.resultState === "success" ?
						<Result
							status="success"
							title="Vielen Dank für Ihre Anfrage."
							subTitle=""
						/>
						:
						<div className={"errResult"}>
							<Result
								status="error"
								title="Tut uns leid, bei der Übermittlung Ihrer Anfrage ist etwas schief gelaufen. Bitte probieren Sie es noch einmal."
								subTitle="Gerne sind wir auch telefonisch für Sie da."
								extra={[
									<div key={"error-result"} style={{textAlign:"center"}}>
										<h2 className={"phone"}>{this.props.contactPhone}</h2>
										<p style={{marginBottom: "2em"}}>Folgende Daten konnten nicht an uns übermittelt werden.</p>
										<div style={{textAlign: "left"}}>{this.printSubmittedFormData()}</div>
										<div className={"errResultButtons"} style={{display: "flex", flexWrap: "wrap"}}>
											<Button onClick={(e) => this.handleSubmit(e, true)} type={"primary"}>Erneut Senden</Button>
											<Button onClick={() => this.editForm()}>Formular überarbeiten</Button>
										</div>
									</div>
								]}
							/>
						</div>
			)
		} else {
			return (
				<Form onSubmit={this.handleSubmit} id="contact-form">
					<ContactSection title="Name und Adresse">
						<div className="row no-margin">
							{/* Anrede */}
							<div className="tcol-2">
								<FormItem label={"Anrede"}>
									{getFieldDecorator("salutation", {
										rules: [
											{ required: true, message: "Bitte geben Sie Ihre Anrede an. "}
										]
									})(
										<Select
											style={{ width: "100% "}}
											placeholder="Anrede auswählen"
											allowClear={true}
											onChange={value => this.handleSelectChange("salutation", value)}
										>
											<Option value="HE">Herr</Option>
											<Option value="FR">Frau</Option>
											<Option value="DIV">Divers</Option>
											<Option value="FI">Firma</Option>
										</Select>
									)}
								</FormItem>
							</div>

							{/* Name */}
							{this.props.form.getFieldsValue().salutation !== "FI" &&
							<div className="tcol-2">
								<FormItem name={"firstName"} label={"Vorname"} onChange={() => this.validateForm()}>
									{getFieldDecorator("firstName", {
										rules: [
											{required: true, message: "Bitte geben Sie Ihren Vornamen an."},
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="Max"/>
									)}
								</FormItem>
							</div>
							}
							{this.props.form.getFieldsValue().salutation !== "FI" ?
								<div className="tcol-2">
									<FormItem name={"name"} label={"Nachname"} onChange={() => this.validateForm()}>
										{getFieldDecorator("name", {
											rules: [
												{required: true, message: "Bitte geben Sie Ihren Nachnamen an."},
												BAD_CHARACTERS_VALIDATION
											]
										})(
											<Input placeholder="Mustermann"/>
										)}
									</FormItem>
								</div>
								:
								<div className="tcol-2">
									<FormItem name={"company"} label={"Firma"} onChange={() => this.validateForm()}>
										{getFieldDecorator("company", {
											rules: [
												{required: true, message: "Bitte geben Sie Ihren Firmennamen an."},
												BAD_CHARACTERS_VALIDATION
											]
										})(
											<Input placeholder="Musterfirma"/>
										)}
									</FormItem>
								</div>
							}


							{/* Straße / Hausnummer */}
							<div className="tcol-2">
								<FormItem onChange={() => this.validateForm()}>
									Straße / Hausnummer
									{getFieldDecorator("address", {
										rules: [
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="Musterstraße 1a"/>
									)}
								</FormItem>
							</div>

							{/* PLZ */}
							<div className="tcol-2">
								<FormItem onChange={() => this.validateForm()}>
									PLZ
									{getFieldDecorator("zip", {
										rules: [
											// { pattern: PLZ_REGEX, message: "Bitte geben Sie eine gültige Postleitzahl an." },
											BAD_CHARACTERS_VALIDATION,
											{
												max:10,
												message: 'Es sind nicht mehr als 10 Zeichen erlaubt.',
											}
										]
									})(
										<Input maxLength={10} placeholder="12345"/>
									)}
								</FormItem>
							</div>

							{/* Ort */}
							<div className="tcol-2">
								<FormItem onChange={() => this.validateForm()}>
									Ort
									{getFieldDecorator("city", {
										rules: [
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="Musterstadt"/>
									)}
								</FormItem>
							</div>

							{/* Land */}
							<div className="tcol-2">
								<FormItem>
									Land
									{getFieldDecorator("country")(
										<Select
											placeholder="Land auswählen"
											allowClear={true}
										>
											{COUNTRY_OPTIONS}
										</Select>
									)}
								</FormItem>
							</div>
						</div>
					</ContactSection>
					<ContactSection title="Kontaktdaten">
						<div className="row no-margin">
							{/* Telefon Privat */}
							<div className="tcol-2">
								<FormItem label={"Telefon Privat"} onChange={() => this.validateForm()}>
									{getFieldDecorator("phonePrivate", {
										rules: [
											{ required: this.state.contactValidationType === "phonePrivate", message: "Bitte geben Sie Ihre Telefonnummer an." },
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="Telefon Privat"/>
									)}
								</FormItem>
							</div>

							{/* Telefon Geschäft */}
							<div className="tcol-2">
								<FormItem label={"Telefon Geschäft"} onChange={() => this.validateForm()}>
									{getFieldDecorator("phoneBusiness", {
										rules: [
											{ required: this.state.contactValidationType === "phoneBusiness", message: "Bitte geben Sie Ihre Telefonnummer an." },
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="Telefon Geschäft" />
									)}
								</FormItem>
							</div>

							{/* Telefon Mobil */}
							<div className="tcol-2">
								<FormItem label={"Telefon Mobil"} onChange={() => this.validateForm()}>
									{getFieldDecorator("phoneMobile", {
										rules: [
											{ required: this.state.contactValidationType === "phoneMobile", message: "Bitte geben Sie Ihre Telefonnummer an." },
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="Telefon Mobil"/>
									)}
								</FormItem>
							</div>
						</div>
						<div className="row no-margin">
							{/* Faxnummer */}
							<div className="tcol-2">
								<FormItem label={"Faxnummer"} onChange={() => this.validateForm()}>
									{getFieldDecorator("fax", {
										rules: [
											{ required: this.state.contactValidationType === "fax", message: "Bitte geben Sie Ihre Faxnummer an." },
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="Faxnummer"/>
									)}
								</FormItem>
							</div>
							<div className="tcol-2">
								<FormItem name={"email"} label={"E-Mail"} onChange={() => this.validateForm()}>
									{getFieldDecorator("email", {
										rules: [
											{ required: (this.state.contactValidationType === "email" || this.state.copyEmailToClient), message: "Bitte geben Sie Ihre E-Mail Adresse an." },
											{ type: "email", message: "Bitte geben Sie eine gültige E-Mail Adresse an." },
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input placeholder="E-Mail Adresse" />
									)}
								</FormItem>
							</div>
							<div className="tcol-2">
								<FormItem label={"Kontaktaufnahme"} onChange={() => this.validateForm()}>
									{getFieldDecorator("contact", {
										rules: [
											{ required: true, message: "Bitte wählen Sie eine Kontaktmöglichkeit aus." }
										]
									})(
										<Select
											placeholder="Bitte auswählen"
											onChange={this.handleContactTypeSelection}
										>
											<Option value="TP">per Telefon Privat</Option>
											<Option value="TB">per Telefon Geschäft</Option>
											<Option value="TM">per Telefon Mobil</Option>
											<Option value="FA">per Fax</Option>
											<Option value="EM">per E-Mail</Option>
										</Select>
									)}
								</FormItem>
							</div>
						</div>
					</ContactSection>
					<ContactSection title={"Weitere Angaben"}>
						<div className="row no-margin">
							<div className="tcol-2">
								Ihr Preisangebot<br />
								<div style={{display: "flex", alignItems: "center"}}>
									<Input
										style={{width: "100%"}}
										value={this.state.vehiclePrice}
										onChange={(e) => this.onChangePrice(e.target.value)}
									/>
									<span style={{marginLeft: ".2rem"}}>€</span>
								</div>
							</div>
							<div className="tcol-2">
								<FormItem>
									Möchten Sie dieses Fahrzeug Probe fahren?<br />
									{getFieldDecorator("isTestDriveRequest", {
										valuePropName: "checked"
									})(
										<Switch />
									)}
								</FormItem>
							</div>
						</div>
						<div className="row no-margin">
							<div className="tcol-6">
								<FormItem onChange={() => this.validateForm()}>
									Fragen und Anmerkungen<br />
									{getFieldDecorator("text", {
										rules: [
											BAD_CHARACTERS_VALIDATION
										]
									})(
										<Input.TextArea autoSize={{ minRows: 3, maxRows: 18 }}/>
									)}
								</FormItem>
							</div>
						</div>
						<div className={"row no-margin"}>
							<div className="tcol-4">
								<FormItem>
									{getFieldDecorator("sendCopy", {
										valuePropName: "checked"
									})(
										<Checkbox onChange={this.handleCheckboxChange} id="sendCopy" style={{ display: "flex", alignItems: "center" }}>
											Anfrage-Kopie an eigene E-Mail-Adresse schicken
										</Checkbox>
									)}
								</FormItem>
							</div>
						</div>
					</ContactSection>
					<ContactSection>
						<div className="tcol-6" style={{ marginBottom: "5rem" }}>
							<div style={{ display: "block", marginBottom: "1rem" }}>
								* = Pflichtfeld
							</div>
							<div style={{marginBottom: "1em"}}>
								<FormItem onChange={() => this.validateForm()}>
									<ReCaptcha
										ref={(el) => {this.captchaDemo = el;}}
										hl={"de"}
										size="normal"
										data-theme="dark"
										render="explicit"
										//sitekey="6LdJfNkZAAAAAIYxPxrn_ke-4Jnu66Q-O82_MP8G"
										sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
										verifyCallback={this.verifyCaptcha}
										onExpired={this.expireCaptcha}
									/>
								</FormItem>
								{this.state.hasErrors && !this.state.verified ?
									<p style={{color: "red"}}>Bitte bestätigen Sie die Captcha</p> : null
								}
							</div>
							<Button
								type="primary"
								disabled={this.state.hasErrors || this.state.timeoutRunning && true}
								onClick={this.handleSendRequest}
							>
								Absenden
								{this.state.isLoading && <Icon type="loading" style={{ fontSize: 24 }} spin />}
							</Button>
							<Button
								type="default"
								size="default"
								onClick={this.handleReset}
								className="reset-button"
							>
								Zurücksetzen
							</Button>
							{this.state.hasErrors && (
								<div style={{ marginTop: "1rem" }} className="error">
									Bitte überprüfen Sie Ihre Eingaben.
								</div>
							)}
						</div>
					</ContactSection>
				</Form>
			)
		}
	}

}

export default Form.create()(ContactForm);