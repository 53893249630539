import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import Spinner from 'react-spinkit';
import animejs from 'animejs';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as siteActions from 'actions/siteActions';
import AuerLogo from 'components/AuerLogo';

class AuerLoadingScreen extends Component {

	componentWillReceiveProps(nextProps) {
		if(nextProps.site.isLoading) {
			animejs({
				targets: "#auer-loading-screen",
				opacity: 1,
				duration: 300,
				easing: 'easeInOutQuart'
			});
		} else {
			animejs({
				targets: "#auer-loading-screen",
				opacity: 0,
				duration: 300,
				easing: 'easeInOutQuart'
			});
		}
	}

	render() {
		return (
			<div style={{ pointerEvents: this.props.site.isLoading ? "all" : "none" }} id="auer-loading-screen">
				<div id="logo">
					<AuerLogo />
					<Spinner name="three-bounce" color="#108ee9"/>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		site: state.site
	};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(siteActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuerLoadingScreen);