import React from 'react';

const ConsumptionItem = ({ value, name, suffix, col }) => {
	if(value) {
		return (
			<div className={`tcol-${col}`}>
				<div className="consumption-item">
					<div>
						<h3>{value} {suffix}</h3>
						<h4>{name}</h4>
					</div>
				</div>
			</div>
		);
	}
	return null;
};

export default ConsumptionItem;