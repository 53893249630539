import React from 'react';
import settings from 'settings';

const EmissionQuickView = ({ vehicle }) => {
	if (vehicle.emissionFuelConsumption === null || vehicle.emissionFuelConsumption === undefined) return null;
	if ((vehicle.searchClass === "Motorbike" || vehicle.searchClass === "Motorhome" || vehicle.searchClass === "AgriculturalVehicle")) return null;

	let motorType = vehicle.motorart && vehicle.motorart.toLowerCase();

	// Treat used & mileage < 2500 same as new
	// Backend only delivers strings and no decent model, hence parsing.
	if ((vehicle.condition == "Gebraucht" && parseInt(vehicle.mileage.replace("km", '').replace(".", '')) <= settings.mileageEmissionThreshhold)
		|| (vehicle.condition == "Neu")
		|| (vehicle.usageType == "Vorführfahrzeug")
	) {
		if(settings.definitionPetrolNames.includes(motorType) || settings.definitionDieselNames.includes(motorType)){ return renderNew(vehicle);}
		else if(settings.definitionHybridNames.includes(motorType)){return renderHybrid(vehicle);}
		else if(settings.definitionElectricNames.includes(motorType)){return renderElectric(vehicle);}
		else {return null;}
	} else {
        return null;
    }
}

function renderElectric(vehicle) {
	return (
		<div className="emission" style={{ marginTop: "0.25rem", fontSize: 13 }}>
			<span>Stromverbrauch (kombiniert): {vehicle.emissionFuelConsumption ? vehicle.emissionFuelConsumption.powerConsumptionCombined + "kWh/100km*" : "-"}</span>
		</div>
	)
}

function renderHybrid(vehicle) {
	return (
		<div className="emission" style={{ marginTop: "0.25rem", fontSize: 13 }}>
			<span>Stromverbrauch (kombiniert): {vehicle.emissionFuelConsumption ? vehicle.emissionFuelConsumption.powerConsumptionCombined+ "kWh/100km*" : "-"}</span><br />
			<span>Kraftstoffverbrauch (kombiniert): {vehicle.emissionFuelConsumption ? vehicle.emissionFuelConsumption.consumptionCombined+ "l/100km*" : "-"}</span><br />
			<span>CO<sub>2</sub>-Emission (kombiniert): {vehicle.emissionFuelConsumption ? vehicle.emissionFuelConsumption.co2Emission + " g/km*" : "-"}</span>
		</div>
	)
}

function renderNew(vehicle) {
	return (
		<div className="emission" style={{ marginTop: "0.25rem", fontSize: 13 }}>
			<span>Kraftstoffverbrauch (kombiniert): {vehicle.emissionFuelConsumption ? vehicle.emissionFuelConsumption.consumptionCombined + "l/100km*" : "-"}</span><br />
			<span>CO2-Emission (kombiniert): {vehicle.emissionFuelConsumption ? vehicle.emissionFuelConsumption.co2Emission + " g/km*" : "-"}</span>
		</div>
	)
}

export default EmissionQuickView;