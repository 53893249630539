import React from 'react';

import Section from 'components/layout/Section';

const VehicleDescription = ({ description, phoneNumber }) => (
	<Section
		title="Beschreibung"
		containerClassName="description"
	>
		<div
			className="desc-content"
			dangerouslySetInnerHTML={{ __html: description ? parseDescription(description, phoneNumber) : "" }}
		></div>
	</Section>
);

function parseDescription(description, phoneNumber) {
	let text = description;
	let regBold = /\*\*(.*?)\*\*/g;
	let regListItem = /\*\s(.*?)\\/g;
	
	// Wir müssen im Front-End die Telefonnummer in der Beschreibung austauschen,
	// da die Telefonnummer der Beschreibung in die Datenbank geschrieben wird.
	let regPhoneNumber = /(0|0049\s?|\+49\s?|\(\+49\)\s?){1}([1-9]{2,4})([ \-\/]?[0-9]{1,10})+/g;

	// Replace Horizontal Lines
	text = text.replace(new RegExp("----", "g"), "<hr />");

	// Replace Bold Tags
	text = text.replace(regBold, "<b>$1</b>");

	// Replace List Tags
	text = text.replace(regListItem, "<li>$1</li>");

	// Replace Line Breaks
	text = text.replace(/\\\\/g, "<br />");

	text = text.replace(/\\/g, "");

	text = text.replace(regPhoneNumber, phoneNumber);

	return text;
}

export default VehicleDescription;