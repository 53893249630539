import React, { Component } from 'react';
import { Radio } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class RadioSearch extends Component {

	constructor(props) {
		super(props);
		this.radioChange = this.radioChange.bind(this);
	}

	radioChange(e) {
		if(this.props.name === "searchClass") {
			this.props.actions.resetSearchState();
			this.props.actions.setSearchMetaKey(this.props.name, e.target.value);
			this.props.actions.getSearchData(e.target.value);
		} else {
			this.props.actions.setSearchFilterKey(this.props.name, !this.props.value);
		}
		this.props.handleChange();
	}

	render() {
		return (
			<RadioGroup onChange={this.radioChange} value={this.props.value}>
				{this.props.options.map(option => (
					<RadioButton checked={option.value === this.props.value} value={option.value} key={option.value}>{option.displayName}</RadioButton>
				))}
			</RadioGroup>
		);
	}

}

/*
	proptypes
	- name
	- value
	- actions
	- options[{ key: "", value: "" }]
	- defaultValue
*/

export default RadioSearch;