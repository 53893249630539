import * as types from 'actions/actionTypes';
import settings from 'settings';

const initialState = {
	vehicle: {
		loading: true,
		data: {},
		error: "",
		mail: {}
	},
	interesting: [],
	comparsionVehicles: []
};

export default function result(state = initialState, action) {
	
	let vehicle;

	switch(action.type) {

		case types.LOAD_SINGLE_VEHICLE_SUCCESS:
			vehicle = Object.assign({}, state.vehicle, { data: action.data, loading: false });
			return Object.assign({}, state, { vehicle });

		case types.LOAD_SINGLE_VEHICLE_FAIL:
			vehicle = Object.assign({}, state.vehicle, { error: action.errMsg, loading: false });
			return Object.assign({}, state, { vehicle });

		case types.LOAD_INTERESTING_VEHICLES_SUCCESS:
			return Object.assign({}, state, { interesting: action.data });

		case types.RESET_RESULT_VEHICLE:
			vehicle = Object.assign({}, state.vehicle, { loading: true, data: {} });
			return Object.assign({}, state, { vehicle });

		case types.SEND_MAIL_FAIL:
			return Object.assign({}, state, { mail: { success: false, msg: "E-Mail konnte nicht verschickt werden. "} });

		case types.SEND_MAIL_SUCCESS:
			return Object.assign({}, state, { mail: { success: true, msg: "E-Mail wurde erfolgreich verschickt."} });

		case types.SEND_CHECK_LINK_CODE_FAIL:
			return Object.assign({}, state, { linkCode: { success: false, msg: "Link Code ist vorhanden. "} });

		case types.SEND_CHECK_LINK_CODE_SUCCESS:
			return Object.assign({}, state, { linkCode: { success: true, msg: "Link Code ist nicht vorhanden."} });

		case types.SEND_AGREEMENT_FAIL:
			return Object.assign({}, state, { agreement: { success: false, msg: "Zustimmung konnte nicht eingeholt werden. "} });

		case types.SEND_AGREEMENT_SUCCESS:
			return Object.assign({}, state, { agreement: { success: true, msg: "Zustimmung wurde erfolgreich eingeholt."} });

		default:
			return state;

	}

}