import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Pagination, Select} from 'antd';

import SortItem from 'components/form/input/SortItem';

class SortMenu extends Component {

	static propTypes = {
		// Objects
		searchFilter: PropTypes.object.isRequired,
		searchData: PropTypes.object.isRequired,
		searchResults: PropTypes.array.isRequired,

		// Functions
		handleSort: PropTypes.func.isRequired,
		handleShowSizeChange: PropTypes.func.isRequired,
		handlePaginationChange: PropTypes.func.isRequired,

		// State
		countAfterExecute: PropTypes.number.isRequired
	}

	render() {
		let { searchFilter, searchData, searchMeta } = this.props;
		return (
			<div>
					<div>
						<h3>Sortieren nach</h3>
						<div className={"sort-menu"} style={{ margin: "1rem 0" }}>
							<SortItem
								type="consumerPrice"
								sortBy={this.props.handleSort}
								sortField={searchMeta.sortField}
								sortDescending={searchMeta.sortDescending}
							>
								Preis
							</SortItem>
							{this.props.searchResults && this.props.searchResults.find(result => result.mileage !== "0 km") &&
								<SortItem
									type="mileage"
									sortBy={this.props.handleSort}
									sortField={searchMeta.sortField}
									sortDescending={searchMeta.sortDescending}
								>
									Kilometerstand
								</SortItem>
							}
							{this.props.searchResults && this.props.searchResults.find(result => result.firstRegistration) &&
								<SortItem
									type="firstRegistration"
									sortBy={this.props.handleSort}
									sortField={searchMeta.sortField}
									sortDescending={searchMeta.sortDescending}
								>
									Erstzulassung
								</SortItem>
							}
							<SortItem
								type="importiertAm"
								sortBy={this.props.handleSort}
								sortField={searchMeta.sortField}
								sortDescending={searchMeta.sortDescending}
							>
								Eingetroffen
							</SortItem>
						</div>
						<Pagination
							hideOnSinglePage={true}
							showSizeChanger={true}
							current={searchMeta.page}
							total={this.props.countAfterExecute}
							pageSize={searchMeta.pageSize}
							onShowSizeChange={this.props.handleShowSizeChange}
							onChange={this.props.handlePaginationChange}
						/>
					</div>
			</div>
		);
	}

}

export default SortMenu;