import * as types from 'actions/actionTypes';

const initialState = {
	isLoading: false,
	comparsionVehicles: []
};

export default function site(state = initialState, action) {

	switch(action.type) {

		case types.SET_LOADING_STATUS:
			return (() => {
				return Object.assign({}, state, { isLoading: action.value });
			})();

		case types.LOAD_COMPARSION_VEHICLES_SUCCESS:

			if(action.index != -1) {
				let newComparsionVehicles = [...state.comparsionVehicles];

				if(action.data === null) {
					newComparsionVehicles.splice(action.index, 1);
				} else {
					newComparsionVehicles[action.index] = action.data;
				}

				let copyComparedVehicleStore = [];

				newComparsionVehicles.map(comparsionVehicle => {
					copyComparedVehicleStore.push({vehicle: {adId: comparsionVehicle.adId, searchClass: comparsionVehicle.searchClass}});
				});
				sessionStorage.setItem("comparedVehicles", JSON.stringify(copyComparedVehicleStore));

				return Object.assign({}, state, {comparsionVehicles: newComparsionVehicles});
			} else {
				let newComparsionVehicles = [...state.comparsionVehicles];

				newComparsionVehicles.push(action.data);

				let copyComparedVehicleStore = [];

				newComparsionVehicles.map(comparsionVehicle => {
					copyComparedVehicleStore.push({vehicle: {adId: comparsionVehicle.adId, searchClass: comparsionVehicle.searchClass}});
				});
				sessionStorage.setItem("comparedVehicles", JSON.stringify(copyComparedVehicleStore));

				return Object.assign({}, state, {comparsionVehicles: newComparsionVehicles});
			}

		case types.RESET_COMPARSION_VEHICLES:
			sessionStorage.removeItem("comparedVehicles");
			return Object.assign({}, state, {comparsionVehicles: [action.vehicle]});

		default:
			return state;

	}

}

