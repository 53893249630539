import React, { Component } from 'react';
import { Slider } from 'antd';

class SliderSearch extends Component {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onAfterChange = this.onAfterChange.bind(this);
		this.tipFormatter = this.tipFormatter.bind(this);
	}

	onChange(value) {
		this.props.actions.setSearchFilterKey(this.props.name, value);
	}

	onAfterChange(value) {
		this.props.handleChange();
	}

	tipFormatter(value) {
		return null;
		// return this.props.tipFormat ? `${value} ${this.props.tipFormat}` : value;
	}

	render() {
		return (
			<Slider
				range={this.props.range}
				defaultValue={[this.props.valueMin, this.props.valueMax]}
				min={this.props.valueMin}
				max={this.props.valueMax}
				value={this.props.value}
				onChange={this.onChange}
				onAfterChange={this.onAfterChange}
				step={this.props.step}
				tipFormatter={null}
			/>
		);
	}

}

/*
	proptypes
	- range
	- valueMin
	- valueMax
	- value
	- step
	- tipformat
	- actions
*/

export default SliderSearch;