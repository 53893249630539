import React, { Component } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import settings from "../../settings";
import ResultItem from "../ResultItem";
import Text from "antd/es/typography/Text";

class ParkedVehiclesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initLoading: true,
			parkedVehicles: [],
		}
	}

	componentDidMount() {
		const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");
		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		axios.post(`${settings.servicePath}/customerpanel/parked/vehicles`, {
			mail: mail,
			linkCode: linkCode
		})
			.then((result) => {
				this.setState({parkedVehicles: result.data, initLoading: false});
			})
	}

	onUpdateParkedVehicles = (index) => {
		let parkedVehiclesCopy = this.state.parkedVehicles;

		parkedVehiclesCopy.splice(index, 1);
		this.setState({parkedVehicles: parkedVehiclesCopy});

		this.props.updateParkedVehicles(false);
	}

	render() {
		if(this.state.parkedVehicles.length > 0) {
			return (
				<div id="results" className="row" style={{ marginTop: "1rem" }}>
					{this.state.parkedVehicles.map((vehicle, index) => (
						<ResultItem
							key={vehicle.fahrzeugId}
							vehicle={vehicle}
							siteActions={this.props.siteActions}
							isParkedVehicleSite={true}
							updateParkedVehicles={() => this.onUpdateParkedVehicles(index)}
							onSetComparsionVehicle={this.props.onSetComparsionVehicle}
							siteData={this.props.siteData}
							vehicleCompared={this.props.vehicleCompared}
							vehicleRemovedCompare={this.props.vehicleRemovedCompare}
							vehicleParkedOut={this.props.vehicleParkedOut}
						/>
					))}
				</div>
			);
		} else {
			return <Text>Es befinden sich noch keine Fahrzeuge auf Ihrem Parkplatz.</Text>;
		}
	}
}

export default ParkedVehiclesList;