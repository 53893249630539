import React from "react";

const Disclaimer = ({ vehicle }) => {
	return (
		<div>
			<div className="container emission-disclaimer">
				<div className="row">
					<div className="tcol-6">
						<p>{vehicle.envkvFooterText}</p>
					</div>
				</div>
			</div>

			<div className="container info" style={{ marginTop: "1rem", marginBottom: 0 }}>
				<div className="row">
					<div className="tcol-6">
						<p style={{ margin: 0 }}>Dieses Angebot wurde mit größtmöglicher Sorgfalt zusammengestellt. Trotzdem kann keine Gewähr für die Fehlerfreiheit der enthaltenen Informationen übernommen werden oder dass das angezeigte Fahrzeug zwischenzeitlich nicht bereits verkauft wurde.</p>
					</div>
				</div>
			</div>
			<br /><br /><br /><br />
		</div>
	);
};

export default Disclaimer;