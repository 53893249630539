import React from 'react';
import PropTypes from 'prop-types';

const VehicleTitle = ({ make, shortDescription, unrepaired, dealersOnly }) => (
	<div>
		<div className="title">{make} {shortDescription}</div>

		{unrepaired ?
			<div className="info info-important">Dieses Fahrzeug ist beschädigt.</div>
		: null}

		{dealersOnly ?
			<div className="info info-important">Dieses Fahrzeug wird nur an Händler und Gewerbetreibende unter <b>Ausschluss jeglicher Gewährleistungen</b> verkauft!</div>
		: null}
	</div>
);

VehicleTitle.propTypes = {
	make: PropTypes.string.isRequired,
	shortDescription: PropTypes.string.isRequired,
	unrepaired: PropTypes.bool.isRequired,
	dealersOnly: PropTypes.bool.isRequired
}

export default VehicleTitle;