import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { BackTop, Modal } from 'antd';

import Contact from 'components/Contact';
import AuerLogo from 'components/AuerLogo';
import CHFPrice from 'components/vehicle/CHFPrice';
import FinancingExample from 'components/modal/FinancingExample';
import Button from "antd/es/button";
import * as customerServicesHelper from "../../customerServicesHelper";
import Cookies from "js-cookie";

class ResultHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	static propTypes = {
		vehicle: PropTypes.object.isRequired,
		visibilityHeight: PropTypes.number.isRequired,
		chfPricingModal: PropTypes.bool.isRequired,
		financingModal: PropTypes.bool.isRequired,
		closeModal: PropTypes.func.isRequired,
		siteActions: PropTypes.object.isRequired
	}

	navigateBack = (isClick) => {
		this.props.history.push(`/`);
	};

	render() {
		return (
			<div>
				<div className={"big-menu"}>
					<div style={{display: "block", textAlign: "end", borderBottom: "1px solid #d9d9d9",	padding: ".5rem"}}>
						{(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail")) ?
							<div>
								<Link to={`/customerpanel`} onClick={(e) => {
									e.preventDefault();
									this.props.history.push(`/customerpanel`);
								}}>
									<Button type="link" style={{padding: ".5rem 1.5rem"}}>Meine Suchen <span className={"anzahl-menu"}>({this.props.searchRequestsCount})</span></Button>
								</Link>
								<Link to={`/customerpanel?start=parkplatz`} onClick={(e) => {
									e.preventDefault();
									this.props.history.push(`/customerpanel?start=parkplatz`);
								}}>
									<Button type="link" style={{padding: ".5rem 1.5rem"}}>Parkplatz <span className={"anzahl-menu"}>({this.props.parkedVehicleCount})</span></Button>
								</Link>
								<Link to={`/customerpanel?start=agreement`} onClick={(e) => {
									e.preventDefault();
									this.props.history.push(`/customerpanel?start=agreement`)
								}}>
									<Button type="link" style={{padding: ".5rem 1.5rem"}}>Mein Konto</Button>
								</Link>
								<Button onClick={customerServicesHelper.onLogout} type="primary" style={{padding: ".5rem 1.5rem"}}>Abmelden</Button>
							</div>
							:
							<Link to={`/customerpanel`} onClick={(e) => {
								e.preventDefault();
								this.props.history.push(`/customerpanel`);
							}}>
								<Button type="primary" style={{padding: ".5rem 1.5rem"}}>Anmelden</Button>
							</Link>
						}
					</div>

					<BackTop
						visibilityHeight={this.props.visibilityHeight}
					>
						<div className="scroll-to scroll-to-show back-to-search" style={{top: "5rem"}}>
							<div style={{ color: "white" }}  onClick={() => this.navigateBack(true)} onMouseDown={(e) => this.navigateBack(e.button === 1)} >
								<svg height="16px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M28,14H8.8l4.62-4.62C13.814,8.986,14,8.516,14,8c0-0.984-0.813-2-2-2c-0.531,0-0.994,0.193-1.38,0.58l-7.958,7.958  C2.334,14.866,2,15.271,2,16s0.279,1.08,0.646,1.447l7.974,7.973C11.006,25.807,11.469,26,12,26c1.188,0,2-1.016,2-2  c0-0.516-0.186-0.986-0.58-1.38L8.8,18H28c1.104,0,2-0.896,2-2S29.104,14,28,14z"/></svg>
								&nbsp; Zurück zur Trefferliste
							</div>
						</div>
					</BackTop>

					<a target="_blank" href="https://auer-gruppe.de">
						<AuerLogo />
					</a>
				</div>

				<div className={"small-menu"}>
					<a target="_blank" href="https://auer-gruppe.de">
						<AuerLogo />
					</a>

					<div style={{display: "block", textAlign: "end", borderBottom: "1px solid #d9d9d9",	padding: ".5rem"}}>
						{(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail")) ?
							<div>
								<Link to={`/customerpanel`} onClick={(e) => {
									e.preventDefault();
									this.props.history.push(`/customerpanel`);
								}}>
									<Button type="link" style={{padding: ".5rem 1.5rem"}}>Meine Suchen <span className={"anzahl-menu"}>({this.props.searchRequestsCount})</span></Button>
								</Link>
								<Link to={`/customerpanel?start=parkplatz`} onClick={(e) => {
									e.preventDefault();
									this.props.history.push(`/customerpanel?start=parkplatz`);
								}}>
									<Button type="link" style={{padding: ".5rem 1.5rem"}}>Parkplatz <span className={"anzahl-menu"}>({this.props.parkedVehicleCount})</span></Button>
								</Link>
								<Link to={`/customerpanel?start=agreement`} onClick={(e) => {
									e.preventDefault();
									this.props.history.push(`/customerpanel?start=agreement`)
								}}>
									<Button type="link" style={{padding: ".5rem 1.5rem"}}>Mein Konto</Button>
								</Link>
								<Button onClick={customerServicesHelper.onLogout} type="primary" style={{padding: ".5rem 1.5rem"}}>Abmelden</Button>
							</div>
							:
							<Link to={`/customerpanel`} onClick={(e) => {
								e.preventDefault();
								this.props.history.push(`/customerpanel`);
							}}>
								<Button type="primary" style={{padding: ".5rem 1.5rem"}}>Anmelden</Button>
							</Link>
						}
					</div>

					<BackTop
						visibilityHeight={this.props.visibilityHeight}
					>
						<div className="scroll-to scroll-to-show back-to-search">
							<div style={{ color: "white" }}  onClick={() => this.navigateBack(true)} onMouseDown={(e) => this.navigateBack(e.button === 1)} >
								<svg height="16px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M28,14H8.8l4.62-4.62C13.814,8.986,14,8.516,14,8c0-0.984-0.813-2-2-2c-0.531,0-0.994,0.193-1.38,0.58l-7.958,7.958  C2.334,14.866,2,15.271,2,16s0.279,1.08,0.646,1.447l7.974,7.973C11.006,25.807,11.469,26,12,26c1.188,0,2-1.016,2-2  c0-0.516-0.186-0.986-0.58-1.38L8.8,18H28c1.104,0,2-0.896,2-2S29.104,14,28,14z"/></svg>
								&nbsp; Zurück zur Trefferliste
							</div>
						</div>
					</BackTop>
				</div>

				<Contact vehicle={this.props.vehicle} />

				<Modal
					title="Preisbeispiel Schweiz"
					visible={this.props.chfPricingModal}
					width="40%"
					onCancel={() => this.props.closeModal("chfPricingModal")}
					onOk={() => this.props.closeModal("chfPricingModal")}
					footer={[]}
				>
					<CHFPrice
						chf={this.props.vehicle.chfPriceInfo}
						vehicle={this.props.vehicle}
					/>				
				</Modal>

				<Modal
					title={`Finanzierungsbeispiel ${this.props.vehicle.shortDescription}`}
					visible={this.props.financingModal}
					width="40%"
					onCancel={() => this.props.closeModal("financingModal")}
					onOk={() => this.props.closeModal("financingModal")}
					footer={[]}
				>
					<FinancingExample
						financing={this.props.financing}
					/>
				</Modal>
			</div>
		);
	}

}

export default withRouter(ResultHeader);