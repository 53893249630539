import settings from 'settings';
import axios from "axios";
import Cookies from 'js-cookie';

export function saveFilter(filterData) {
	return new Promise((resolve, reject) => {
		if(!(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) && !(Cookies.get("linkCode") && Cookies.get("mail"))) {
			resolve({"status": "BAD_REQUEST"});
		}

		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		if(linkCode === undefined) {
			resolve({"status": "BAD_REQUEST"});
		} else {
			axios.post(`${settings.servicePath}/searchfilter/${linkCode}`, filterData)
				.then((result) => {
					resolve(result.data);
				});
		}
	});
};

export function updateFilter(filterData, suchanfrageId) {
	return new Promise((resolve, reject) => {
		if(!(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) && !(Cookies.get("linkCode") && Cookies.get("mail"))) {
			reject(null);
		}

		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		if(!linkCode) {
			reject(false);
		}

		axios.post(`${settings.servicePath}/searchfilter/${suchanfrageId}/${linkCode}/update`, filterData)
			.then(result => {
				resolve(result.data);
			});
	});
}

export function deleteSearchFilter(suchanfrageId, index) {
	if(sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail") || Cookies.get("linkCode") && Cookies.get("mail")) {
		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		axios.post(`${settings.servicePath}/searchfilter/${suchanfrageId}/${linkCode}/delete`)
			.then((result) => {
				return result;
			});
	}
};

export function onLogout() {
	sessionStorage.removeItem("linkCode");
	sessionStorage.removeItem("mail");
	localStorage.removeItem("stay");
	Cookies.remove("addCode");
	Cookies.remove("linkCode");
	Cookies.remove("mail");

	location.reload();
}