import React from "react";
import {connect} from "react-redux";

const Footer = (props) => {
	return (
		<div>
			<footer style={{ bottom: (props.isSearch && window.innerWidth < 850) ? 110 : 0 }}>
				<ul>
					<li><a href="https://auer-gruppe.de/impressum/">Impressum</a></li>
					<li><a href="https://auer-gruppe.de/datenschutzerklaerung/">Datenschutzerklärung</a></li>
				</ul>
			</footer>
			{window.innerWidth < 850 && (
				<div style={{ display: "block", height: props.site.comparsionVehicles.length >= 2 ? "120px" : "60px" }}></div>
			)}
		</div>
	);
};

function mapStateToProps(state, ownProps) {
	return {
		site: state.site
	};
}

export default connect(mapStateToProps)(Footer);