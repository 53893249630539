import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';

class Popup extends Component {

	constructor(props) {
		super(props);
		this.handleBlur = this.handleBlur.bind(this);
	}

	static propTypes = {
		isOpened: PropTypes.bool.isRequired,
		animated: PropTypes.bool,
		toggleModal: PropTypes.func,
		closeModal: PropTypes.func
	};

	componentDidUpdate() {
		if(this.props.isOpened) {
			this.refs.popup.focus();
		}
	}

	handleBlur() {
		if(this.props.closeModal) {
			// this.props.closeModal();
		}
	}

	render() {
		// if animate
		if(this.props.animated) {
			return (
				<Collapse isOpened={this.props.isOpened}>
					<div style={this.props.style} className="auer-popup" tabIndex={0} ref="popup" onBlur={this.handleBlur}>
						{this.props.children}
					</div>					
				</Collapse>
			);
		}

		// else ...
		if(this.props.isOpened) {
			return (
				<div style={this.props.style} className="auer-popup" tabIndex={0} ref="popup" onBlur={this.handleBlur}>
					{this.props.children}
				</div>
			);
		}

		return null;
	}

}

export default Popup;