import React from 'react';

import BackgroundSection from 'components/layout/BackgroundSection';

const FeatureSection = ({ title, keys, categorizedFeatures }) => {
	let mergedList = [];

	keys.map(sectionListKey => {
		let array = categorizedFeatures[sectionListKey];
		if(array !== undefined && array !== null) {
			mergedList = [...mergedList, ...array];
		}
	});

	mergedList.sort((a, b) => {
		let valueA = a.value.toLowerCase();
		let valueB = b.value.toLowerCase();

		if(valueA < valueB) {
			return -1;
		}

		if(valueA > valueB) {
			return 1;
		}

		return 0;
	});

	if(mergedList.length === 0) {
		return null;
	}

	return (
		<div className="feature-list-wrapper">
			<h3>{title}</h3>
			<ul id="feature-list">
				{mergedList.map(feature => (
					<li key={feature.key}><p>{feature.value}</p></li>
				))}
			</ul>
		</div>
	);
};

const VehicleFeatures = ({ features, categorizedFeatures }) => {
	if(!(features && features.length > 0)) {
		return null;
	}

	return (
		<BackgroundSection
			containerClassName="features space-wrapper"
			title="Ausstattung"
		>
			<div style={{ marginBottom: "1rem", fontWeight: "300" }}>Bereits im Kaufpreis enthalten.</div>
			<FeatureSection
				title="Sicherheit und Fahrassistenzsysteme"
				keys={["SECURITY", "HEADLIGHTS", "DAYTIME_RUNNING_LIGHTS", "CRUISE_CONTROL", "PARK_ASSIST", "BENDING_LIGHTS"]}
				categorizedFeatures={categorizedFeatures} 
			/>
			<FeatureSection
				title="Funktionalität und Komfort"
				keys={["COMFORT", "TRAILER_COUPLING", "CLIMATISATION"]}
				categorizedFeatures={categorizedFeatures}
			/>
			<FeatureSection
				title="Infotainment und Kommunikaton"
				keys={["INFOTAINMENT", "RADIO"]}
				categorizedFeatures={categorizedFeatures}
			/>
			<FeatureSection
				title="Innenausstattung und Exterior"
				keys={["INTERIOR_EXTERIOR"]}
				categorizedFeatures={categorizedFeatures}
			/>
			<FeatureSection
				title="Sonstiges"
				keys={["OTHER", "OTHER_FUEL", "EMERGENCY"]}
				categorizedFeatures={categorizedFeatures}
			/>
		</BackgroundSection>
	);
};

export default VehicleFeatures;