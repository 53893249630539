import React, { Component } from "react";
import settings from "../settings";
import Section from "../components/layout/Section";
import ComparsionSection from "./ComparsionSection";

import * as helper from "../helper";
import Button from "antd/es/button";
import {Link} from "react-router-dom";
import {Row, Col, Switch} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

class Comparsion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onlyDiffs: false,
            rerender: false
        }
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({rerender: !this.state.rerender});
        });
    }

    render() {
        const comparsionVehicles = this.props.comparsionVehicles;
        const searchClass = this.props.searchClass;
        const spanSizeDesk = comparsionVehicles.length === 1 ? 12 : comparsionVehicles.length === 2 ? 8 : 6;
        const spanSizeMobil = comparsionVehicles.length === 1 ? 24 : comparsionVehicles.length === 2 ? 12 : 8;

        if(comparsionVehicles) {
            return(
                <div id="compare-vehicles" style={{maxWidth: "1140px"}}>
                    <div>
                        <Row gutter={16}>
                            {window.outerWidth >= 801 && <Col span={spanSizeDesk}></Col> }
                            {comparsionVehicles.map((vehicle, index) => {
                                if(!vehicle) { return (null); }
                                return (
                                    <Col span={window.outerWidth >= 801 ? spanSizeDesk : spanSizeMobil} key={`${vehicle.adId}-head`}>
                                        <div>
                                            <Link to={`/fahrzeug/${vehicle.adId}`} id={`ad${vehicle.adId}`} onClick={(e) => {
                                                this.props.closeDrawer();
                                                this.props.history.push(`/fahrzeug/${vehicle.adId}`);
                                            }}>
                                                <Button style={{width: "100%", marginBottom: ".5rem", padding: ".8em 1em"}}>Zum Fahrzeug</Button>
                                            </Link>
                                            <Button style={{width: "100%", marginBottom: "1rem", padding: ".5em 1em", backgroundColor: "#ff5757", color: "white"}} onClick={() => this.props.siteActions.loadComparsionVehicles(null, index)}>Entfernen</Button>
                                            <div className={"comparsion-image"} style={{backgroundImage: `url(${settings.imagePath}${vehicle.image ? vehicle.image : vehicle.images[0] ? vehicle.images[0] : settings.imagePath + settings.fallbackImage})`}} />
                                            <div className={"comparsion-title"}>
                                                <h4 style={{fontWeight: "700"}}>{vehicle.make} {vehicle.shortDescription}</h4>
                                                <p className={"condition"} style={{marginBottom: ".5em"}}>{vehicle.condition}</p>
                                            </div>
                                            <p style={{marginBottom: "0"}} className="price">{vehicle.price}</p>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>

                    {comparsionVehicles.length > 0 &&
                        <div style={{display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "1rem", paddingTop: ".5rem", paddingBottom: ".5rem", borderTop: "1px solid black", borderBottom: "1px solid black"}}>
                            <div><span style={{marginRight: ".5rem", textAlign: "right"}}>{this.state.onlyDiffs ? "Alles anzeigen" : "Nur Unterschiede anzeigen"}</span></div>
                            <div><Switch
                                style={{alignSelf: "center"}}
                                checkedChildren={<FontAwesomeIcon icon={faCheck}/>}
                                unCheckedChildren={<FontAwesomeIcon icon={faTimes}/>}
                                checked={this.state.onlyDiffs}
                                onChange={() => this.setState({onlyDiffs: !this.state.onlyDiffs})}
                            /></div>
                        </div>
                    }

                    <Section
                        containerId="compare-vehicle-details"
                        title={"DETAILS"}
                    >
                        {comparsionVehicles.find(vehicle => vehicle["firstRegistration"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Erstzulassung"} keyAdd={"reg"} valueKey={"firstRegistration"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["mileage"] && vehicle["mileage"] !== "0 km") &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Kilometerstand"} keyAdd={"km"} valueKey={"mileage"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["power"] && vehicle["power"] !== "0 kW (0 PS)") &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Leistung"} keyAdd={"power"} valueKey={"power"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["cubicCapacity"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Hubraum"} keyAdd={"hubraum"} valueKey={"cubicCapacity"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["motorart"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Motorart"} keyAdd={"motor"} valueKey={"motorart"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["gearbox"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Getriebe"} keyAdd={"gear"} valueKey={"gearbox"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["drivingMode"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Antriebsart"} keyAdd={"driving"} valueKey={"drivingMode"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["color"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Farbe"} keyAdd={"color"} valueKey={"color"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["interiorType"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Innenausstattung"} keyAdd={"inner-color"} valueKey={["interiorType", "interiorColor"]}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["doorCount"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Anzahl Türen"} keyAdd={"door"} valueKey={"doorCount"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["climatisation"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Klimatisierung"} keyAdd={"clima"} valueKey={"climatisation"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["airbags"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Airbags"} keyAdd={"airbags"} valueKey={"airbags"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["leergewicht"] && vehicle["leergewicht"] !== "0") &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Leergewicht"} keyAdd={"leergewicht"} valueKey={"leergewicht"} addValue={"(kg)"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["zulaessigesGesamtgewicht"] && vehicle["zulaessigesGesamtgewicht"] !== "0") &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Zul. Gesamtgewicht"} keyAdd={"zul-gesamtgewicht"} valueKey={"zulaessigesGesamtgewicht"} addValue={"(kg)"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["leergewicht"] && vehicle["leergewicht"] !== "0") && comparsionVehicles.find(vehicle => vehicle["zulaessigesGesamtgewicht"]) &&
                            <Row gutter={16}>
                                {window.outerWidth >= 801 && <Col span={spanSizeDesk}><b>Zuladung</b></Col>}
                                {comparsionVehicles.map(vehicle => {
                                    if(!vehicle) { return (null); }
                                    return (
                                        <Col span={window.outerWidth >= 801 ? spanSizeDesk : spanSizeMobil} key={`${vehicle.adId}-zuladung`}>
                                            {window.outerWidth < 801 && <b>Zuladung</b>}
                                            <div>{helper.calculateZuladung(vehicle["leergewicht"], vehicle["zulaessigesGesamtgewicht"])} (kg)</div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        }
                        {comparsionVehicles.find(vehicle => vehicle["length"] && vehicle["length"] !== "0") &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Länge"} keyAdd={"length"} valueKey={"length"} addValue={"(mm)"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["width"] && vehicle["width"] !== "0") &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Breite"} keyAdd={"width"} valueKey={"width"} addValue={"(mm)"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["height"] && vehicle["height"] !== "0") &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Höhe"} keyAdd={"height"} valueKey={"height"} addValue={"(mm)"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["category"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={"Fahrzeugtyp"} keyAdd={"category"} valueKey={"category"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                        {comparsionVehicles.find(vehicle => vehicle["usageType"]) &&
                            <ComparsionSection
                                spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                onlyDiffs={this.state.onlyDiffs}
                                label={searchClass === "Car" ? "Gebrauchtwagenart" : "Gebrauchtfahrzeugart"} keyAdd={"usageType"} valueKey={"usageType"}
                                comparsionVehicles={comparsionVehicles}
                            />
                        }
                    </Section>

                    {(comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["consumptionInner"]) ||
                    comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["consumptionOuter"]) ||
                    comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["consumptionCombined"]) ||
                    comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["co2Emission"]) ||
                    comparsionVehicles.find(vehicle => vehicle["emissionClass"])) &&
                        <Section
                            containerId="compare-vehicle-verbrauch"
                            title={"VERBRAUCHSANGABEN"}
                        >
                                    {comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["consumptionInner"]) &&
                                        <ComparsionSection
                                            spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                            onlyDiffs={this.state.onlyDiffs}
                                            label={"Innerorts"} keyAdd={"inner-consumption"} valueKey={"emissionFuelConsumption+consumptionInner"} addValue={"l/100km"}
                                            comparsionVehicles={comparsionVehicles}
                                        />
                                    }
                                    {comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["consumptionOuter"]) &&
                                        <ComparsionSection
                                            spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                            onlyDiffs={this.state.onlyDiffs}
                                            label={"Ausserorts"} keyAdd={"outer-consumption"} valueKey={"emissionFuelConsumption+consumptionOuter"} addValue={"l/100km"}
                                            comparsionVehicles={comparsionVehicles}
                                        />
                                    }
                                    {comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["consumptionCombined"]) &&
                                        <ComparsionSection
                                            spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                            onlyDiffs={this.state.onlyDiffs}
                                            label={"Kombiniert"} keyAdd={"combined-consumption"} valueKey={"emissionFuelConsumption+consumptionCombined"} addValue={"l/100km"}
                                            comparsionVehicles={comparsionVehicles}
                                        />
                                    }
                                    {comparsionVehicles.find(vehicle => vehicle["emissionFuelConsumption"]["co2Emission"]) &&
                                        <ComparsionSection
                                            spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                            onlyDiffs={this.state.onlyDiffs}
                                            label={"CO2-Emission (Kombiniert)"} keyAdd={"co2"} valueKey={"emissionFuelConsumption+co2Emission"} addValue={"g/km"}
                                            comparsionVehicles={comparsionVehicles}
                                        />
                                    }
                                    {comparsionVehicles.find(vehicle => vehicle["emissionClass"]) &&
                                        <ComparsionSection
                                            spanSizeDesk={spanSizeDesk} spanSizeMobil={spanSizeMobil}
                                            onlyDiffs={this.state.onlyDiffs}
                                            label={"Abgasnorm"} keyAdd={"emission"} valueKey={"emissionClass"}
                                            comparsionVehicles={comparsionVehicles}
                                        />
                                    }
                        </Section>
                    }
                    {comparsionVehicles.find(vehicle => vehicle["categorizedFeatures"] && (vehicle["categorizedFeatures"]["CRUISE_CONTROL"] ||
                    vehicle["categorizedFeatures"]["PARK_ASSIST"] || vehicle["categorizedFeatures"]["SECURITY"] ||
                    vehicle["categorizedFeatures"]["DAYTIME_RUNNING_LIGHTS"] || vehicle["categorizedFeatures"]["CLIMATISATION"] ||
                    vehicle["categorizedFeatures"]["COMFORT"] || vehicle["categorizedFeatures"]["INFOTAINMENT"] ||
                    vehicle["categorizedFeatures"]["RADIO"] || vehicle["categorizedFeatures"]["INTERIOR_EXTERIOR"] || vehicle["categorizedFeatures"]["OTHER"])) &&
                        <Section
                            containerId="compare-vehicle-ausstattung"
                            title={"AUSSTATTUNG"}
                        >
                                {comparsionVehicles.find(vehicle => vehicle["categorizedFeatures"] && (vehicle["categorizedFeatures"]["CRUISE_CONTROL"] || vehicle["categorizedFeatures"]["PARK_ASSIST"] || vehicle["categorizedFeatures"]["DAYTIME_RUNNING_LIGHTS"] || vehicle["categorizedFeatures"]["SECURITY"]))  &&
                                    <ComparsionSection
                                        margin={true}
                                        label={"Sicherheit und Fahrassistenzsysteme"}
                                        keyAdd={"cruise-control"}
                                        valueKey={["categorizedFeatures+CRUISE_CONTROL", "categorizedFeatures+PARK_ASSIST", "categorizedFeatures+DAYTIME_RUNNING_LIGHTS", "categorizedFeatures+SECURITY"]}
                                        comparsionVehicles={this.props.comparsionVehicles}
                                        isFeature={true}
                                        onlyDiffs={this.state.onlyDiffs}
                                        spanSizeDesk={spanSizeDesk}
                                        spanSizeMobil={spanSizeMobil}
                                    />
                                }
                                {comparsionVehicles.find(vehicle => vehicle["categorizedFeatures"] && (vehicle["categorizedFeatures"]["CLIMATISATION"] || vehicle["categorizedFeatures"]["COMFORT"])) &&
                                <ComparsionSection
                                    margin={true}
                                    label={"Funktionalität und Komfort"}
                                    keyAdd={"clima-comfort"}
                                    valueKey={["categorizedFeatures+CLIMATISATION", "categorizedFeatures+COMFORT"]}
                                    comparsionVehicles={this.props.comparsionVehicles}
                                    isFeature={true}
                                    onlyDiffs={this.state.onlyDiffs}
                                    spanSizeDesk={spanSizeDesk}
                                    spanSizeMobil={spanSizeMobil}
                                />
                                }
                                {comparsionVehicles.find(vehicle => vehicle["categorizedFeatures"] && (vehicle["categorizedFeatures"]["INFOTAINMENT"] || vehicle["categorizedFeatures"]["RADIO"])) &&
                                <ComparsionSection
                                    margin={true}
                                    label={"Infotainment und Kommunikaton"}
                                    keyAdd={"info-radio"}
                                    valueKey={["categorizedFeatures+INFOTAINMENT", "categorizedFeatures+RADIO"]}
                                    comparsionVehicles={this.props.comparsionVehicles}
                                    isFeature={true}
                                    onlyDiffs={this.state.onlyDiffs}
                                    spanSizeDesk={spanSizeDesk}
                                    spanSizeMobil={spanSizeMobil}
                                />
                                }
                                {comparsionVehicles.find(vehicle => vehicle["categorizedFeatures"] && vehicle["categorizedFeatures"]["INTERIOR_EXTERIOR"]) &&
                                    <ComparsionSection
                                        margin={true}
                                        label={"Innenausstattung und Exterior"}
                                        keyAdd={"interior-exterior"}
                                        valueKey={["categorizedFeatures+INTERIOR_EXTERIOR"]}
                                        comparsionVehicles={this.props.comparsionVehicles}
                                        isFeature={true}
                                        onlyDiffs={this.state.onlyDiffs}
                                        spanSizeDesk={spanSizeDesk}
                                        spanSizeMobil={spanSizeMobil}
                                    />
                                }
                                {comparsionVehicles.find(vehicle => vehicle["categorizedFeatures"] && (vehicle["categorizedFeatures"]["OTHER"] || vehicle["categorizedFeatures"]["OTHER_FUEL"])) &&
                                    <ComparsionSection
                                        margin={true}
                                        label={"Sonstiges"}
                                        keyAdd={"other"}
                                        valueKey={["categorizedFeatures+OTHER", "categorizedFeatures+OTHER_FUEL"]}
                                        comparsionVehicles={this.props.comparsionVehicles}
                                        isFeature={true}
                                        onlyDiffs={this.state.onlyDiffs}
                                        spanSizeDesk={spanSizeDesk}
                                        spanSizeMobil={spanSizeMobil}
                                    />
                                }
                        </Section>
                    }
                </div>
            );
        } else {
            return null;
        }

    }
}

export default Comparsion;