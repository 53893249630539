import {Checkbox, Col, Row, Button} from "antd";
import React, {Component} from "react";
import Cookies from 'js-cookie';

class CookieBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookieConsent: false,
            editCookies: false,
            useMarketingCookies: false,
            useStatisticCookies: false,
            prevCookiesState: {
                useMarketingCookies: false,
                useStatisticCookies: false
            },
            printBanner: true
        };
    }

    async componentDidMount() {
        await this.initCookieState();
        await this.checkCookie();
        this.setState({
            printBanner: this.props.printBanner,
            prevCookiesState: {
                useMarketingCookies: this.state.useMarketingCookies,
                useStatisticCookies: this.state.useStatisticCookies
            }
        });
    }

    initCookieState() {
        if(Cookies.get('cookieConsent') !== undefined) { this.setState({cookieConsent: true}); }
        if(Cookies.get('cookieConsentMarketing') === "Allow") { this.setState({useMarketingCookies: true}); }
        if(Cookies.get('cookieConsentStatistik') === "Allow") { this.setState({useStatisticCookies: true}); }
    }

    delete_cookie = (name) => {
        Cookies.remove(name);
    };

    async onAllowAllCookies() {
        await this.setState({
            useStatisticCookies: true,
            useMarketingCookies: true
        });

        this.onAcceptCookie();
    }

    onAcceptCookie = async () => {
        if(!this.state.cookieConsent || (this.state.prevCookiesState.useStatisticCookies !== this.state.useStatisticCookies || this.state.prevCookiesState.useMarketingCookies !== this.state.useMarketingCookies)) {
            if(this.state.useStatisticCookies || this.state.useMarketingCookies) { Cookies.set('cookieConsent', 'Allow', { expires: 365}); }
            else { Cookies.set('cookieConsent', 'Deny', { expires: 365}); }

            if(this.state.useMarketingCookies) { Cookies.set('cookieConsentMarketing', 'Allow', { expires: 365}); }
            else { Cookies.set('cookieConsentMarketing', 'Deny', { expires: 365}); }

            if(this.state.useStatisticCookies) { Cookies.set('cookieConsentStatistik', 'Allow', { expires: 365}); }
            else { Cookies.set('cookieConsentStatistik', 'Deny', { expires: 365}); }

            await this.checkCookie();
            location.reload();
        }

        this.setState({
            cookieConsent: true,
            editCookies: false
        });
    };

    checkCookie = () => {
        if(this.state.useStatisticCookies === false) {
            this.delete_cookie("_ga");
            this.delete_cookie("_gat");
            this.delete_cookie("_gid");
        }
        if(this.state.useMarketingCookies === false) {
            this.delete_cookie("_fbp");
        }
    };

    render() {
        if(this.state.printBanner) {
            return(
                this.state.cookieConsent === false || this.state.editCookies ?
                    <Row id={"cookie-banner"} className="cookie-banner-row" style={{display: "flex", alignItems: "center", padding: "1em", position: "fixed", bottom: "0", backgroundColor: "white", borderTop: "1px solid #333", zIndex: "9"}} gutter={[16,16]}>
                        <Col xs={24} sm={24} md={24} lg={12} className={"cookie-banner-text"}>
                            <p>
                                Diese Webseite verwendet Cookies, um Informationen über die Nutzung unserer Websites durch die Besucher zu sammeln. Diese Cookies helfen uns dabei, Inhalte zu personalisieren, Ihnen das bestmögliche Online-Erlebnis zu bieten, unsere Website ständig zu verbessern und Ihnen Angebote zu unterbreiten, die auf Ihre Interessen zugeschnitten sind. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter.
                                <br/><br/>
                                <b>Hinweis auf Verarbeitung Ihrer auf dieser Webseite erhobenen Daten in den USA durch Google, Facebook, LinkedIn, YouTube:</b>
                                Indem Sie auf "Alle akzeptieren" klicken, willigen Sie zugleich gem. Art. 49 Abs. 1 S. 1 lit. a DSGVO ein, dass Ihre Daten in den USA verarbeitet werden. Wir weisen Sie ausdrücklich darauf hin, dass in den USA derzeit kein angemessenes Datenschutzniveau besteht und es auch keinen Angemessenheitsbeschluss seitens der EU-Kommission gibt. Wir weisen ferner darauf hin, dass unter anderem aufgrund des CLOUD Acts  und anderen gesetzlichen Regelungen in den USA, US-amerikanische Behörden auf diese Daten zugreifen können und Ihnen in den USA nicht die Betroffenenrechte zustehen, wie dies innerhalb der EU der Fall ist. Die USA werden vom Europäischen Gerichtshof als ein Land mit einem nach EU-Standards unzureichendem Datenschutzniveau eingeschätzt. Wenn Sie nur funktionale Cookies akzeptieren, findet die vorgehend beschriebene Übermittlung nicht statt.
                                <br/><br/>
                                Mit dem Klick auf den Button "Alle akzeptieren" erklären Sie sich mit der Verwendung aller Cookies einverstanden. Für weitere Informationen über die Nutzung von Cookies oder für die Änderung Ihrer Einstellungen, klicken Sie bitte auf "Cookie-Richtlinie (EU)".
                                <br/><br/><br/><b><a className={"cookie-link"} href={"https://auer-gruppe.de/datenschutzerklaerung"} target={"_blank"}>Datenschutzerklärung</a> - <a
                                aria-label="Cookie-Richtlinie (EU)" className="cc-link"
                                href="https://auer-gruppe.de/neue-startseite/cookie-richtlinie-eu/">Cookie-Richtlinie
                                (EU)</a> - <a className={"cookie-link"} target={"_blank"} href={"https://auer-gruppe.de/impressum"}>Impressum</a></b>
                            </p>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} style={{textAlign: "center"}}>
                            <Checkbox checked={true} disabled>Funktional</Checkbox>
                            <Checkbox
                                checked={this.state.useStatisticCookies}
                                onChange={() => this.setState({useStatisticCookies: !this.state.useStatisticCookies})}
                            >
                                Statistiken
                            </Checkbox>
                            <Checkbox
                                checked={this.state.useMarketingCookies}
                                onChange={() => this.setState({useMarketingCookies: !this.state.useMarketingCookies})}
                            >
                                Marketing
                            </Checkbox>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} style={{textAlign: "end"}} className={"cookie-banner-buttons"}>
                            <Button style={{padding: ".8rem"}} size={"default"} onClick={() => this.onAllowAllCookies()}>Alle akzeptieren</Button>
                            <Button className={"btn btn-primary"} style={{padding: ".8rem", marginLeft: ".5em", color: "white", backgroundColor: "#1890ff", border: "1px solid #1890ff", borderRadius: "5px"}} onClick={() => this.onAcceptCookie()}>Einstellungen speichern</Button>
                        </Col>
                    </Row>
                :
                    <div className={"changeCookies"} onClick={() => this.setState({editCookies: true})}>
                        <p>Cookies</p>
                    </div>
            );
        } else {
            return null;
        }
    }
};

export default CookieBanner;