import React, {Component} from "react";
import settings from 'settings';
import * as helper from 'helper';
import EmissionQuickView from 'components/vehicle/EmissionQuickView';
import { setFallbackImage } from '../helper';
import { Link, withRouter } from 'react-router-dom';
import Tag from "antd/es/tag";
import axios from "axios";
import Button from "antd/es/button";
import {Modal} from "antd";
import Cookies from "js-cookie";

class ResultItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			parkedState: null,
			replaceComparsionVehicle: false,
			newVehicleToCompare: null,
			notRegistered: false
		};
	}

	async componentDidMount () {
		if ((sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail"))) {
			const vehicle = this.props.vehicle.fahrzeug ? this.props.vehicle.fahrzeug : this.props.vehicle;
			const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");
			const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

			await axios.post(`${settings.servicePath}/customerpanel/parked/vehicle/${vehicle.adId}`, {
				mail: mail,
				linkCode: linkCode
			})
				.then((result) => {
					this.setState({parkedState: result.data});
				});
		}
	}

	renderItem(key, item) {
		if(key === "Kilometer" && item === "0 km") { return null; }

		if(item && item.length > 0) {
			return (
				<div className="tcol-2">
					<span className="label">{key}</span>
					<span>{item}</span>
				</div>
			);
		} else {
			return null;
		}
	}

	async onPark(e, adId) {
		e.preventDefault();

		const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");
		const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

		if(!mail && !linkCode) {
			this.setState({notRegistered: true});
		}

		if(!this.props.isParkedVehicleSite && !this.state.parkedState) {
			axios.post(`${settings.servicePath}/ad/${adId}/park`, {mail: mail, linkCode: linkCode})
				.then((result) => {
					if(result.data == "OK") {
						axios.post(`${settings.servicePath}/customerpanel/parked/vehicle/${adId}`, {
							mail: mail,
							linkCode: linkCode
						})
							.then((result) => {
								this.setState({parkedState: result.data});
								this.props.updateParkedVehicles(true);
								this.props.vehicleParked();
							});
					}
				});
		} else {
			const kundenMailParkedVehicleId = !this.props.isParkedVehicleSite ? this.state.parkedState.kundenMailParkedVehicleId : this.props.vehicle.kundenMailParkedVehicleId;

			axios.post(`${settings.servicePath}/customerpanel/parked/vehicle/${kundenMailParkedVehicleId}/delete`, {mail: mail, linkCode: linkCode})
				.then((result) => {
					if(result.data == "OK") {
						this.setState({parkedState: null});
						this.props.updateParkedVehicles(false);
						this.props.vehicleParkedOut();
					}
				});
		}
	}

	onSetComparsionVehicle = async (vehicle) => {
		const comparsionVehicles = this.props.siteData.comparsionVehicles;

		if(comparsionVehicles.find(comparsionVehicle => comparsionVehicle && comparsionVehicle.searchClass !== vehicle.searchClass)) {
			this.setState({differentSearchClass: true, newVehicleToCompare: vehicle });
			return;
		}

		if(comparsionVehicles && window.innerWidth >= 1015 && comparsionVehicles.length >= 3 || comparsionVehicles && window.innerWidth < 1015 && comparsionVehicles.length >= 2) {
			if(comparsionVehicles && window.innerWidth >= 1015 && comparsionVehicles.length >= 3 && comparsionVehicles[2] === null) {
				this.props.siteActions.loadComparsionVehicles(vehicle, 2);
				this.props.vehicleCompared();
			} else {
				this.setState({replaceComparsionVehicle: true, newVehicleToCompare: vehicle});
			}
		} else {
			this.props.siteActions.loadComparsionVehicles(vehicle);
			this.props.vehicleCompared();
		}
	}

	onRemoveComparsionVehicle = (vehicle) => {
		const index = this.props.siteData.comparsionVehicles.findIndex(comparsionVehicle => comparsionVehicle ? comparsionVehicle.adId === vehicle.adId : false);
		this.props.siteActions.loadComparsionVehicles(null, index);
		this.props.vehicleRemovedCompare();
	}
	onReplaceComparsionVehicle = (index) => {
		this.props.siteActions.loadComparsionVehicles(this.state.newVehicleToCompare, index);
		this.setState({replaceComparsionVehicle: false, newVehicleToCompare: null});
		this.props.vehicleCompared();
	}
	 onReplaceSearchClass = async () => {
		 await this.props.siteActions.resetComparsionVehicles(this.state.newVehicleToCompare);
		 this.props.siteActions.loadComparsionVehicles(this.state.newVehicleToCompare, 0);

		this.setState({newVehicleToCompare: null, differentSearchClass: false});
		this.props.vehicleCompared();
	}

	isCompared = (vehicle) => {
		return this.props.siteData.comparsionVehicles.findIndex(comparsionVehicle => comparsionVehicle ? comparsionVehicle.adId === vehicle.adId : false);
	}

	getParkedState = async (adId) => {

	}

	render() {
		const vehicle = this.props.vehicle.fahrzeug ? this.props.vehicle.fahrzeug : this.props.vehicle;
		let dateParts;
		let importiertAm;
		let pastDays;

		if(!this.props.isParkedVehicleSite) {
			dateParts = vehicle.importiertAm.split('.');
			importiertAm = new Date(dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]);
			pastDays = new Date((new Date()).valueOf() - 1000*60*60*24*2);
		}

		return (
			<div className={`result-item-div`}>
				<Modal footer={null} okButtonProps={{ display: "none" }} style={{width: "fit-content"}} title="Vergleichsfahrzeug wechseln" visible={this.state.replaceComparsionVehicle} onCancel={() => this.setState({replaceComparsionVehicle: false, newVehicleToCompare: null})}>
					<h4 style={{marginBottom: "1rem"}}>Welches Fahrzeug möchten Sie ersetzen?</h4>
					{this.props.siteData && this.props.siteData.comparsionVehicles.map((vehicle, index) => {
						return (vehicle && <Button key={vehicle.adId} style={{width: "100%"}} type={"default"} onClick={() => this.onReplaceComparsionVehicle(index)}>{vehicle.shortDescription}</Button>);
					})}
				</Modal>
				<Modal footer={null} okButtonProps={{ display: "none" }} style={{width: "fit-content"}} title="Nur Fahrzeuge derselben Kategorie" visible={this.state.differentSearchClass} onCancel={() => this.setState({notRegistered: false})}>
					<p>Es können nur Fahrzeuge derselben Kategorie verglichen werden.</p>

					<p>Möchten Sie Ihre Vergleichsfahrzeuge durch das neu gewählte Fahrzeug ersetzen?</p>

					<Button type={"primary"} onClick={this.onReplaceSearchClass}>Ersetzen</Button>
					<Button style={{marginLeft: ".5em"}} onClick={() => this.setState({differentSearchClass: false, newVehicleToCompare: null})}>Abbrechen</Button>
				</Modal>
				<Modal footer={null} okButtonProps={{ display: "none" }} style={{width: "fit-content"}} title="Fahrzeug kann nicht geparkt werden" visible={this.state.notRegistered} onCancel={() => this.setState({notRegistered: false})}>
					<p>Um ein Fahrzeug zu parken müssen Sie sich mit Ihrer E-Mail Adresse in unserem System anmelden.</p>

					<h2>Ihre Vorteile</h2>

					<p>- Beliebig viele Fahrzeuge parken<br/>
						- Beliebig viele Suchaufträge speichern</p>
					<p>- Anmeldung an unserem Benachrichtigungssystem.<br/>
						Erfahren Sie sofort, wenn wir ein neues Fahrzeug passend zu Ihrem Suchauftrag in Bestand nehmen.
						oder bei Preissenkungen an einem von Ihnen geparkten Fahrzeug.</p>

					<Button style={{marginTop: ".5em"}} type={"primary"} onClick={(e) => this.props.history.push(`/customerpanel`)}>Zur Anmeldung</Button>
					<Button style={{marginLeft: ".5em"}} onClick={() => this.setState({notRegistered: false})}>Abbrechen</Button>
				</Modal>
				<div className={"result-item-flex action-btns btn-absolute big"}>
					<button className={`${(this.props.isParkedVehicleSite || this.state.parkedState) ? " vehicle-is-parked-btn" : ""}`} style={{marginLeft: ".5em"}} onClick={(e) => this.onPark(e, vehicle.adId)}>{(this.props.isParkedVehicleSite || this.state.parkedState) ? "Ausparken" : "Parken"}</button>
					<button onClick={(e) => this.isCompared(vehicle) === -1 ? this.onSetComparsionVehicle(vehicle) : this.onRemoveComparsionVehicle(vehicle)}>
						{this.isCompared(vehicle) === -1 ? "Vergleichen" : "Nicht vergleichen"}
					</button>
				</div>
				<div className={"result-item-flex action-btns small"}>
					<button className={`${(this.props.isParkedVehicleSite || this.state.parkedState) ? " vehicle-is-parked-btn" : ""}`} style={{marginLeft: ".5em"}} onClick={(e) => this.onPark(e, vehicle.adId)}>{(this.props.isParkedVehicleSite || this.state.parkedState) ? "Ausparken" : "Parken"}</button>
					<button onClick={(e) => this.isCompared(vehicle) === -1 ? this.onSetComparsionVehicle(vehicle) : this.onRemoveComparsionVehicle(vehicle)}>
						{this.isCompared(vehicle) === -1 ? "Vergleichen" : "Nicht vergleichen"}
					</button>
				</div>
				<Link to={`/fahrzeug/${vehicle.adId}`} id={`ad${vehicle.adId}`} onClick={(e) => {
					e.preventDefault();
					helper.sendAnalyticsEvent("Fahrzeug", "Klick auf Treffer", vehicle.adId);
					this.props.siteActions.setPageLoadingStatus(true)
						.then(() => {
							this.props.history.push(`/fahrzeug/${vehicle.adId}`)
						});
				}}>
						<div className={`result-item${(this.props.isParkedVehicleSite || this.state.parkedState) ? " vehicle-is-parked" : ""}`}>
							<img onError={setFallbackImage} src={`${settings.imagePath}${vehicle.image ? vehicle.image : vehicle.images[0]}`} alt={vehicle.shortDescription} />
							<div className="content">
								{!this.props.isParkedVehicleSite && (importiertAm >= pastDays) && <Tag color="red">Neu eingetroffen</Tag>}
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<h2>{vehicle.shortDescription}</h2>
								</div>
								<div className="condition">
									{vehicle.condition === "Neu" ? "Neufahrzeug" : vehicle.condition}
									{vehicle.usageType != null ? ` | ${vehicle.usageType}` : null}</div>

								<div className="row no-margin">
									{vehicle.searchClass === "Car" ? (
										this.renderItem("Kraftstoff", vehicle.fuel)
									) : (
										this.renderItem("Hubraum", vehicle.cubicCapacity)
									)}
									{(!(settings.categoryBlacklist.includes(vehicle.category)) && !(vehicle.power === "0 kW (0 PS)")) && (
										this.renderItem("Leistung", vehicle.power)
									)}
									{this.renderItem("Erstzulassung", vehicle.firstRegistration)}
									{this.renderItem("Farbe", vehicle.color)}
									{!(settings.categoryBlacklist.includes(vehicle.category)) && this.renderItem("Kilometer", vehicle.mileage) }
									{this.renderItem("Getriebe", vehicle.gearbox)}
								</div>
								<div className={"row tagsList"} >
									{vehicle.highlight1 && vehicle.highlight1 !== "" && <Tag>{vehicle.highlight1}</Tag>}
									{vehicle.highlight2 && vehicle.highlight2 !== "" && <Tag>{vehicle.highlight2}</Tag>}
									{vehicle.highlight3 && vehicle.highlight3 !== "" && <Tag>{vehicle.highlight3}</Tag>}
								</div>
								<div className="price">
									{vehicle.price}
									{!!vehicle.reducedBy && (<div style={{ display: "block", fontSize: "14px", fontWeight: "bold", color: "#b71c1c" }}>Reduziert um {vehicle.reducedBy}</div>)}
								</div>
								<EmissionQuickView vehicle={vehicle} />

							</div>
						</div>
				</Link>
			</div>
		);
	}
}

export default withRouter(ResultItem);