import * as types from 'actions/actionTypes';
import { REHYDRATE } from 'redux-persist/constants'

const initialState = {
	searchFilter: {
		brand: [{ key: "alle" }],
		condition: ["NEW", "USED"],
	},
	searchResults: [],
	searchData: {
		eingetroffenVor: [
			{key: 1, value:"1"},
			{key: 2, value:"2"},
			{key: 3, value:"3"},
			{key: 4, value:"4"},
			{key: 5, value:"5"},
			{key: 6, value:"6"},
			{key: 7, value:"7"},
			{key: 8, value:"8"},
			{key: 9, value:"9"},
			{key: 10, value:"10"},
			{key: 11, value:"11"},
			{key: 12, value:"12"},
			{key: 13, value:"13"},
			{key: 14, value:"14"},
		]
	},
	searchMeta: {
		searchClass: "Car",
		page: 0,
		pageSize: 20,
		sortField: "consumerPrice",
		sortDescending: false
	},
	count: 0,
	searchFilterAfterExecute: {},
	countAfterExecute: 0,
	newFilterDisabled: false,
	comparsionVehicles: [],
	daysBack: null,
	searchRequest: null,
	jumpToList: false
};

export { initialState };

export default function search(state = initialState, action) {
	switch(action.type) {
		case REHYDRATE:
			return state;

		case types.REHYDRATE_SEARCH_FILTER:
			return (function() {
				let searchFilter = Object.assign({}, state.searchFilter, action.value);
				let newState = Object.assign({}, state, { searchFilter });
				return newState;
			})();

		case types.REHYDRATE_SEARCH_META:
			return (function() {
				let searchMeta = Object.assign({}, state.searchMeta, action.value);
				let newState = Object.assign({}, state, { searchMeta });
				return newState;
			})();

		case types.SET_SEARCH_FILTER_KEY:
			return (function() {
				let searchFilter = Object.assign({}, state.searchFilter, { [action.key]: action.value });
				let newState = Object.assign({}, state, { searchFilter });
				persistSearchFilterObject(newState);

				return newState;
			})();

		case types.SET_SEARCH_FILTER_FEATURE_KEY:
			return (function() {
				let searchFilterFeature = Object.assign({}, state.searchFilter.features, { [action.key]: action.value });
				let newState = Object.assign({}, state, { searchFilter: Object.assign({}, state.searchFilter, { features: searchFilterFeature }) });
				persistSearchFilterObject(newState);
				return newState;
			})();

		case types.SET_SEARCH_META_KEY:
			return (function() {
				let searchMeta = Object.assign({}, state.searchMeta, { [action.key]: action.value });
				let newState = Object.assign({}, state, { searchMeta });
				persistSearchMetaObject(newState);
				return newState;
			})();

		case types.SET_SEARCH_DATA_KEY:
			return (function() {
				let searchData = Object.assign({}, state.searchData, { [action.key]: action.value });
				return Object.assign({}, state, { searchData });	
			})();

		case types.EXECUTE_SEARCH_SUCCESS:
			return Object.assign({}, state, { searchResults: action.data });
		
		case types.GET_SEARCH_DATA_SUCCESS:
			return (() => {
				let searchData = state.searchData;
				let newSearchData = Object.assign({}, searchData, { ...action.data })
				return Object.assign({}, state, { searchData: newSearchData });
			})();

		case types.LOAD_BRAND_MODELS_SUCCESS:
			return (() => {
				let brandModels = {};

				if (state.searchData.brandModels !== undefined) {
					brandModels = Object.assign({}, state.searchData.brandModels);
				}

				brandModels[action.data.brand] = action.data.response;

				let searchData = Object.assign({}, state.searchData, { brandModels });
				return Object.assign({}, state, { searchData });
			})();

		case types.GET_MODELSERIES_SUCCESS:
			return(() => {
				let modelSeries = {};

				if(state.searchData.modelSeries !== undefined) {
					modelSeries = Object.assign({}, state.searchData.modelSeries);
				}

				modelSeries[action.data.brand] = action.data.response;

				let searchData = Object.assign({}, state.searchData, { modelSeries });
				return Object.assign({}, state, { searchData });
			})();

		case types.GET_EXISTING_NEW_VEHICLE_BRANDS_SUCCESS:
			return(() => {
				let searchData = Object.assign({}, state.searchData, { brands: action.data });
				return Object.assign({}, state, {
					searchData
				});
			})();

		case types.GET_COUNT_SUCCESS:
			return Object.assign({}, state, { count: parseInt(action.data.value), searchResults: action.data.items });

		case types.RESET_SEARCH_STATE:
			return (function() {
				let searchData = Object.assign({}, state.searchData, { brandModels: [] });
				let selectedSearchClass = state.searchMeta.searchClass;
				return Object.assign({}, state, {
					searchFilter: initialState.searchFilter,
					searchMeta: Object.assign({}, initialState.searchMeta, { searchClass: selectedSearchClass }), 
					searchData,
					searchResults: initialState.searchResults
				});
			})();

		case types.SET_SEARCH_REDUCER_KEY:
			return (function() {
				return Object.assign({}, state, { [action.key]: action.value });
			})();

		case types.LOAD_MOTORBIKE_DRIVING_MODES_SUCCESS:
			return (function() {
				let searchData = Object.assign({}, state.searchData, { drivingModes: action.data });
				return Object.assign({}, state, { searchData });
			})();

		case types.SET_NEW_FILTER_DISABLED:
			return (() => {
				return Object.assign({}, state, { newFilterDisabled: action.value })
			})();

		case types.SET_SEARCH_REQUEST_DATA:
			return (function () {
				return Object.assign({}, state, {
					jumpToList: action.jumpToList,
					daysBack: action.daysBack,
					searchRequest: action.searchRequest
				})
			})();

		case types.RESET_SEARCH_REQUEST_DATA:
			return (function() {
				return Object.assign({}, state, {
					daysBack: initialState.daysBack,
					jumpToList: initialState.jumpToList,
					searchRequest: initialState.searchRequest
				});
			})();

		default:
			return state;

	}

}

function persistSearchFilterObject(newState) {
	let searchFilterObject = newState.searchFilter;
	localStorage.setItem("SEARCH_FILTER_STATE", JSON.stringify(searchFilterObject));
}

function persistSearchMetaObject(newState) {
	let searchMetaObject = newState.searchMeta;
	localStorage.setItem("SEARCH_META_STATE", JSON.stringify(searchMetaObject));
}