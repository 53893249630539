import React, { Component } from "react";
import { Button } from "antd";

import SelectSearch from "components/form/input/SelectSearch";
import InputSearch from "components/form/input/InputSearch";

class BrandSelect extends Component {
	 getModels(models, modelSeries) {
		let filteredModels = [];

		if(modelSeries) {
			 modelSeries.map(modelSerie => {

				if(isFinite(String(modelSerie[0]))) {
					models.filter(model => {
						if (model.value[0] === modelSerie[0]) {
							filteredModels.push(model);
						}
					})
				}
				else {
					models.filter(model => {
						if (model.value.includes(modelSerie[0])) {
							filteredModels.push(model);
						}
					})
				}
			})
		}

		if(filteredModels.length === 0) {
			filteredModels = models;
		}

		return filteredModels;
	}

	addNewBrandRow = () => {
		let currentBrands = Object.assign([], this.props.searchFilter.brand);
		currentBrands.push({ key: "" })
		this.props.actions.setSearchFilterKey("brand", currentBrands);
	}

	removeBrand = (item, i) => {
		let currentBrands = Object.assign([], this.props.searchFilter.brand);
		currentBrands.splice(i, 1);
		this.props.actions.setSearchFilterKey("brand", currentBrands);

		let currentModels = Object.assign({}, this.props.searchFilter.model);
		delete currentModels[item.key];
		this.props.actions.setSearchFilterKey("model", currentModels);

		let currentModelSeries = Object.assign({}, this.props.searchFilter.modelSeries);
		delete currentModelSeries[item.key];
		this.props.actions.setSearchFilterKey("modelSeries", currentModelSeries);

		setTimeout(() => {
			this.props.actions.getCount(this.props.searchFilter, this.props.searchMeta);
		}, 0);
	}

	render() {
		let { searchFilter, searchData, searchMeta, actions, handleChange } = this.props;
		let brands = Object.assign([], searchFilter.brand);
		let brandsData = Object.assign([], searchData.brands);

		let isCarSearchClass = searchMeta.searchClass === "Car";

		return (
			<div>
				{brands.length > 0 && brands.map((item, i) => (
					<div key={i} className={`row ${brands.length > 0 && "brand-select-wrapper"}`}>
						{brandsData.length > 1 &&
							<div className="tcol-2">
								<span className="label">Marke</span>
								<SelectSearch
									allowClear={false}
									name="brand"
									actions={actions}
									value={searchFilter.brand !== undefined && searchFilter.brand.length > 0 ? searchFilter.brand[i].key : ""}
									valueList={Object.assign([], searchData.brands).filter(item => {
										let value = searchFilter.brand !== undefined && searchFilter.brand.length > 0 ? searchFilter.brand[i].key : "";

										if (searchFilter.brand.filter(brand => brand.key === item.key).length > 0 && value !== item.key) {
											return false;
										}

										return true;
									})}
									searchFilter={searchFilter}
									multiple={false}
									showAllSelect={i === 0 && searchFilter.brand.length === 1}
									searchMeta={searchMeta}
									handleChange={handleChange}
									index={i}
								/>
							</div>
						}

						<div className="tcol-2">
							{searchFilter.brand[i].key === "BMW" ?
								<div>
									<span className="label">Modellreihe</span>
									<SelectSearch
										allowClear={false}
										name="modelSeries"
										actions={actions}
										brand={searchFilter.brand[i].key}
										searchFilter={searchFilter}
										value={searchFilter.modelSeries ? searchFilter.modelSeries[searchFilter.brand[i].key]  : []}
										valueList={searchData.modelSeries && searchData.modelSeries[searchFilter.brand[i].key] }
										multiple={true}
										handleChange={handleChange}
										showAllSelect={true}
										index={i}
									/>
								</div>
							: null}
							<span className="label">Modell</span>
							{(searchMeta.searchClass === "Car")
								&& searchFilter.brand[i].key !== "alle" && searchData.brandModels !== undefined
								&& (searchData.brandModels[searchFilter.brand[i].key] !== undefined
								&& (searchData.brandModels[searchFilter.brand[i].key].length > 0 && searchData.brandModels[searchFilter.brand[i].key][0].key !== "Andere")
							) ?
								<SelectSearch
									allowClear={false}
									name="model"
									actions={actions}
									brand={searchFilter.brand[i].key}
									searchFilter={searchFilter}
									value={searchFilter.model !== undefined ? searchFilter.model[searchFilter.brand[i].key] : []}
									valueList={searchData.brandModels && searchFilter.modelSeries ? this.getModels(searchData.brandModels[searchFilter.brand[i].key], searchFilter.modelSeries[searchFilter.brand[i].key]) : searchData.brandModels ? searchData.brandModels[searchFilter.brand[i].key] : [] }
									multiple={true}
									handleChange={handleChange}
									showAllSelect={true}
									index={i}
								/>
							: null}
							<InputSearch
								name="modelDescription"
								actions={actions}
								value={searchFilter.brand[i].modelDescription !== undefined ? searchFilter.brand[i].modelDescription : ""}
								searchFilter={searchFilter}
								placeholder={isCarSearchClass ? "M-Sport, Xenon, HiFi..." : "Modell"}
								size="large"
								style={{ height: "32px", fontSize: "16px" }}
								handleChange={handleChange}
								index={i}
							/>
						</div>
						{i !== 0 ? (
							<div className="tcol-2" style={{ height: isCarSearchClass ? 126 : 80, display: "flex", justifyContent: "center", alignItems: isCarSearchClass ? "center" : "flex-end" }}>
								<Button
									icon="delete"
									type="dashed"
									size="default"
									style={{ padding: "0.5rem 1rem" }}
									onClick={() => this.removeBrand(item, i)}
								>
									Marke entfernen
								</Button>
							</div>
						)
						:
						(
							<div className="tcol-2">
								<span className="label">Fahrzeug eingetroffen vor</span>
								<SelectSearch
									name="eingetroffenVor"
									actions={this.props.actions}
									value={searchFilter.eingetroffenVor ? `${searchFilter.eingetroffenVor} ${searchFilter.eingetroffenVor > 1 ? " Tage" : " Tag"}` : ''}
									valueList={searchData.eingetroffenVor}
									handleChange={this.props.handleChange}
									showAllSelect={false}
									placeholder={"Tage"}
								/>
							</div>
						)
						}
					</div>
				))}

				{/* Erst wenn eine Marke ausgewählt wurde, kann eine weitere hinzugefügt werden. */}
				{this.props.searchFilter.brand && (!(this.props.searchFilter.brand[0].key === "alle")) && (
					<div className="row">
						<div className="tcol-2">
							<Button
									icon="plus"
									type="dashed"
									size="default"
									style={{ padding: "0.5rem 1rem" }}
									onClick={this.addNewBrandRow}
								>
								Marke hinzufügen
							</Button>
						</div>
					</div>
				)}
			</div>
		);
	}

}

export default BrandSelect;