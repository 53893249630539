import React from 'react';
import PropTypes from 'prop-types';

import DetailItem from 'components/vehicle/DetailItem';
import settings from "../../settings";

import * as helper from "../../helper";

const generateWeightName = (leergewicht, gesamtGewicht) => {
	let name = "";
	let zuladung = "0";

	if(leergewicht !== "0") { name += "Leergewicht / "; }
	if(gesamtGewicht !== "0") { name += "zul. Gesamtgewicht"; }

	if(leergewicht !== "0" && gesamtGewicht !== "0") {
		zuladung = helper.calculateZuladung(leergewicht, gesamtGewicht);
		if(zuladung !== "0") { name += " / Zuladung"; }
	}

	return name;
};
const generateWeightValue = (leergewicht, gesamtGewicht) => {
	let value = "";
	let zuladung = "0";

	if(leergewicht !== "0") { value += `${leergewicht} / `; }
	if(gesamtGewicht !== "0") { value += `${gesamtGewicht}`; }
	if(leergewicht !== "0" && gesamtGewicht !== "0") {
		zuladung = helper.calculateZuladung(leergewicht, gesamtGewicht);
		if(zuladung !== "0") { value += ` / ${zuladung}`; }
	}

	return value;
};

const VehicleSpecifications = ({ vehicle }) => (
	<ul className="feature-list">
		{/* Wichtiges */}
		<DetailItem value={vehicle.adId} name="Fahrzeugnummer" />
		<DetailItem value={vehicle.firstRegistration} name="Erstzulassung" />
		
		{vehicle.condition !== "Neu" && (!settings.categoryBlacklist.includes(vehicle.category)) ? 
			<DetailItem value={vehicle.mileage} name="Kilometerstand" />
		: null}

		{/* Angaben zum Motor */}
		{(!(settings.categoryBlacklist.includes(vehicle.category)) && !(vehicle.power === "0 kW (0 PS)")) && (
			<DetailItem value={vehicle.power} name="Leistung" />
		)}

		{!settings.categoryBlacklist.includes(vehicle.category) && (
			<DetailItem value={vehicle.cubicCapacity} name="Hubraum" />
		)}

		<DetailItem value={vehicle.motorart} plugInHybrid={vehicle.pluginHybrid} name="Motorart" />
		<DetailItem value={vehicle.emissionFuelConsumption != null ? vehicle.emissionFuelConsumption.consumptionPetrolType : null} name="Treibstoffart" />

		{/* Batteriekapazität */}
		{vehicle.batterieKapazitaet && (vehicle.batterieKapazitaet > 0 && (vehicle.motorart === "Elektromotor" || vehicle.motorart === "Hybrid")) ? <DetailItem value={vehicle.batterieKapazitaet + " kWh"} name="Batteriekapazität"/> : null }

		{/* Getriebe */}
		<DetailItem value={vehicle.gearbox} name="Getriebe" />

		{/* Antriebart */}
		<DetailItem value={vehicle.drivingMode} name="Antriebsart" />

		{/* Sonstiges */}
		<DetailItem value={vehicle.colorManufacturer} name="Farbe" />
		{vehicle.interiorType && vehicle.interiorColor ?  
			<DetailItem value={vehicle.interiorType + ", " + vehicle.interiorColor} name="Innenausstattung" />
		: null}
		<DetailItem value={vehicle.doorCount} name="Türen" />
		{/*
			TODO: Anders herausfinden, ob es sich um ein Motorrad handelt, oder nicht.
			Mit React Router v4 dann in Kombination mit Redux.
		*/}
		{vehicle.contactInfo.site !== "Zizenhausen" && (
			<DetailItem value={vehicle.climatisation} name="Klimatisierung" />
		)}
		<DetailItem value={vehicle.airbags} name="Airbags" />

		{/* Gewicht */}
		{vehicle.zulaessigesGesamtgewicht && vehicle.leergewicht && vehicle.zulaessigesGesamtgewicht !== "0" || vehicle.leergewicht !== "0" ?
			<DetailItem
				value={generateWeightValue(vehicle.leergewicht, vehicle.zulaessigesGesamtgewicht)}
				name={`${generateWeightName(vehicle.leergewicht, vehicle.zulaessigesGesamtgewicht)} (kg)`}
			/>
		: null
		}
		{/* Maße */}
		{vehicle.height && vehicle.length && vehicle.width && vehicle.height !== "0" && vehicle.length !== "0" && vehicle.width !== "0" ?
			<DetailItem
				value={`${vehicle.length} / ${vehicle.width} / ${vehicle.height}`}
				name={`Länge / Breite / Höhe (mm)`}
			/>
		: null
		}
	</ul>
);

VehicleSpecifications.propTypes = {
	vehicle: PropTypes.object.isRequired
};

export default VehicleSpecifications;