import React from 'react';

const Item = ({ name, selected, svg, onClick, classes, text="" }) => {
	return (
		<div
			onClick={(e) => onClick(e, name)}
			className={`
				item
				${classes + " "}
				${selected === name ? "item-selected" : null}
			`}
		>{text !== "" ? text : svg}</div>
	);
};

export default Item;