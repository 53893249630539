let settings = {
	priceMax: 100000,
	kmMax: 120000,
	regMax: new Date().getFullYear(),
	servicePath: "https://staging.fahrzeugmarkt.auer-gruppe.de/api/fms",
	//servicePath: "https://fahrzeugmarkt.auer-gruppe.de/api/fms",
	imagePath: "https://fahrzeugmarkt.auer-gruppe.de/fm/images/",
	mileageEmissionThreshhold: 2500, // Threshhold to treat used cars with very low mileage as new cars (important for emissions)
	fallbackImage: "fallback.jpg",
	userInputDelay: 300, // Delay for user input, prevents requests for every keystroke
	// baseName: "/fahrzeugmarkt/"
	categoryBlacklist: ["Zeltanhänger", "Wohnwagen"],
	definitionElectricNames: [
		"elektro",
		"elektromotor",
		"electro",
		"electromotor",
		"elecktro",
		"elecktromotor",
		"electric"
	],
	definitionPetrolNames: [
		"petrol",
		"patrol",
		"gasoline",
		"gasolin",
		"gas",
		"benzin",
		"bencin",
		"bensin",
		"pensin",
		"penzin",
		"pencin",
	],
	definitionDieselNames: [
		"diesel",
		"diesehl",
		"disel",
		"disell",
		"diesell"
	],
	definitionHybridNames: [
		"hybrid",
		"hibrid",
		"hybried",
		"hypried"
	]
};

if (process.env.NODE_ENV === "development") {
	//settings.servicePath = "https://fahrzeugmarkt.auer-gruppe.de/api/fms"

	settings.servicePath = "https://staging.fahrzeugmarkt.auer-gruppe.de/api/fms"
	//settings.servicePath = "//192.168.2.117:8080/fms"
};

export default settings;
