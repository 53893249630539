import "../styles/main.less";
import React from "react";
import ReactDOM from "react-dom";

import * as searchActions from "actions/searchActions";
import configureStore from "./store/configureStore";
import App from "containers/App";
import qs from "querystring";

import axios from "axios";
import settings from 'settings';

let store = configureStore();

if ('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

(async () => {
	let { searchMeta, searchFilter, count, countAfterExecute } = store.getState().search;
	let searchParams = qs.decode(location.search.substring(1));

	if(searchParams.searchClass) { await store.dispatch(searchActions.getSearchData(searchParams.searchClass)); }
	else { await store.dispatch(searchActions.getSearchData(searchMeta.searchClass)); }
	await store.dispatch(searchActions.getCount(searchFilter, searchMeta));

	if (module.hot) {
		module.hot.accept();
	}

	if(!localStorage.getItem("loggedIn")) {
		localStorage.setItem("SEARCH_FILTER_STATE", "{}");
		localStorage.setItem("SEARCH_META_STATE", "{}");
	}

	if(!sessionStorage.getItem("visited") || sessionStorage.getItem("visited" !== true)) {
		axios.post(`${settings.servicePath}/kpi/page?page=main`);
		sessionStorage.setItem("visited", true);
	}

	ReactDOM.render(
		<App store={store} />, document.getElementById("search")
	);
})();