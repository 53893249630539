import React, { Component } from "react";
import {Pagination, ConfigProvider, Spin, Icon, Button, Menu, BackTop, Drawer, Modal} from "antd";
import deDE from "antd/lib/locale-provider/de_DE";
import { StickyContainer } from "react-sticky";
import jump from "jump.js";
import { easeInOutQuart } from "ez.js";
import qs from "querystring";
import { Helmet } from "react-helmet";
import eventListener from "eventlistener";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as searchActions from "actions/searchActions";
import * as siteActions from "actions/siteActions";
import Footer from "components/layout/Footer";
import VehicleHeader from "components/vehicle-header/VehicleHeader";
import settings from "settings";
import * as helper from "helper";
import * as customerServicesHelper from "../customerServicesHelper";

import SearchForm from "containers/Search/SearchForm";
import FormButtons from "containers/Search/FormButtons";
import DetailSearch from "containers/Search/DetailSearch";
import SortMenu from "containers/Search/SortMenu";
import Disclaimer from "containers/Search/Disclaimer";
import SearchResults from "containers/Search/SearchResults";
import TopAnnotation from "containers/Search/TopAnnotation";
import CookieBanner from "../components/CookieBanner";
import Cookies from "js-cookie";
import Comparsion from "./Comparsion";

import axios from "axios";

class Search extends Component {

	constructor(props) {
		super(props);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleShowSizeChange = this.handleShowSizeChange.bind(this);
		this.handlePaginationChange = this.handlePaginationChange.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.handleVehicleChange = this.handleVehicleChange.bind(this);
		this.handleColorChange = this.handleColorChange.bind(this);
		this.toggleDetailedSearch = this.toggleDetailedSearch.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.state = {
			loading: false,
			saving: false,
			showDetailedSearch: false,
			toggledPanels: [],
			hasSearched: false,
			paginationCount: 0,
			newFilterDisabled: false,
			// Timer to determine if user is still typing and prevent requests
			typeTimer: 0,
			currentScrollPosition: 0,
			reloadFilter: false,
			visible: false,
			filterSaved: false,
			filterAlreadyExist: false,
			notRegistered: false,
			prevSearchFilter: null,
			initialFilter: {
				brand: [{ key: "alle" }],
				condition: ["NEW", "USED"],
			},

		};
	}

	/**
	 *
	 * @param {boolean} jumpToList set if you want page to transition to list
	 */
	handleSearch(jumpToList = false, adId = null, resetPage = true, clickedSearch = true) {
		let { searchFilter, searchMeta } = this.props.search;
		this.setState({ loading: true });
		resetPage && this.props.actions.setSearchMetaKey("page", 1);

			this.props.actions.executeSearch(searchFilter, searchMeta).then(res => {
				this.setState({loading: false, hasSearched: true, reloadFilter: false})

				if (jumpToList) {
					adId && document.getElementById(`{adId${adId}`) ? jump(`#ad${adId}`, {offset: -10, easing: easeInOutQuart}) : jump("#sort", {
						duration: 1500,
						offset: -10,
						easing: easeInOutQuart
					});
				}

				this.props.actions.setSearchReducerKey("countAfterExecute", this.props.search.count);
				this.props.actions.setSearchReducerKey("searchFilterAfterExecute", this.props.search.searchFilter);
			});

			if(clickedSearch) {
				let filterData = helper.formatSearchFilterData(this.props.search.searchFilter, this.props.search.searchData, this.props.search.searchMeta)
				axios.post(`${settings.servicePath}/kpi/search`, filterData);
			}

		//this.setState({filterSaved: false, filterAlreadyExist: false});
	}

	async handleReset() {
		this.setState({ hasSearched: false, toggledPanels: [] });
		this.props.actions.setNewFilterDisabled(false);
		this.props.actions.resetSearchState();
		localStorage.removeItem("SEARCH_FILTER_STATE");
		localStorage.removeItem("SEARCH_META_STATE");
		this.handleChange();
		this.props.actions.getCount(this.props.search.searchFilter, this.props.search.searchMeta);
	}

	handleChange() {
		if (this.state.typeTimer) {
			clearTimeout(this.state.typeTimer);
		}

		this.state.typeTimer = setTimeout(() => {
			let { searchFilter, searchMeta } = this.props.search;

			this.props.actions.getCount(searchFilter, searchMeta);
			this.executeAutomaticSearch();
			this.setState({filterSaved: false, filterAlreadyExist: false});
		}, settings.userInputDelay);
	}

	setScrollPosition = () => {
		this.setState({ currentScrollPosition: window.scrollY }, () => {
		});
	}

	toggleNewCheckbox = () => {
		this.props.actions.setNewFilterDisabled(!this.props.search.newFilterDisabled)
		this.props.actions.setSearchFilterKey("condition", ["USED"]);
		setTimeout(() => {
			this.executeAutomaticSearch();
			this.setState({filterSaved: false, filterAlreadyExist: false});
		}, 0);
	}

	handleShowSizeChange(current, size) {
		this.props.actions.setSearchMetaKey("pageSize", size);
	}

	handlePaginationChange(pageNumber) {
		localStorage.setItem("SEARCH_PAGE", pageNumber);
		this.props.actions.setSearchMetaKey("page", pageNumber);
	}

	handleSort(field, orientation = null) {
		let { searchMeta } = this.props.search;
		this.props.actions.setSearchMetaKey("sortDescending", orientation ? orientation : !searchMeta.sortDescending);
		this.props.actions.setSearchMetaKey("sortField", field);
	}

	handleEingetroffen = (data) => {
		this.props.actions.setSearchFilterKey("eingetroffenVor", data);
	}

	handleVehicleChange(name, resetFilter = true) {
		this.setState({ hasSearched: false, filterSaved: false, filterAlreadyExist: false });
		resetFilter && this.handleReset();

		this.props.actions.setSearchMetaKey("searchClass", name);
		this.props.actions.getSearchData(name);
		this.props.actions.setSearchRequestData(null);

		if(resetFilter) {
			setTimeout(() => {
				let { searchMeta, searchFilter } = this.props.search;
				this.props.actions.getCount(searchFilter, searchMeta);
			}, 0);
		}
	}

	handleColorChange(colors) {
		this.props.actions.setSearchFilterKey("exteriorColor", colors);
		setTimeout(() => {
			let { searchMeta, searchFilter } = this.props.search;

				this.props.actions.getCount(searchFilter, searchMeta);
				this.executeAutomaticSearch();
				this.setState({filterSaved: false, filterAlreadyExist: false});

		}, 0);
	}

	handleCheckboxChange(e, elName) {
		let name = e != null ? e.target.name : elName;
		let condition = Object.assign([], this.props.search.searchFilter.condition) || [];

		if (condition.includes(name)) {
			let i = condition.indexOf(name);
			condition.splice(i, 1);
		} else {
			condition.push(name);
		}

		this.props.actions.setSearchFilterKey("condition", condition);

		helper.log.debug(name, condition);

		if (condition.includes("NEW") && !condition.includes("USED")) {
			this.props.actions.getBrandsList(this.props.search.searchMeta, true);
		} else if (condition.includes("USED") && !condition.includes("NEW")) {
			this.props.actions.getBrandsList(this.props.search.searchMeta, false, true);
		} else { this.props.actions.getBrandsList(this.props.search.searchMeta, false); }

		if (!condition.includes("USED")) {
			this.props.actions.setSearchFilterKey("usageType", []);
			this.props.actions.setNewFilterDisabled(false)
		}

		setTimeout(() => {
			let { searchFilter, searchMeta } = this.props.search;

				this.props.actions.getCount(searchFilter, searchMeta);
				this.executeAutomaticSearch();
				this.setState({filterSaved: false, filterAlreadyExist: false});

		}, 0);
	}

	toggleDetailedSearch() {
		this.setState({ showDetailedSearch: !this.state.showDetailedSearch, toggledPanels: !this.state.showDetailedSearch === true ? ['1','2','3','4','5','6'] : [] });
	}

	/**
	 * Wenn der Kunde bereits einmal gesucht hat, so soll die Ergebnisliste automatisch
	 * aktualisiert werden, wenn dieser einen Parameter abändert.
	 */
	executeAutomaticSearch = () => {
		if (this.state.hasSearched) {
			this.handleSearch(false, null, true, false);
		}
	}

	executeSearch = (hash) => {
		this.setState({ loading: true });
		this.props.actions.executeSearch(this.props.search.searchFilter, this.props.search.searchMeta)
			.then(res => {
				if (this.props.site.isLoading) {
					setTimeout(() => {
						if (hash != null && hash != undefined) {
							let vehicleElement = document.getElementById(hash.replace("#", ""));
							if (vehicleElement) {
								let elementId = hash.replace("#", "");
								vehicleElement.scrollIntoView();
							}
						}
						this.props.siteActions.setPageLoadingStatus(false);
					}, 150);
				}
				this.setState({ loading: false });
			});
	}

	componentDidUpdate(prevProps, prevState) {
		let { searchMeta } = prevProps.search;

		// Search meta changed
		if (
			!this.state.reloadFilter &&
			(searchMeta.sortDescending !== this.props.search.searchMeta.sortDescending ||
			searchMeta.sortField !== this.props.search.searchMeta.sortField ||
			searchMeta.eingetroffenVor !== this.props.search.searchMeta.eingetroffenVor)
		) {
			this.executeSearch();
		}

		// Pagination change
		if (
			!this.state.reloadFilter &&
			(searchMeta.pageSize !== this.props.search.searchMeta.pageSize ||
			searchMeta.page !== this.props.search.searchMeta.page)
		) {
			this.executeSearch();
		}

		// Hash vorhanden?
		if (!this.state.reloadFilter && window.location.hash) {
			let hash = window.location.hash;
			history.replaceState("", document.title, window.location.pathname + window.location.search);
			this.executeSearch();
		}

		if(prevProps.site.comparsionVehicles !== this.props.site.comparsionVehicles && this.props.site.comparsionVehicles.length === 0) {
			this.setState({visible: false});
		}
	}

	handleScrollChange = (e) => {
		localStorage.setItem("SEARCH_SCROLL_Y", window.scrollY);
	}

	async insertPrevFilter(searchParams) {
		let { searchFilter } = this.props.search;

		if (searchParams.onlyMotorhome) {
			this.props.actions.setSearchMetaKey("onlyMotorhome", searchParams.onlyMotorhome);
		}

		if (searchParams.searchClass) {
			await this.props.actions.setSearchMetaKey("searchClass", searchParams.searchClass);
			await this.props.actions.getSearchData(searchParams.searchClass);
		}

		if(searchParams.brand === "BMW" || (Object.prototype.toString.call(searchParams.brand) === "[object Array]" && searchParams.brand.filter(brand => brand.key === "BMW"))) {
			await this.props.actions.loadModelSeries(searchParams.searchClass);
		}

		if(searchParams.condition) {
			if(searchParams.condition.includes("NEW") && !searchParams.condition.includes("USED")) {
				this.props.actions.setSearchFilterKey("condition", ["NEW"]);
			}
			else if(searchParams.condition.includes("USED") && !searchParams.condition.includes("NEW")) {
				this.props.actions.setSearchFilterKey("condition", ["USED"]);
			}
			else {
				this.props.actions.setSearchFilterKey("condition", ["NEW", "USED"]);
			}
		}

		if (searchParams.dimsBrands) {
			searchParams.brand = JSON.parse(searchParams.dimsBrands);
		}
		if (searchParams.dimsModels) {
			searchParams.model = JSON.parse(searchParams.dimsModels);
		}
		if (searchParams.dimsSeries) {
			searchParams.modelSeries = JSON.parse(searchParams.dimsSeries);
		}
		if (searchParams.brand) {
			let searchParamBrands = [];

			const dataIncludesBrand = (brandToCheck) => this.props.search.searchData.brands.filter(brand => brand.key === brandToCheck).length > 0;

			if (Object.prototype.toString.call(searchParams.brand) === "[object Array]") {
				searchParams.brand.forEach(async (brand, index) => {
					if (dataIncludesBrand(brand) || dataIncludesBrand(brand.key)) {
						if(searchParams.model && !Array.isArray(searchParams.model)) {
							let models = [];

							Object.entries(searchParams.model).map(([key,value])=>{
								models.push({brand: key, models:value});
							});

							searchParams.model = models;
						}

						if(searchParams.modelSeries && !Array.isArray(searchParams.modelSeries)) {
							let modelSeries = [];

							Object.entries(searchParams.modelSeries).map(([key,value])=>{
								modelSeries.push({brand: key, modelSeries:value});
							});

							searchParams.modelSeries = modelSeries;
						}

						let brandName = brand.key !== undefined ? brand.key : brand;
						let modelSerieIndex = searchParams.modelSeries ? searchParams.modelSeries.findIndex(modelSerie => modelSerie.brand === brandName) : -1;
						let modelIndex = searchParams.model ? searchParams.model.findIndex(model => model.brand === brandName) : -1;
						let models = [];
						let modelSeries = [];

						if(modelIndex !== -1) {
							models = (searchParams.model[modelIndex].models && !searchParams.model[modelIndex].models.includes("EMPTY")) ? searchParams.model[modelIndex].models : [];
						} else {
							models = searchParams.model && searchParams.model[brandName] && searchParams.model[brandName];
						}
						if(modelSerieIndex !== -1) {
							modelSeries = (searchParams.modelSeries[modelSerieIndex].modelSeries && !searchParams.modelSeries[modelSerieIndex].modelSeries.includes("EMPTY")) ? searchParams.modelSeries[modelSerieIndex].modelSeries : [];
						} else {
							modelSeries = searchParams.modelSeries && searchParams.modelSeries[brandName] && searchParams.modelSeries[brandName];
						}

						searchParamBrands.push({
							key: brandName,
							modelDescription: searchParams.modelDescription ? searchParams.modelDescription : brand.modelDescription !== "EMPTY" ? brand.modelDescription : "",
							modelSerie: modelSeries,
							model: models });
					} else if(brand.key === "alle") {
						searchParamBrands.push({
							key: brand.key,
							modelDescription: searchParams.modelDescription ? searchParams.modelDescription : brand.modelDescription !== "EMPTY" ? brand.modelDescription : "",
						});
					}
				});
			} else {
				if (dataIncludesBrand(searchParams.brand)) {
					searchParamBrands.push({ key: searchParams.brand, modelDescription: searchParams.modelDescription, modelSerie: searchParams.modelSerie ? [searchParams.modelSerie] : [], model: searchParams[`model_${searchParams.brand}`] });
				}
			}

			if (searchParamBrands.length > 0) {
				let filterBrands = [];
				for (let searchParamBrand of searchParamBrands) {
					filterBrands.push({key: searchParamBrand.key, modelDescription: searchParamBrand.modelDescription});
				}
				this.props.actions.setSearchFilterKey("brand", filterBrands);

				for (let item of searchParamBrands) {
					await this.props.actions.loadBrandModels(item.key, this.props.search.searchMeta.searchClass, item.modelSerie);

					let brandModels = item.model;

					if ((!!brandModels) && (brandModels.length > 0)) {
						let validatedModels = [];

						if (Object.prototype.toString.call(brandModels) === "[object String]") {
							brandModels = [brandModels];
						}

						brandModels.forEach(queryModel => {
							let isValid = this.props.search.searchData.brandModels[item.key].filter(model => model.key === queryModel).length > 0;
							if (isValid) {
								validatedModels.push(queryModel);
							}
						});

						let newModels = {};

						if (this.props.search.searchFilter.model !== undefined) {
							newModels = Object.assign({}, this.props.search.searchFilter.model);
						}

						newModels[item.key] = validatedModels;

						this.props.actions.setSearchFilterKey("model", newModels);
					} else {
						let newModels = {};

						if (this.props.search.searchFilter.model !== undefined) {
							newModels = Object.assign({}, this.props.search.searchFilter.model);
						}

						this.props.actions.setSearchFilterKey("model", newModels);
					}
					if (item.modelSerie && item.modelSerie.length > 0) {
						let newModelSerie = {};

						if (!Object.prototype.toString.call(item.modelSerie) === "[object Array]") {
							item.modelSerie = [item.modelSerie];
						}

						if (this.props.search.searchFilter.modelSeries !== undefined) {
							newModelSerie = Object.assign({}, this.props.search.searchFilter.modelSeries);
						}

						newModelSerie[item.key] = item.modelSerie;

						this.props.actions.setSearchFilterKey("modelSeries", newModelSerie);
					} else {
						let newModelSerie = {};

						if (this.props.search.searchFilter.modelSeries !== undefined) {
							newModelSerie = Object.assign({}, this.props.search.searchFilter.modelSeries);
						}
						this.props.actions.setSearchFilterKey("modelSeries", newModelSerie);
					}
				}
			}
		} else {
			this.props.actions.setSearchFilterKey("brand", [{key: "alle", modelDescription: null}]);
			this.props.actions.setSearchFilterKey("model", null);
			this.props.actions.setSearchFilterKey("modelSeries", null);
		}

		if(searchParams.km && searchParams.km[0]) {searchParams.kmMin = searchParams.km[0];}
		if(searchParams.km && searchParams.km[1]) {searchParams.kmMax = searchParams.km[1];}
		if (searchParams.kmMin || searchParams.kmMax) {
			let currentKmState = Object.assign([], searchFilter.km);

			if (searchParams.kmMin && parseInt(searchParams.kmMin) > 0) { currentKmState[0] = parseInt(searchParams.kmMin); }
			else { currentKmState[0] = 0; }

			if (searchParams.kmMax && parseInt(searchParams.kmMax) > 0) { currentKmState[1] = parseInt(searchParams.kmMax); }
			else { currentKmState[1] = settings.kmMax; }

			this.props.actions.setSearchFilterKey("km", currentKmState);
		} else { this.props.actions.setSearchFilterKey("km", null); }

		if(searchParams.reg && searchParams.reg[0]) {searchParams.regMin = searchParams.reg[0];}
		if(searchParams.reg && searchParams.reg[1]) {searchParams.regMax = searchParams.reg[1];}
		if (searchParams.regMin || searchParams.regMax) {
			let currentRegState = Object.assign([], searchFilter.reg);

			if (searchParams.regMin && parseInt(searchParams.regMin) > 0) { currentRegState[0] = parseInt(searchParams.regMin); }
			else { currentRegState[0] = 1988; }

			if (searchParams.regMax && parseInt(searchParams.regMax) > 0) { currentRegState[1] = parseInt(searchParams.regMax); }
			else { currentRegState[1] = settings.regMax; }

			this.props.actions.setSearchFilterKey("reg", currentRegState);
		} else { this.props.actions.setSearchFilterKey("reg", null); }

		if(searchParams.price && searchParams.price[0]) {searchParams.priceMin = searchParams.price[0];}
		if(searchParams.price && searchParams.price[1]) {searchParams.priceMax = searchParams.price[1];}
		if (searchParams.priceMin || searchParams.priceMax) {
			let currentPriceState = Object.assign([], searchFilter.price);

			if (searchParams.priceMin && parseInt(searchParams.priceMin) > 0) { currentPriceState[0] = parseInt(searchParams.priceMin); }
			else { currentPriceState[0] = 0; }

			if (searchParams.priceMax && parseInt(searchParams.priceMax) > 0) { currentPriceState[1] = parseInt(searchParams.priceMax); }
			else { currentPriceState[1] = settings.priceMax; }

			this.props.actions.setSearchFilterKey("price", currentPriceState);
		} else { this.props.actions.setSearchFilterKey("price", null); }

		if(searchParams.usageType) {
			if(!Array.isArray(searchParams.usageType)) { searchParams.usageType = searchParams.usageType.includes(';') ? searchParams.usageType.split(';') : [searchParams.usageType] }
			this.props.actions.setSearchFilterKey("usageType", searchParams.usageType);
		} else { this.props.actions.setSearchFilterKey("usageType", null); }

		if (searchParams.category) {
			if(!Array.isArray(searchParams.category)) { searchParams.category = searchParams.category.includes(';') ? searchParams.category.split(';') : [searchParams.category] }
			this.props.actions.setSearchFilterKey("category", searchParams.category);
		} else { this.props.actions.setSearchFilterKey("category", null); }

		if(searchParams.doorCount) {
			if(!Array.isArray(searchParams.doorCount)) { searchParams.doorCount = searchParams.doorCount.includes(';') ? searchParams.doorCount.split(';') : [searchParams.doorCount] }
			this.props.actions.setSearchFilterKey("doorCount", searchParams.doorCount);
		} else { this.props.actions.setSearchFilterKey("doorCount", null); }

		if(searchParams.motorart) {
			if(!Array.isArray(searchParams.motorart)) { searchParams.motorart = searchParams.motorart.includes(';') ? searchParams.motorart.split(';') : [searchParams.motorart] }
			this.props.actions.setSearchFilterKey("motorart", searchParams.motorart);
		} else { this.props.actions.setSearchFilterKey("motorart", null); }

		if(searchParams.sites) {
			if(!Array.isArray(searchParams.sites)) { searchParams.sites = searchParams.sites.includes(';') ? searchParams.sites.split(';') : [searchParams.sites] }
			this.props.actions.setSearchFilterKey("sites", searchParams.sites);
		} else { this.props.actions.setSearchFilterKey("sites", null); }

		if(searchParams.emissionStickers) {
			if(!Array.isArray(searchParams.emissionStickers)) { searchParams.emissionStickers = searchParams.emissionStickers.includes(';') ? searchParams.emissionStickers.split(';') : [searchParams.emissionStickers] }
			this.props.actions.setSearchFilterKey("emissionStickers", searchParams.emissionStickers);
		} else { this.props.actions.setSearchFilterKey("emissionStickers", null); }

		if(searchParams.drivingModes) {
			if(!Array.isArray(searchParams.drivingModes)) { searchParams.drivingModes = searchParams.drivingModes.includes(';') ? searchParams.drivingModes.split(';') : [searchParams.drivingModes] }
			this.props.actions.setSearchFilterKey("drivingModes", searchParams.drivingModes);
		} else { this.props.actions.setSearchFilterKey("drivingModes", null); }

		if(searchParams.gearBox) {
			if(!Array.isArray(searchParams.gearBox)) { searchParams.gearBox = searchParams.gearBox.includes(';') ? searchParams.gearBox.split(';') : [searchParams.gearBox] }
			this.props.actions.setSearchFilterKey("gearBox", searchParams.gearBox);
		} else { this.props.actions.setSearchFilterKey("gearBox", null); }

		if(searchParams.exteriorColor) {
			if(!Array.isArray(searchParams.exteriorColor)) { searchParams.exteriorColor = searchParams.exteriorColor.includes(';') ? searchParams.exteriorColor.split(';') : [searchParams.exteriorColor] }
			this.props.actions.setSearchFilterKey("exteriorColor", searchParams.exteriorColor);
		} else { this.props.actions.setSearchFilterKey("exteriorColor", null); }

		if(searchParams.accidentFree) {
			this.props.actions.setSearchFilterKey("accidentFree", searchParams.accidentFree);
		} else { this.props.actions.setSearchFilterKey("accidentFree", null); }

		if(searchParams.unrepaired) {
			this.props.actions.setSearchFilterKey("unrepaired", searchParams.unrepaired);
		} else { this.props.actions.setSearchFilterKey("unrepaired", null); }

		if(searchParams.eingetroffenVor) {
			this.props.actions.setSearchFilterKey("eingetroffenVor", searchParams.eingetroffenVor);
		} else { this.props.actions.setSearchFilterKey("eingetroffenVor", null); }

		if(searchParams.features) {
			if(typeof searchParams.features !== "object") { searchParams.features = JSON.parse(searchParams.features); }

			Object.entries(searchParams.features).map(([key,value]) => {
				if(value !== null && value !== undefined) {
					switch (key) {
						case "other":
						case "other_fuel":
						case "comfort":
						case "infotainment":
						case "interior_exterior":
						case "security": value = value.includes(";") ? value.split(";") : [value]; break;
						default: value = value.includes(";") ? value.split(";") : value; break;
					}

					this.props.actions.setSearchFilterFeatureKey((key.toUpperCase()), null);
					this.props.actions.setSearchFilterFeatureKey((key.toUpperCase()), value);
				} else {
					this.props.actions.setSearchFilterFeatureKey((key.toUpperCase()), null);
				}
			});
		}

		if(searchParams.power && searchParams.power[0]) {searchParams.powerMin = searchParams.power[0];}
		if(searchParams.power && searchParams.power[1]) {searchParams.powerMax = searchParams.power[1];}
		if (searchParams.powerMin || searchParams.powerMax) {
			let currentPowerState = Object.assign([], searchFilter.power);

			if (searchParams.powerMin && parseInt(searchParams.powerMin) > 0) { currentPowerState[0] = parseInt(searchParams.powerMin); }
			else { currentPowerState[0] = this.props.search.searchData.minPower; }

			if (searchParams.powerMax && parseInt(searchParams.powerMax) > 0) {	currentPowerState[1] = parseInt(searchParams.powerMax); }
			else { currentPowerState[1] = this.props.search.searchData.maxPower; }

			this.props.actions.setSearchFilterKey("power", currentPowerState);
		} else { this.props.actions.setSearchFilterKey("power", null); }

		if(searchParams.width && searchParams.width[0]) {searchParams.widthMin = searchParams.width[0];}
		if(searchParams.width && searchParams.width[1]) {searchParams.widthMax = searchParams.width[1];}
		if (searchParams.widthMin || searchParams.widthMax) {
			let currentWidthState = Object.assign([], searchFilter.width);

			if (searchParams.widthMin && parseInt(searchParams.widthMin) > 0) { currentWidthState[0] = parseInt(searchParams.widthMin); }
			else { currentWidthState[0] = this.props.search.searchData.minWidth; }

			if (searchParams.widthMax && parseInt(searchParams.widthMax) > 0) { currentWidthState[1] = parseInt(searchParams.widthMax); }
			else { currentWidthState[1] = this.props.search.searchData.maxWidth; }

			this.props.actions.setSearchFilterKey("width", currentWidthState);
		} else { this.props.actions.setSearchFilterKey("width", null); }

		if(searchParams.weight && searchParams.weight[0]) {searchParams.weightMin = searchParams.weight[0];}
		if(searchParams.weight && searchParams.weight[1]) {searchParams.weightMax = searchParams.weight[1];}
		if (searchParams.weightMin || searchParams.weightMax) {
			let currentWeightState = Object.assign([], searchFilter.weight);

			if (searchParams.weightMin && parseInt(searchParams.weightMin) > 0) { currentWeightState[0] = parseInt(searchParams.weightMin);	}
			else { currentWeightState[0] = this.props.search.searchData.minWeight; }

			if (searchParams.weightMax && parseInt(searchParams.weightMax) > 0) { currentWeightState[1] = parseInt(searchParams.weightMax); }
			else { currentWeightState[1] = this.props.search.searchData.maxWeight; }

			this.props.actions.setSearchFilterKey("weight", currentWeightState);
		} else { this.props.actions.setSearchFilterKey("weight", null); }

		if(searchParams.height && searchParams.height[0]) {searchParams.heightMin = searchParams.height[0];}
		if(searchParams.height && searchParams.height[1]) {searchParams.heightMax = searchParams.height[1];}
		if (searchParams.heightMin || searchParams.heightMax) {
			let currentHeightState = Object.assign([], searchFilter.height);

			if (searchParams.heightMin && parseInt(searchParams.heightMin) > 0) { currentHeightState[0] = parseInt(searchParams.heightMin); }
			else { currentHeightState[0] = this.props.search.searchData.minHeight; }

			if (searchParams.heightMax && parseInt(searchParams.heightMax) > 0) { currentHeightState[1] = parseInt(searchParams.heightMax); }
			else { currentHeightState[1] = this.props.search.searchData.maxHeight; }

			this.props.actions.setSearchFilterKey("height", currentHeightState);
		} else { this.props.actions.setSearchFilterKey("height", null); }

		if(searchParams.length && searchParams.length[0]) {searchParams.lengthMin = searchParams.length[0];}
		if(searchParams.length && searchParams.length[1]) {searchParams.lengthMax = searchParams.length[1];}
		if (searchParams.lengthMin || searchParams.lengthMax) {
			let currentLengthState = Object.assign([], searchFilter.length);

			if (searchParams.lengthMin && parseInt(searchParams.lengthMin) > 0) { currentLengthState[0] = parseInt(searchParams.lengthMin); }
			else { currentLengthState[0] = this.props.search.searchData.minLength; }

			if (searchParams.lengthMax && parseInt(searchParams.lengthMax) > 0) { currentLengthState[1] = parseInt(searchParams.lengthMax); }
			else { currentLengthState[1] = this.props.search.searchData.maxLength; }

			this.props.actions.setSearchFilterKey("length", currentLengthState);
		} else { this.props.actions.setSearchFilterKey("length", null); }

		let newToggledPanels = [];
		if(searchParams.doorCount || searchParams.gearBox || searchParams.exteriorColor || searchParams.powerMin || searchParams.powerMax || searchParams.motorart || searchParams.sites ||
			searchParams.emissionStickers || searchParams.accidentFree || searchParams.unrepaired || searchParams.drivingModes || searchParams.lengthMin || searchParams.lengthMax ||
			searchParams.widthMin || searchParams.widthMax || searchParams.heightMin || searchParams.heightMax || searchParams.weightMin || searchParams.weightMax) {
			newToggledPanels.push("1");
		}

		if(searchParams.features) {
			Object.entries(searchParams.features).map(([key, value]) => {
				if (value !== null && value !== undefined && value !== "") {
					switch (key) {
						case "headlights":
						case "daytime_running_lights":
						case "cruise_control":
						case "park_assist":
						case "bending_lights":
						case "security":
							newToggledPanels.push("2");
							break;
						case "trailer_coupling":
						case "climatisation":
						case "comfort":
							newToggledPanels.push("3");
							break;
						case "radio":
						case "infotainment":
							newToggledPanels.push("4");
							break;
						case "interior_exterior":
							newToggledPanels.push("5");
							break;
						case "other":
						case "other_fuel":
						case "emergency":
							newToggledPanels.push("6");
							break;
					}
				}
			})
		}
		this.setState({toggledPanels: newToggledPanels});
	}

	async componentDidMount() {
		let prevSearchFilter = this.props.search.searchRequest ? Object.assign({}, this.props.search.searchRequest) : localStorage.getItem("SEARCH_FILTER_STATE") ? JSON.parse(localStorage.getItem("SEARCH_FILTER_STATE")) : null;
		let searchParams = {};

		if(prevSearchFilter && Object.keys(prevSearchFilter).length > 0) {
			if(!prevSearchFilter.searchClass) { prevSearchFilter.searchClass = localStorage.getItem("SEARCH_META_STATE") ? JSON.parse(localStorage.getItem("SEARCH_META_STATE")).searchClass : null; }
			//if(prevSearchFilter.category && !Array.isArray(prevSearchFilter.category)) { prevSearchFilter.category = prevSearchFilter.category.split(";"); }
			//if(prevSearchFilter.gearBox) { prevSearchFilter.gearBox = prevSearchFilter.gearBox.split(";"); }
			//if(prevSearchFilter.usageType) { prevSearchFilter.usageType = prevSearchFilter.usageType.split(";"); }
		}

		localStorage.setItem("lastMountedComponent", "Search");

		this.refs.container.addEventListener("keypress", e => {
			// On Enter
			if (e.keyCode == 13) {
				this.handleSearch(true);
			}
		});

		if (location.search || (prevSearchFilter && Object.keys(prevSearchFilter).length > 0)) {
			this.setState({reloadFilter: true});

			if(location.search && !qs.decode(location.search.substring(1)).c) {
				searchParams = qs.decode(location.search.substring(1));
			} else {
				searchParams = prevSearchFilter;
			}

			if(this.props.search.daysBack > 0) {
				this.handleEingetroffen(this.props.search.daysBack);
			}
			this.setFilterNSearch(searchParams, (location.search || this.props.search.jumpToList));
		} else {
			// Beim zurückgehen vom Browser, werden hier die Filter resettet
			if(localStorage.getItem("adID") && document.getElementById(`{adId${localStorage.getItem("adID")}`)){
				jump(`#ad${localStorage.getItem("adID")}`, { offset: -10, easing: easeInOutQuart });
				localStorage.removeItem("adID");
			}
			await this.props.actions.getCount(this.props.search.searchFilter, this.props.search.searchMeta);
		}
	}

	setFilterNSearch = async (searchParams, jumpToList) => {
		await this.insertPrevFilter(searchParams);
		this.setState({initialFilter: (location.search || localStorage.getItem("loggedIn")) ? {} : this.props.search.searchFilter});

		if (searchParams.details) {
			this.state.showDetailedSearch = true;
		}

		await this.props.actions.getCount(this.props.search.searchFilter, this.props.search.searchMeta);
		jumpToList && await this.handleSearch(jumpToList, searchParams.adId, false, false);

		localStorage.removeItem("loggedIn");
	}

	onCreateFilter = () => {
		let filterData = null;

		this.setState({saving: true});
		filterData = helper.formatSearchFilterData(this.props.search.searchFilter, this.props.search.searchData, this.props.search.searchMeta);

		customerServicesHelper.saveFilter(filterData)
		.then(result => {
			if(result.status === "FOUND") {
				this.props.existingFilter();
				this.setState({filterAlreadyExist: true, saving: false});
			}
			if(result.status === "CREATED") {
				this.props.savedFilter();
				this.props.updateSearchRequests();
				this.props.actions.setSearchRequestData(0, false, {suchanfrageId: result.suchanfrageId});
				this.setState({filterSaved: true, saving: false});
			}
			if(result.status === "BAD_REQUEST") {
				this.setState({notRegistered: true, saving: false});
			}
		})
	}

	onEditFilter = () => {
		const searchFilter = Object.assign({}, this.props.search.searchFilter);
		const searchMeta = Object.assign({}, this.props.search.searchMeta);

		this.setState({saving: true});
		let filterData = helper.formatSearchFilterData(searchFilter, this.props.search.searchData, searchMeta);

		customerServicesHelper.updateFilter(filterData, this.props.search.searchRequest.suchanfrageId)
			.then(result => {
				if(result === "OK") {
					//this.props.actions.resetSearchRequestData();
					this.props.savedFilter();
					this.setState({filterSaved: true, saving: false});
				} else if(result === "FOUND") {
					this.props.existingFilter();
					this.setState({filterAlreadyExist: true, saving: false});
				} else if(result === "BAD_REQUEST") {
					this.setState({notRegistered: true, saving: false});
				}
			});
	}

	componentWillUnmount() {
		eventListener.remove(window, "scroll", this.handleScrollChange);
	}

	onClickCompare = () => {
		if(this.state.visible) { this.setState({visible: false}); }
		else { this.setState({visible: true}); }
	}

	onOpenDrawer = () => { this.setState({visible: true}); }

	onClose = () => {
		this.setState({ visible: false });
	};

	togglePanel = (toggledPanels) => {
		this.setState({toggledPanels: toggledPanels});
	}

	objectsEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));

	render() {
		let { searchFilter, searchData, searchMeta, count, countAfterExecute } = this.props.search;
		const search = this.props.search;

		const loadingIcon = <Icon type="loading" style={{ fontSize: "24px" }} spin />;

		return (
			<ConfigProvider locale={deDE}>
				<div style={{opacity: Cookies.get('cookieConsent') === undefined && ".5"}}>
					<Helmet>
						<title>Suchen | Fahrzeugmarkt - Auer Gruppe</title>
						<meta name="description" content="Der Fahrzeugmarkt der Auer Gruppe. Durchsuchen Sie unseren Fahrzeugbestand mit über 1500 Fahrzeugen, die Ihnen zur Auswahl stehen!" />
						<meta name="keywords" content="Fahrzeugmarkt, Auer Gruppe, Fahrzeugbörse, BMW, BMW i, BMW M, Jaguar, Land Rover, Lotus, KTM, Bodensee, Autohaus, Stockach" />
						<meta property="og:type" content="website" />
						<meta property="og:url" content="https://fahrzeugmarkt.auer-gruppe.de" />
						<meta property="og:title" content="Fahrzeugmarkt | Auer Gruppe" />
						<meta property="og:image" content="https://auer-gruppe.de/wp-content/uploads/2018/05/Stockach-min.jpg" />
						<meta property="og:description" content="Der Fahrzeugmarkt der Auer Gruppe. Durchsuchen Sie unseren Fahrzeugbestand mit über 1500 Fahrzeugen, die Ihnen zur Auswahl stehen!" />
						<meta property="og:site_name" content="Fahrzeugmarkt" />
						<meta property="og:locale" content="de_DE" />
					</Helmet>
					<Modal footer={null} okButtonProps={{ display: "none" }} style={{width: "fit-content"}} title="Suchauftrag kann nicht gespeichert werden" visible={this.state.notRegistered} onCancel={() => this.setState({notRegistered: false})}>
						<p>Um einen Suchauftrag zu speichern müssen Sie sich mit Ihrer E-Mail Adresse in unserem System anmelden.</p>

						<h2>Ihre Vorteile</h2>

						<p>- Beliebig viele Suchaufträge speichern<br/>
						- Beliebig viele Fahrzeuge parken</p>
						<p>- Anmeldung an unserem Benachrichtigungssystem.<br/>
						Erfahren Sie sofort, wenn wir ein neues Fahrzeug passend zu Ihrem Suchauftrag in Bestand nehmen,
						oder bei Preissenkungen an einem von Ihnen geparkten Fahrzeug.</p>

						<Button style={{marginTop: ".5em"}} type={"primary"} onClick={(e) => this.props.history.push(`/customerpanel`)}>Zur Anmeldung</Button>
						<Button style={{marginLeft: ".5em"}} onClick={() => this.setState({notRegistered: false})}>Abbrechen</Button>
					</Modal>
					<StickyContainer>
						<div>
							<TopAnnotation id="back-to-search" onClick={() => jump("html")} text={"Zurück zur Suche"} top="1rem" />
							<TopAnnotation
								id="result-annotation"
								onClick={() => this.handleSearch(true)}
								text={<div><Spin indicator={loadingIcon} spinning={this.state.loading} />{count} Ergebnisse</div>}
								top="4rem"
							/>
							<VehicleHeader
								onItemChange={this.handleVehicleChange}
								value={searchMeta.searchClass}
								displayLogo={true}
								parkedVehicleCount={this.props.parkedVehicleCount}
								searchRequestsCount={this.props.searchRequestsCount}
								history={this.props.history}
							/>
						</div>
						<div className="container" ref="container">
							<SearchForm
								searchFilter={search.searchFilter}
								searchData={searchData}
								searchMeta={searchMeta}
								actions={this.props.actions}
								searchResults={search.searchResults}

								handleCheckboxChange={this.handleCheckboxChange}
								handleChange={this.handleChange}
								toggleNewCheckbox={this.toggleNewCheckbox}

								newFilterDisabled={search.newFilterDisabled}
								settings={settings}
							/>
							<FormButtons
								saving={this.state.saving}
								handleReset={this.handleReset}
								handleSearch={this.handleSearch}
								handleSave={this.onCreateFilter}
								handleEditFilter={this.onEditFilter}
								searchRequestId={search.searchRequest ? search.searchRequest.suchanfrageId : null}
								loading={this.state.loading}
								count={count}
								filterSaved={this.state.filterSaved}
							/>
						</div>
					{searchMeta.searchClass !== "AgriculturalVehicle" &&
						<div className="container">
							<DetailSearch
								searchFilter={searchFilter}
								searchData={searchData}
								searchMeta={searchMeta}
								actions={this.props.actions}

								toggleDetailedSearch={this.toggleDetailedSearch}
								handleChange={this.handleChange}
								handleColorChange={this.handleColorChange}
								togglePanel={this.togglePanel}

								showDetailedSearch={this.state.showDetailedSearch}
								toggledPanels={this.state.toggledPanels}
								settings={settings}
							/>
						</div>
					}
						{
							this.state.hasSearched && this.props.search.searchResults ?
								<div className="container" id="sort" style={{marginTop: "2rem"}}>
									<SortMenu
										searchFilter={searchFilter}
										searchData={searchData}
										searchMeta={searchMeta}
										searchResults={search.searchResults}

										handleEingetroffen={this.handleEingetroffen}
										handleSort={this.handleSort}
										handlePaginationChange={this.handlePaginationChange}
										handleShowSizeChange={this.handleShowSizeChange}

										countAfterExecute={countAfterExecute}
									/>
									<SearchResults
										searchResults={this.props.search.searchResults}
										loading={this.state.loading}
										hasSearched={this.state.hasSearched}
										siteActions={this.props.siteActions}
										updateParkedVehicles={this.props.updateParkedVehicles}
										onSetComparsionVehicle={this.props.onSetComparsionVehicle}
										comparsionVehicles={this.props.search.comparsionVehicles}
										actions={this.props.actions}
										searchActions={this.props.searchActions}
										siteData={this.props.site}

										vehicleParked={this.props.vehicleParked}
										vehicleParkedOut={this.props.vehicleParkedOut}
										vehicleCompared={this.props.vehicleCompared}
										vehicleRemovedCompare={this.props.vehicleRemovedCompare}
									/>
									{(this.props.search.searchResults && this.props.search.searchResults.length > 0) &&
									<div>
										<Pagination
											hideOnSinglePage={true}
											showSizeChanger={true}
											current={searchMeta.page}
											total={countAfterExecute}
											pageSize={searchMeta.pageSize}
											onShowSizeChange={this.handleShowSizeChange}
											onChange={pageNumber => {
												this.handlePaginationChange(pageNumber);
												jump("#results", {
													duration: 1500,
													offset: -75,
													easing: easeInOutQuart
												});
											}}
										/>
										<br/>
										<Disclaimer/>
									</div>
									}
								</div>
								:
								<div style={{marginBottom: "4rem"}}></div>
						}
				</StickyContainer>
					<div>
						<Drawer
							title="Fahrzeuge Vergleichen"
							placement={window.innerWidth >= 801 ? "right" : "bottom"}
							closable={true}
							onClose={this.onClose}
							visible={this.props.site.comparsionVehicles.length > 0 && this.state.visible}
							key={"comparsion-drawer"}
							height={"100%"}
							width={"fit-content"}
						>
							<Comparsion searchClass={this.props.search.searchMeta.searchClass} closeDrawer={() => this.setState({visible: false})} comparsionVehicles={this.props.site.comparsionVehicles} history={this.props.history} siteActions={this.props.siteActions} />
						</Drawer>
						<div style={{cursor: "pointer", display: this.props.site.comparsionVehicles.length > 0 ? "block" : "none"}} className={"comparsion-vehicle"} onClick={this.onClickCompare}>
							Vergleichsfahrzeuge
						</div>
						<Footer isSearch={true} bottom={window.innerWidth < 850 ? 110 : 0} />
					</div>
					<CookieBanner printBanner={false} />
				</div>
			</ConfigProvider >
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		search: state.search,
		site: state.site
	};
}

function mapDispatchToProps(dispatch) {
    return {
		actions: bindActionCreators(searchActions, dispatch),
		siteActions: bindActionCreators(siteActions, dispatch),
		searchActions: bindActionCreators(searchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);