import React, {Component} from "react";
import {Row, Col} from "antd";

class ComparsionSection extends Component {
    constructor(props) {
        super(props);
    }

    formatValueKey = (valueKey, addValue, vehicle) => {
        let output = "";

        if(Array.isArray(valueKey)) {
            output = [];

            valueKey.map((key, index) => {
                if(key.includes("+")) {
                    const keys = key.split("+");
                    let keyValues = null;

                    if(!vehicle[keys[0]]) {
                    } else {
                        keyValues = vehicle[keys[0]][keys[1]];

                        if (Array.isArray(keyValues)) {
                            keyValues.map(keyValue => {
                                output.push(keyValue.value);
                            });
                        }
                    }
                } else {
                    if(!vehicle[key]) {
                        output += "undefined";
                    } else {
                        output += vehicle[key];
                    }

                    if(index < (valueKey.length -1)) { output += ", "; }
                }
            })
        } else if(valueKey.includes("+")) {
            const keys = valueKey.split("+");

            if(!vehicle[keys[0]][keys[1]]) {
                output = undefined;
            } else {
                switch (keys.length) {
                    case 2:
                        output += vehicle[keys[0]][keys[1]]; break;
                    case 3:
                        output += vehicle[keys[0]][keys[1]][keys[2]]; break;
                    default:
                        output += vehicle[keys[0]];
                }
            }
        } else {
            if(!vehicle[valueKey]) { output = undefined; }
            else { output += vehicle[valueKey]; }
        }

        if(addValue && output) {
            output += " " + addValue;
        }

        if(output && output.includes(",")) {
            let newOutput = "";

            const outputParts = output.split(", ");

            outputParts.map((outputPart, index) => {
                if(outputPart !== "undefined") {
                    newOutput += outputPart;
                } else {
                    newOutput += "Keine Angaben";
                }

                if(index < (outputParts.length -1)) { newOutput += ", "; }
            });

            output = newOutput;
        }
        if(!output) {
            output = "Keine Angaben";
        }

        if(output === "CLASSIC") { output = "Oldtimer"; }
        if(output === "DEMONSTRATION") { output = "Vorführfahrzeug"; }
        if(output === "EMPLOYEES_CAR") { output = "Jahreswagen"; }

        return output;
    }

    hasDiffs = () => {
        const vehiclesLength = this.props.comparsionVehicles.length;
        const vehicles = this.props.comparsionVehicles;

        if ((vehiclesLength === 2 && (this.formatValueKey(this.props.valueKey, this.props.addValue, vehicles[0]) === this.formatValueKey(this.props.valueKey, this.props.addValue, vehicles[1]))) ||
            (vehiclesLength === 3 && (this.formatValueKey(this.props.valueKey, this.props.addValue, vehicles[0]) === this.formatValueKey(this.props.valueKey, this.props.addValue, vehicles[1])) && (formatValueKey(props.valueKey, props.addValue, vehicles[0]) === formatValueKey(props.valueKey, props.addValue, vehicles[2])) && (formatValueKey(props.valueKey, props.addValue, vehicles[1]) === formatValueKey(props.valueKey, props.addValue, vehicles[2])))
        ) {
            return false;
        } else {
            return true;
        }
    }

    getFeatures = () => {
        const vehicleCount = this.props.comparsionVehicles.length;

        let features = [];
        let output = [];
        let returnHtml = [];

        this.props.comparsionVehicles.map(vehicle => {
            features.push(this.formatValueKey(this.props.valueKey, this.props.addValue, vehicle));
        });

        if(vehicleCount === 1 && features[0] !== "Keine Angaben") {
            features[0].map((feature,index) => {
                output.push(
                    <Row key={"feature-1-" + this.props.valueKey+"-"+index}>
                        <Col span={24}>{feature}</Col>
                    </Row>
                )
            })

            if(window.outerWidth >= 801) {
                returnHtml.push(<Col key={`feature-label-${this.props.valueKey}`} span={12}><b>{this.props.label}</b></Col>);
                returnHtml.push(<Col key={`feature-output-${this.props.valueKey}`} span={12}>{output}</Col>);
            } else {
                returnHtml.push(
                    <Col key={`feature-output-${this.props.valueKey}`} span={24}>
                        <b>{this.props.label}</b>
                        {output}
                    </Col>
                );
            }
        }
        else if(vehicleCount === 2 && (features[0] !== "Keine Angaben" || features[1] !== "Keine Angaben")) {
            if(this.props.onlyDiffs && JSON.stringify(features[0]) === JSON.stringify(features[1])) {return null;}
            else {
                if(features[0] !== "Keine Angaben") {
                    features[0].map((feature,index) => {
                        if(this.props.onlyDiffs && features[1] !== "Keine Angaben" && features[1].findIndex(featureSec => featureSec === feature) !== -1) {return;}
                        output.push(
                            <Row key={"feature-1-" + this.props.valueKey+"-"+index}>
                                <Col span={12}>{feature}</Col>
                                {(features[1] !== "Keine Angaben" && features[1].findIndex(featureSec => featureSec === feature) !== -1) && <Col span={12}>{feature}</Col>}
                            </Row>
                        )
                    })
                }
                if(features[1] !== "Keine Angaben") {
                    features[1].map((feature,index) => {
                        output.push(
                            <Row key={"feature-2-" + this.props.valueKey+"-"+index}>
                                {(features[0] !== "Keine Angaben" && features[0].findIndex(featureFirst => featureFirst === feature) === -1) &&
                                <Col offset={12} span={12}>{feature}</Col>}
                            </Row>
                        )
                    })
                }

                if(window.outerWidth >= 801) {
                    returnHtml.push(<Col key={`feature-label-${this.props.valueKey}`} span={8}><b>{this.props.label}</b></Col>);
                    returnHtml.push(<Col key={`feature-output-${this.props.valueKey}`} span={16}>{output}</Col>);
                } else {
                    returnHtml.push(
                        <Col key={`feature-output-${this.props.valueKey}`} span={24}>
                            <b>{this.props.label}</b>
                            {output}
                        </Col>
                    );
                }
            }
        }
        else if(vehicleCount === 3 && (features[0] !== "Keine Angaben" || features[1] !== "Keine Angaben" || features[2] !== "Keine Angaben")) {
            if(this.props.onlyDiffs && JSON.stringify(features[0]) === JSON.stringify(features[1]) &&
                JSON.stringify(features[0]) === JSON.stringify(features[2]) && JSON.stringify(features[1]) === JSON.stringify(features[2])) {return null;}
            else {
                if(features[0] !== "Keine Angaben") {
                    features[0].map((feature,index) => {
                        if (this.props.onlyDiffs && (features[1].findIndex(featureSec => featureSec === feature) !== -1) && (features[2].findIndex(featureThird => featureThird === feature) !== -1)) {
                            return;
                        }
                        output.push(
                            <Row key={"feature-1-" + this.props.valueKey+"-"+index}>
                                <Col span={8}>{feature}</Col>
                                {(features[1] !== "Keine Angaben" && features[1].findIndex(featureSec => featureSec === feature)) !== -1 &&
                                <Col span={8}>{feature}</Col>}
                                {(features[2] !== "Keine Angaben" && features[2].findIndex(featureThird => featureThird === feature)) !== -1 &&
                                <Col offset={(features[1] !== "Keine Angaben" && features[1].findIndex(featureSec => featureSec === feature)) === -1 ? 8 : 0}
                                     span={8}>{feature}</Col>
                                }
                            </Row>
                        )
                    })
                }
                if(features[1] !== "Keine Angaben") {
                    features[1].map((feature,index) => {
                        output.push(
                            <Row key={"feature-2-" + this.props.valueKey+"-"+index}>
                                {(features[0] !== "Keine Angaben" && features[0].findIndex(featureFirst => featureFirst === feature) === -1) &&
                                <Col offset={8} span={8}>{feature}</Col>}
                                {((features[0] !== "Keine Angaben" && features[0].findIndex(featureFirst => featureFirst === feature) === -1) && (features[2] !== "Keine Angaben" && features[2].findIndex(featureThird => featureThird === feature) !== -1)) &&
                                <Col span={8}>{feature}</Col>}
                            </Row>
                        )
                    })
                }
                if(features[2] !== "Keine Angaben") {
                    features[2].map((feature,index) => {
                        output.push(
                            <Row key={"feature-3-" + this.props.valueKey+"-" + index}>
                                {((features[0] !== "Keine Angaben" && features[0].findIndex(featureFirst => featureFirst === feature) === -1) && (features[1] !== "Keine Angaben" && features[1].findIndex(featureSec => featureSec === feature) === -1)) &&
                                <Col offset={16} span={8}>{feature}</Col>}
                            </Row>
                        )
                    })
                }

                if(window.outerWidth >= 801) {
                    returnHtml.push(<Col key={"feature-label-" + this.props.valueKey} span={6}><b>{this.props.label}</b></Col>);
                    returnHtml.push(<Col key={"feature-col-" + this.props.valueKey} span={18}>{output}</Col>);
                } else {
                    returnHtml.push(
                        <Col key={"feature-col-" + this.props.valueKey} span={24}>
                            <b>{this.props.label}</b>
                            {output}
                        </Col>
                    );
                }
            }
        }

        return returnHtml;
    }

    renderContent = () => {
        if(this.props.onlyDiffs) {
            if(!this.hasDiffs()) {
                return null;
            } else {
                if(this.props.isFeature) {
                    return (<Row gutter={16}>{this.getFeatures()}</Row>);
                } else {
                    return (
                        <Row gutter={16}>
                            {window.outerWidth >= 801 && <Col span={this.props.spanSizeDesk}><b>{this.props.label}</b></Col>}
                            {this.props.comparsionVehicles.map(vehicle => {
                                return (
                                    <Col span={window.outerWidth >= 801 ? this.props.spanSizeDesk : this.props.spanSizeMobil} key={`${vehicle.adId}-${this.props.keyAdd}`}>
                                        {window.outerWidth < 801 && <b>{this.props.label}</b>}
                                        <div>{this.formatValueKey(this.props.valueKey, this.props.addValue, vehicle)}</div>
                                    </Col>

                                )
                            })}
                        </Row>
                    )
                }
            }
        } else {
            if(this.props.isFeature) {
                return (<Row gutter={16}>{this.getFeatures()}</Row>);
            } else {
                return (
                    <Row gutter={16}>
                        {window.outerWidth >= 801 && <Col span={this.props.spanSizeDesk}><b>{this.props.label}</b></Col>}
                        {this.props.comparsionVehicles.map(vehicle => {
                            return (
                                <Col span={window.outerWidth >= 801 ? this.props.spanSizeDesk : this.props.spanSizeMobil} key={`${vehicle.adId}-${this.props.keyAdd}`}>
                                    {window.outerWidth < 801 && <b>{this.props.label}</b>}
                                    <div>{this.formatValueKey(this.props.valueKey, this.props.addValue, vehicle)}</div>
                                </Col>

                            )
                        })}
                    </Row>
                )
            }

        }
    }

    render() {
        return <Row><Col span={24}>{this.renderContent()}</Col></Row>;
    }
}

export default ComparsionSection;