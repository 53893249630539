import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Sticky } from 'react-sticky';
import {Button, Modal, Spin} from 'antd';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as searchActions from "actions/searchActions";

class FormButtons extends Component {

	static propTypes = {
		handleReset: PropTypes.func.isRequired,
		handleSearch: PropTypes.func.isRequired,

		loading: PropTypes.bool.isRequired,
		count: PropTypes.number.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			showNoFilterModal: false,
			initialFilter: {
				brand: [{ key: "alle" }],
				condition: ["NEW", "USED"],
			}
		}
	}

	objectsEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));

	handleSave = () => {
		if(this.objectsEqual(this.props.search.searchFilter, this.state.initialFilter)) {
			this.setState({showNoFilterModal: true});
		} else {
			this.props.handleSave();
		}
	}

	printVehicleName = () => {
		switch(this.props.search.searchMeta.searchClass) {
			case "Car": return "PKW";
			case "Motorbike": return "Motorrad";
			case "Motorhome": return "Caravan";
			case "AgriculturalVehicle": return "Mulchraupen";
		}
	}

	render() {

		return (
			<div>
				{window.innerWidth < 850 ?
					// Mobile Search
					<div>
						<Modal title="Was möchten Sie machen?" visible={this.state.showModal} onOk={null} onCancel={() => this.setState({showModal: false})}
							   footer={[
								   <Button style={{display: "initial"}} key="submit" onClick={() => {
									   this.props.handleEditFilter();
									   this.setState({showModal: false});
								   }}>
									   Suche überschreiben
								   </Button>,
								   <Button style={{display: "initial"}} key="back" type="primary" onClick={() => {
									   this.props.handleSave();
									   this.setState({showModal: false});
								   }}>
									   Neue Suche anlegen
								   </Button>
							   ]}
						>
							<p>Möchten Sie Ihren Suchauftrag überschreiben oder einen neuen anlegen?</p>
						</Modal>
						<Modal title="Was möchten Sie machen?" visible={this.state.showNoFilterModal} onOk={null} onCancel={() => this.setState({showNoFilterModal: false})}
							   footer={[
								   <Button style={{display: "initial"}} key="submit" onClick={() => {
									   this.props.handleSave();
									   this.setState({showNoFilterModal: false});
								   }}>
									   Suche speichern
								   </Button>,
								   <Button style={{display: "initial"}} key="back" type="primary" onClick={() => {
									   this.setState({showNoFilterModal: false});
								   }}>
									   Abbrechen
								   </Button>
							   ]}
						>
							<p>Sie haben noch keine Kriterien für Ihre {this.printVehicleName()} Suche gesetzt. Möchten Sie sie dennoch speichern?</p>
						</Modal>
						<Sticky>
							{
								({ style, isSticky }) => {
									let isStickyStyle = isSticky ? {
										margin: 0,
										borderRadius: 0,
										bottom: 0,
										zIndex: 10,
										width: this.props.filterChanged ? this.props.filterSaved ? "100%" : "55%" : "100%"
 									} : null;
									return (
										<div style={{zIndex: document.getElementById("cookie-banner") && "0"}} className={`${isSticky ? "search-button-wrapper" : "" }`}>
											{isSticky ?
												<div>
													<Button
														type="default"
														size="small"
														onClick={this.props.handleReset}
														className="reset-button"
														style={{
															marginBottom: "0rem",
															color: "black",
															border: "none",
															borderRadius: "0",
															borderBottom: "1px solid black",
															height: "50px"
														}}
													>
														Zurücksetzen
													</Button>
													<div style={{display: "flex", height: "60px"}}>
														<Button
															type="primary"
															size="default"
															onClick={this.props.handleSearch}
															loading={this.props.loading}
															style={isStickyStyle}
														>
															Suchen ({this.props.count})
														</Button>
														{this.props.filterSaved ? null :
															this.props.searchRequestId ?
																<Button style={{
																	border: "none",
																	margin: "0",
																	padding: "1rem .5rem",
																	width: "45%",
																	backgroundColor: "transparent",
																	borderRadius: 0,
																	color: "#187fff"
																}} 	onClick={() => this.setState({showModal: true})}
																	type={"primary"}
																	loading={this.props.saving}
																>
																	Suche speichern
																</Button>
															:
																<Button style={{
																	border: "none",
																	margin: "0",
																	padding: "1rem .5rem",
																	width: "45%",
																	backgroundColor: "transparent",
																	borderRadius: 0,
																	color: "#187fff"
																}} 	onClick={this.handleSave}
																	type={"primary"}
																	loading={this.props.saving}
																>
																	Suche speichern
																</Button>
														}
													</div>
												</div>
												:
												<div>
													<Button
														type="primary"
														size="default"
														onClick={this.props.handleSearch}
														loading={this.props.loading}
														style={isStickyStyle}
													>
														Suchen ({this.props.count})
													</Button>
													{this.props.filterSaved ? null :
														this.props.searchRequestId ?
															<Button type={"primary"}
																	loading={this.props.saving}
																	onClick={() => this.setState({showModal: true})}>Suche
																speichern</Button>
															:
															<Button type={"primary"}
																	loading={this.props.saving} onClick={this.handleSave}>Suche
																speichern</Button>
													}
													<Button
														type="default"
														size="default"
														onClick={this.props.handleReset}
														className="reset-button"
														style={{marginBottom: "0rem"}}
													>
														Zurücksetzen
													</Button>
													}
												</div>
											}
										</div>
									);
								}
							}
						</Sticky>
					</div>
				:
					// Desktop Search Button
					<div>
						<Modal title="Was möchten Sie machen?" visible={this.state.showModal} onOk={null} onCancel={() => this.setState({showModal: false})}
							   footer={[
								   <Button key="submit" onClick={() => {
									   this.props.handleEditFilter();
									   this.setState({showModal: false});
								   }}>
									   Suche überschreiben
								   </Button>,
								   <Button key="back" type="primary" onClick={() => {
									   this.props.handleSave();
									   this.setState({showModal: false});
								   }}>
									   Neue Suche anlegen
								   </Button>
							   ]}
						>
							<p>Möchten Sie Ihren Suchauftrag überschreiben oder einen neuen anlegen?</p>
						</Modal>
						<Modal title="Was möchten Sie machen?" visible={this.state.showNoFilterModal} onOk={null} onCancel={() => this.setState({showNoFilterModal: false})}
							   footer={[
								   <Button key="submit" onClick={() => {
									   this.props.handleSave();
									   this.setState({showNoFilterModal: false});
								   }}>
									   Suche speichern
								   </Button>,
								   <Button key="back" type="primary" onClick={() => {
									   this.setState({showNoFilterModal: false});
								   }}>
									   Abbrechen
								   </Button>
							   ]}
						>
							<p>Sie haben noch keine Kriterien für Ihre {this.printVehicleName()} Suche gesetzt. Möchten Sie sie dennoch speichern?</p>
						</Modal>
						<div style={{margin: "0 auto", display: "table"}}>
							<div style={{display: "flex", flexDirection: "column"}} className={"search-form-btns"}>
								<Button
									type="primary"
									size="default"
									onClick={this.props.handleSearch}
									style={{zIndex: "0", minWidth: "190px"}}
								>
									Suchen ({this.props.count})
								</Button>
								{this.props.filterSaved ? null :
								this.props.searchRequestId ?
									<Button
										style={{marginTop: ".5rem", backgroundColor: "transparent", borderColor: "#187fff", color: "#187fff"}}
										onClick={() => this.setState({showModal: true})} type={"primary"}
										loading={this.props.saving}
									>
										Suche speichern
									</Button>
									:
									<Button
										style={{marginTop: ".5rem", backgroundColor: "transparent", borderColor: "#187fff", color: "#187fff"}}
										onClick={this.handleSave} type={"primary"}
										loading={this.props.saving}
									>
										Suche speichern
									</Button>
								}
							</div>

							<Button
								type="default"
								size="default"
								onClick={this.props.handleReset}
								className="reset-button"
								style={{ marginBottom: "0rem" }}
							>
								Zurücksetzen
							</Button>
							<Spin spinning={this.props.loading} style={{ marginLeft: "1rem" }} />
						</div>
					</div>
				}
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		search: state.search
	};
}

function mapDispatchToProps(dispatch) {
	return {
		searchActions: bindActionCreators(searchActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FormButtons);