import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as searchActions from "actions/searchActions";
import * as siteActions from "actions/siteActions";

import {Card, Divider, Popconfirm, Row, Switch, Col, Tag} from "antd";
import Button from "antd/es/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faTimes, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Text from "antd/es/typography/Text";
import {formatThousand} from "../../helper";

class SearchFilterList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initLoading: false
		}
	}

	translateSearchClass(searchclass) {
		switch(searchclass) {
			case "Car": return "PKW";
			case "Motorhome": return "Wohnmobil";
			case "Motorbike": return "Motorrad";
			case "AgriculturalVehicle": return "Mulchraupe"
			default: return "PKW";
		}
	}

	translateCondition(condition) {
		switch(condition) {
			case "NEW;USED": return "Neu -und Gebraucht";
			case "NEW": return "Neu";
			case "USED": return "Gebraucht";
			default: return "Neu -und Gebraucht";
		}
	}

	getBrandInfoCols(brands, models, modelSeries, dateSectionIndex, itemIndex) {
		let cols = [];

		if(brands) {
			brands.map(brand => {
				let brandInfoString = brand.key !== "alle" ? brand.key : "";
				let modelIndex = models ? models.findIndex(model => model.brand === brand.key) : -1;
				let modelSeriesIndex = modelSeries ? modelSeries.findIndex(modelSerie => modelSerie.brand === brand.key) : -1;
				let modelDescription = brand.modelDescription;

				if(modelSeriesIndex !== -1 && modelSeries[modelSeriesIndex].modelSeries.findIndex(modelSerie => modelSerie !== "EMPTY") !== -1) {
					brandInfoString += " - ";

					modelSeries[modelSeriesIndex].modelSeries.map((modelSerie, index) => {
						if(modelSerie !== "EMPTY") {
							brandInfoString += modelSerie;
							if (index < modelSeries[modelSeriesIndex].modelSeries.length - 1) {
								brandInfoString += ", "
							}
						}
					});
				}
				if(modelIndex !== -1 && models[modelIndex].models.findIndex(model => model !== "EMPTY") !== -1) {
					brandInfoString += " - ";

					models[modelIndex].models.map((model, index) => {
						if(model !== "EMPTY") {
							brandInfoString += model;
							if(index < models[modelIndex].models.length-1) { brandInfoString += ", " }
						}
					});
				}
				if(modelDescription !== null && modelDescription !== "" && modelDescription !== "EMPTY") {
					if(brand.key !== "alle") { brandInfoString += " : " + modelDescription; }
					else { brandInfoString += modelDescription; }
				}

				cols.push(
					<div key={dateSectionIndex + "-" + itemIndex + "-" + brand.key}>
						<Text>{brandInfoString}</Text>
					</div>
				);
			});
		}

		return cols;
	}
	getFilterData(request) {
		let elements = [];
		let printedFilterCount = 0;
		let maxCount = 6;
		let maxFeatureIndex = 3;

		if(request.priceMin || request.priceMax) {
			if(request.priceMin && request.priceMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-price"}>Preis: {formatThousand(request.priceMin)} € - {formatThousand(request.priceMax)} €</Tag>); }
			else if(request.priceMin && !request.priceMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-price"}>Preis: &#62; {formatThousand(request.priceMin)} €</Tag>) }
			else if(!request.priceMin && request.priceMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-price"}>Preis: &#60; {formatThousand(request.priceMax)} €</Tag>) }

			printedFilterCount++;
		}
		if(request.regMin || request.regMax) {
			if(request.regMin && request.regMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-reg"}>Erstzulassung: {request.regMin} - {request.regMax}</Tag>); }
			else if(request.regMin && !request.regMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-reg"}>Erstzulassung: &#62; {request.regMin}</Tag>); }
			else if(!request.regMin && request.regMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-reg"}>Erstzulassung: &#60; {request.regMax}</Tag>); }

			printedFilterCount++;
		}
		if(request.kmMin || request.kmMax) {
			if(request.kmMin && request.kmMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-km"}>Kilometer: {formatThousand(request.kmMin)} km - {formatThousand(request.kmMax)} km</Tag>); }
			else if(request.kmMin && !request.kmMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-km"}>Kilometer: &#62; {formatThousand(request.kmMin)} km</Tag>); }
			else if(!request.kmMin && request.kmMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-km"}>Kilometer: &#60; {formatThousand(request.kmMax)} km</Tag>); }

			printedFilterCount++;
		}
		if(request.powerMin || request.powerMax) {
			if(request.powerMin && request.powerMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-power"}>Leistung: {request.powerMin} kW - {request.powerMax} kW</Tag>); }
			else if(request.powerMin && !request.powerMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-power"}>Leistung: &#62; {request.powerMin} kW</Tag>); }
			else if(!request.powerMin && request.powerMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-power"}>Leistung: &#60; {request.powerMax} kW</Tag>); }

			printedFilterCount++;
		}

		if((request.lengthMin || request.lengthMax)) {
			if(request.lengthMin && request.lengthMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-length"}>Länge: {request.lengthMin} mm - {request.lengthMax} mm</Tag>); }
			else if(request.lengthMin && !request.lengthMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-length"}>Länge: &#62; {request.lengthMin} mm</Tag>); }
			else if(!request.lengthMin && request.lengthMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-length"}>Länge: &#60; {request.lengthMax} mm</Tag>); }

			printedFilterCount++;
		}
		if((request.widthMin || request.widthMax) && printedFilterCount <= maxCount) {
			if(request.widthMin && request.widthMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-width"}>Breite: {request.widthMin} mm - {request.widthMax} mm</Tag>); }
			else if(request.widthMin && !request.widthMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-width"}>Breite: &#62; {request.widthMin} mm</Tag>); }
			else if(!request.widthMin && request.widthMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-width"}>Breite: &#60; {request.widthMax} mm</Tag>); }

			printedFilterCount++;
		}
		if((request.heightMin || request.heightMax) && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.heightMin && request.heightMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-height"}>Höhe: {request.heightMin} mm - {request.heightMax} mm</Tag>); }
				else if(request.heightMin && !request.heightMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-height"}>Höhe: &#62; {request.heightMin} mm</Tag>); }
				else if(!request.heightMin && request.heightMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-height"}>Höhe: &#60; {request.heightMax} mm</Tag>); }
			}

			printedFilterCount++;
		}
		if((request.weightMin || request.weightMax) && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.weightMin && request.weightMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-weight"}>zul. Gesamtgewicht: {request.weightMin} kg - {request.weightMax} kg</Tag>); }
				else if(request.weightMin && !request.weightMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-weight"}>zul. Gesamtgewicht: &#62; {request.weightMin} kg</Tag>); }
				else if(!request.weightMin && request.weightMax) { elements.push(<Tag key={request.suchanfrageId + "-tag-weight"}>zul. Gesamtgewicht: &#60; {request.weightMax} kg</Tag>); }
			}

			printedFilterCount++;
		}

		if(request.usageType && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.usageType.includes(";")) {
					let usageType = request.usageType.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-usage"}>
							{ usageType.map((reqUsageType, index) => {
								if(reqUsageType === "") { return; }
								let result = reqUsageType;

								if((index+1) < (usageType.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "-tag-usage"}>{ request.usageType }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.category && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.category.includes(";")) {
					let category = request.category.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-category"}>
							{ category.map((reqCategory, index) => {
								if(reqCategory === "") { return; }
								let result = reqCategory;

								if((index+1) < (category.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "-tag-category"}>{ request.category }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.motorart && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.motorart.includes(";")) {
					let motorart = request.motorart.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-motorart"}>
							{ motorart.map((reqMotorart, index) => {
								if(reqMotorart === "") { return; }
								let result = reqMotorart;

								if((index) < (motorart.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "-tag-motorart"}>{ request.motorart }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.gearBox && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.gearBox.includes(";")) {
					let gearBox = request.gearBox.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-gearbox"}>
							{ gearBox.map((reqGearBox, index) => {
								if(reqGearBox === "") { return; }
								let result = reqGearBox;

								if((index+1) < (gearBox.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "-tag-gearbox"}>{ request.gearBox }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.drivingModes && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.drivingModes.includes(";")) {
					let drivingModes = request.drivingModes.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-drivemode"}>
							{ drivingModes.map((reqDrivingMode, index) => {
								if(reqDrivingMode === "") { return; }
								let result = reqDrivingMode;

								if((index+1) < (drivingModes.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "-tag-drivemode"}>{ request.drivingModes }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.exteriorColor && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.exteriorColor.includes(";")) {
					let exteriorColor = request.exteriorColor.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-exterior-color"}>
							{ exteriorColor.map((reqExteriorColor, index) => {
								if(reqExteriorColor === "") { return; }
								let result = reqExteriorColor;

								if((index+1) < (exteriorColor.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-exterior-color"}>{ request.exteriorColor }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.features.headlights && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-headlights"}>{ request.features.headlights }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.daytime_running_lights && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-daytime-running-lights"}>{ request.features.daytime_running_lights }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.cruise_control && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-cruise-control"}>{ request.features.cruise_control }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.park_assist && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-park-assist"}>{ request.features.park_assist }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.bending_lights && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-bending-lights"}>{ request.features.bending_lights }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.security && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.features.security.includes(";")) {
					let securities = request.features.security.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-security"}>
							{ securities.map((security, index) => {
								if(security === "" || index >= maxFeatureIndex) { return; }
								let result = security;

								if((index+1) < (securities.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-security"}>{ request.features.security }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.features.trailer_coupling && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-trailer-coupling"}>{ request.features.trailer_coupling }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.climatisation && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-climatisation"}>{ request.features.climatisation }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.comfort && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.features.comfort.includes(";")) {
					let comfortFeatures = request.features.comfort.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-comfort"}>
							{ comfortFeatures.map((comfortFeature, index) => {
								if(comfortFeature === "" || index >= maxFeatureIndex) { return; }
								let result = comfortFeature;

								if((index+1) < (comfortFeatures.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-comfort"}>{ request.features.comfort }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.features.radio && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-radio"}>{ request.features.radio }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.infotainment && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.features.infotainment.includes(";")) {
					let infotainmentFeatures = request.features.infotainment.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-infotainment"}>
							{ infotainmentFeatures.map((infotainmentFeature, index) => {
								if(infotainmentFeature === "" || index >= maxFeatureIndex) { return; }
								let result = infotainmentFeature;

								if((index+1) < (infotainmentFeatures.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-infotainment"}>{ request.features.infotainment }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.features.interior_exterior && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.features.interior_exterior.includes(";")) {
					let interiorExteriorFeatures = request.features.interior_exterior.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-interior-exterior"}>
							{ interiorExteriorFeatures.map((interiorExteriorFeature, index) => {
								if(interiorExteriorFeature === "" || index >= maxFeatureIndex) { return; }
								let result = interiorExteriorFeature;

								if((index+1) < (interiorExteriorFeatures.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-interior-exterior"}>{ request.features.interior_exterior }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.features.emergency && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-feature-emergency"}>{ request.features.emergency }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.features.other && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.features.other.includes(";")) {
					let otherFeatures = request.features.other.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-other"}>
							{ otherFeatures.map((otherFeature, index) => {
								if(otherFeature === "" || index >= maxFeatureIndex) { return; }
								let result = otherFeature;

								if((index+1) < (otherFeatures.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-other"}>{ request.features.other }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.features.other_fuel && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.features.other_fuel.includes(";")) {
					let otherFuelFeatures = request.features.other_fuel.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-other-fuel"}>
							{ otherFuelFeatures.map((otherFuelFeature, index) => {
								if(otherFuelFeature === "") { return; }
								let result = otherFuelFeature;

								if((index+1) < (otherFuelFeatures.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-other-fuel"}>{ request.features.other_fuel }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.doorCount && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.doorCount.includes(";")) {
					let reqDoorCount = request.doorCount.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-door-count"}>
							{ reqDoorCount.map((doorCount, index) => {
								if(doorCount === "") { return; }
								let result = doorCount;

								if((index+1) < (reqDoorCount.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-door-count"}>{ request.doorCount }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.emissionStickers && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.emissionStickers.includes(";")) {
					let emissionStickers = request.emissionStickers.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-emission-stickers"}>
							{ emissionStickers.map((emissionSticker, index) => {
								if(emissionSticker === "") { return; }
								let result = emissionSticker;

								if((index+1) < (emissionStickers.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-emission-stickers"}>{ request.emissionStickers }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.sites && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				if(request.sites.includes(";")) {
					let sites = request.sites.split(";");

					elements.push(
						<Tag key={request.suchanfrageId + "-tag-features-site"}>
							{ sites.map((site, index) => {
								if(site === "") { return; }
								let result = site;

								if((index+1) < (sites.length - 1)) { result += ", "; }

								return result;
							}) }
						</Tag>
					);
				} else { elements.push(<Tag key={request.suchanfrageId + "tag-features-site"}>{ request.sites }</Tag>); }
			}

			printedFilterCount++;
		}
		if(request.eingetroffenVor && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-unfallfrei"}>Eingetroffen Vor: { request.eingetroffenVor }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.accidentFree && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-unfallfrei"}>{ request.accidentFree === true ? "Unfallfrei" : "Unfallfrei: Beliebig" }</Tag>);
			}

			printedFilterCount++;
		}
		if(request.unrepaired && printedFilterCount <= maxCount) {
			if((printedFilterCount+1) === maxCount) { elements.push(<Tag key={request.suchanfrageId + "-tag-more"}>Und weitere...</Tag>); }
			else {
				elements.push(<Tag key={request.suchanfrageId + "-tag-unrepaired"}>{ request.unrepaired === true ? "Unbeschädigt" : "Unbeschädigt: Beliebig" }</Tag>);
			}

			printedFilterCount++;
		}

		return elements;
	}

	render() {
		return (
			<div id={"searchfilter-list"}>
				<button style={{width: "100%", padding: ".5em", marginBottom: "1em"}} onClick={this.props.onCreateSearchFilter}>
					+ Neue Suchanfrage erstellen
				</button>
				<Divider orientation="left">MEINE SUCHEN ({this.props.searchRequests.total})</Divider>
				{this.props.searchRequests.dates && this.props.searchRequests.dates.map((request, datesIndex) => {
					return (
						<div key={request.date}>
							<Text style={{fontWeight: "100", fontSize: "1.1rem"}}>{request.date}</Text>
							{request.requests.map((request, index) => {
								return (
									<Card key={index} style={{marginBottom: "1rem", marginTop: ".5em"}} type="inner" title={this.translateSearchClass(request.searchClass)} extra={
										<div style={{display: "flex"}}>
											<h4 style={{marginBottom: "0"}}>{`${this.translateCondition(request.condition)}`}</h4>
										</div>
									}>
										<Row>
											<Col sm={24} md={17} lg={18} xl={19}>
												{this.getBrandInfoCols(request.brand, request.model, request.modelSeries, datesIndex, index)}
												<div className={"filter-data"}>
													{this.getFilterData(request)}
												</div>
											</Col>
											<Col sm={24} md={7} lg={6} xl={5}>
												<div className={"searchfilter-item-card-body"} style={{marginBottom: ".5em", justifyContent: request.newVehicles ? "space-between" : "flex-end"}}>
													<div style={{display: "flex", flexDirection: "column"}}>
														<Button size={"default"} style={{marginBottom: ".5em"}} type={"primary"} onClick={() => this.props.onEditSearchFilter(request.suchanfrageId, datesIndex, 0, false)}>Suche bearbeiten</Button>
														<Button size={"default"} onClick={() => this.props.onEditSearchFilter(request.suchanfrageId, datesIndex, 0, true)}>Treffer Anzeigen</Button>
													</div>
													{request.newVehicles &&
														<Button
															size={"default"}
															onClick={() => this.props.onEditSearchFilter(request.suchanfrageId, datesIndex, request.zuletztGesehenVor, true)}
															style={{backgroundColor: "#1990fc", color: "white"}}>{request.newVehicles.length} Neuzugänger
														</Button>
													}
												</div>
											</Col>
										</Row>
										<div className={"searchfilter-notify-n-delete"} style={{justifyContent: "space-between", alignItems: "baseline", padding: ".5rem", borderTop: "1px solid #767676"}}>
											<div className={"searchfilter-notify"} style={{alignItems: "baseline"}}>
												<span className={"searchfilter-notify-text"} style={{color: (request.zugestimmt && this.props.agreementAccepted) && "green", marginRight: ".5rem"}}>E-Mail Benachrichtigung bei neuen Treffern</span>
												<Switch
													style={{alignSelf: "center"}}
													checkedChildren={<FontAwesomeIcon icon={faCheck}/>}
													unCheckedChildren={<FontAwesomeIcon icon={faTimes}/>}
													checked={(this.props.agreementAccepted === true && request.zugestimmt)}
													onChange={() => this.props.onSetNotifyFilter(index, datesIndex, request.zugestimmt ? false : true)}
												/>
											</div>
											<Popconfirm
												title="Möchten Sie den Suchauftrag wirklich löschen?"
												onConfirm={() => this.props.onDeleteSearchFilter(request.suchanfrageId, datesIndex)}
												okText="Ja"
												cancelText="Nein"
											>
												<button className={"searchfilter-delete"} style={{cursor: "pointer", border: "none", backgroundColor: "#ff4d4f", color: "white", padding: ".5em 1em"}}><FontAwesomeIcon icon={faTrashAlt} /></button>
											</Popconfirm>
										</div>
									</Card>
								)
							})}
						</div>
					)
				})}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		search: state.search,
		site: state.site
	};
}

function mapDispatchToProps(dispatch) {
    return {
		actions: bindActionCreators(searchActions, dispatch),
		siteActions: bindActionCreators(siteActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilterList);