import React from 'react';
import SelectSearch from "./SelectSearch";

class FeatureSelectSearch extends SelectSearch {

	constructor(props) {
		super(props);
	}

	onChange(value = "") {
		this.props.actions.setSearchFilterFeatureKey(this.props.name, value);
		this.props.handleChange();
	}

}

export default FeatureSelectSearch;