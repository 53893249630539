import * as types from 'actions/actionTypes';
import axios from "axios";
import settings from "../settings";

export function setPageLoadingStatus(value) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: types.SET_LOADING_STATUS,
				value
			});
			setTimeout(() => {
				resolve(true);
			}, 350);
		})
	}
}

/**
 * Lädt ähnliche Fahrzeuge anhand der adId.
 * @param {object} vehicles
 * @param {number} index
 */
export function loadComparsionVehicles(vehicle, index = -1) {
	if(vehicle) {
		return dispatch => {
			return axios.get(`${settings.servicePath}/ad/${vehicle.adId}`)
				.catch(err => {
					dispatch(loadComparsionVehiclesFail(err.message));
					throw new Error(err.message);
				})
				.then(function (result) {
					result.data.searchClass = vehicle.searchClass;
					dispatch(loadComparsionVehiclesSuccess(result.data, index));
				});
		};
	} else {
		return dispatch => {
			dispatch(loadComparsionVehiclesSuccess(null, index));
		}
	}
}

export function loadComparsionVehiclesFail(errMsg) {
	return {
		type: types.LOAD_COMPARSION_VEHICLES_FAIL,
		errMsg
	};
}

export function loadComparsionVehiclesSuccess(data, index) {
	return {
		type: types.LOAD_COMPARSION_VEHICLES_SUCCESS,
		data,
		index
	};
}

export function resetComparsionVehicles(vehicle) {

	return {
		type: types.RESET_COMPARSION_VEHICLES,
		vehicle
	};
}