import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

class ScrollToTopRoute extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate = (prevProps) => {
		let path = this.props.path;

		if(path.includes(":vehicleId")) {
			let vehicleId = this.props.location.pathname.split("/")[2];
			path = path.replace(":vehicleId", vehicleId);
		}

		if(path === this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		const { component: Component, ...rest } = this.props;
		return <Route {...rest} render={props => (<Component {...props}
															 searchData={this.props.searchData}
															 parkedVehicleCount={this.props.parkedVehicleCount}
															 searchRequestsCount={this.props.searchRequestsCount}
															 updateParkedVehicles={this.props.updateParkedVehicles}
															 onSetComparsionVehicle={this.props.onSetComparsionVehicle}
															 comparsionVehicles={this.props.comparsionVehicles}
															 onOpenDrawer={this.props.onOpenDrawer}
															 vehicleParked={this.props.vehicleParked}
															 vehicleParkedOut={this.props.vehicleParkedOut}
													/>)} />;
	}

}

export default withRouter(ScrollToTopRoute);