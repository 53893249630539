import React from 'react';
import { Button } from 'antd';

const SortItem = ({ type, sortBy, sortField, children, sortDescending }) => {
	
	return (
		<Button
			type="default"
			className={`sort ${sortField === type ? "current-sort" : ""}`}
			onClick={() => sortBy(type)}
		>
			{children} {sortField === type ? (sortDescending ? "▼" : "▲") : null}
		</Button>
	);

};

export default SortItem;