import React, { Component } from "react";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { loadReCaptcha } from 'react-recaptcha-google';

import { message } from 'antd';

import AuerLoadingScreen from "containers/AuerLoadingScreen";
import ScrollToTopRoute from "containers/ScrollToTopRoute";
import Search from "containers/Search";
import Result from "containers/Result";
import CookieBanner from "../components/CookieBanner";
import LoginForm from "./LoginForm";
import CustomerPanel from "./CustomerPanel";
import axios from "axios";
import settings from "../settings";

import * as siteActions from "actions/siteActions";
import Cookies from "js-cookie";

class App extends Component {
	state = {
		countParkedVehicles: 0,
		countSavedSearchqueries: 0,
		comparsionVehicles: [],
		visible: false,
		replaceComparsionVehicle: false,
		savedCompareVehicle: null
	};

	async componentDidMount() {
		loadReCaptcha();

		if(sessionStorage.getItem("comparedVehicles")) {
			JSON.parse(sessionStorage.getItem("comparedVehicles")).map(vehicle => {
				this.props.store.dispatch(siteActions.loadComparsionVehicles(vehicle.vehicle));
			});
		}
		if((sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail"))) {
			const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");
			const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");

			axios.post(`${settings.servicePath}/agreement/login/authorised`, {linkCode: linkCode, mail: mail})
				.then((result) => {
					if(result.data === "UNAUTHORIZED") {
						sessionStorage.removeItem("linkCode");
						sessionStorage.removeItem("mail");
						localStorage.removeItem("stay");
						localStorage.removeItem("anrede");
						localStorage.removeItem("firstname");
						localStorage.removeItem("lastname");
						localStorage.removeItem("company");
						Cookies.remove("addCode");
						Cookies.remove("linkCode");
						Cookies.remove("mail");

						location.reload();
					} else if(result.data === "ACCEPTED") {
						axios.post(`${settings.servicePath}/agreement`, {linkCode: linkCode})
							.then((result) => {
								sessionStorage.setItem("anrede", result.data.salutation);
								sessionStorage.setItem("firstname", result.data.firstName);
								sessionStorage.setItem("lastname", result.data.name);
								sessionStorage.setItem("company", result.data.company);
								sessionStorage.setItem("mail", result.data.mail);
							});
					}
				});
		}

		if((sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail"))) {
			const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

			axios.post(`${settings.servicePath}/agreement/login/${linkCode}/update`);
		}

		window.addEventListener("resize", e => {
			if(this.props.store.getState().site.comparsionVehicles[2]) {
				this.setState({savedCompareVehicle: this.props.store.getState().site.comparsionVehicles[2]});
			}

			if(window.innerWidth >= 1015) {
				let addVehicle = true;

				if(this.state.savedCompareVehicle) {
					this.props.store.getState().site.comparsionVehicles.map(comparsionVehicle => {
						if(comparsionVehicle && this.state.savedCompareVehicle && (comparsionVehicle.adId === this.state.savedCompareVehicle.adId)) {
							addVehicle = false;
						}
					})
					if(addVehicle) {
						this.props.store.dispatch(siteActions.loadComparsionVehicles(this.state.savedCompareVehicle));
						this.setState({savedCompareVehicle: null});
					}
				}
			} else if(window.innerWidth < 1015 && this.props.store.getState().site.comparsionVehicles.length === 3) {
				this.props.store.dispatch(siteActions.loadComparsionVehicles(null, 2));
			}
		});

		this.updateParkedVehicles();
		this.updateSearchRequests();
	}

	parkedVehicle = () => {
		//TODO Fahrzeug geparkt (Zähler erhöhen) NEUES FEATURE
		message.success('Das Fahrzeug wurde erfolgreich geparkt!', 2);
	};
	parkedVehicleOut = () => {
		//TODO Fahrzeug ausgeparkt (Zähler erhöhen) NEUES FEATURE
		message.success('Das Fahrzeug wurde erfolgreich ausgeparkt!', 2);
	};
	comparedVehicle = () => {
		//TODO Fahrzeug in den Vergleich (Zähler erhöhen) NEUES FEATURE
		message.success('Das Fahrzeug wurde den Vergleichfahrzeugen hinzugefügt!', 2);
	};
	removedCompareVehicle = () => {
		//TODO Fahrzeug aus den Vergleich (Zähler erhöhen) NEUES FEATURE
		message.success('Das Fahrzeug wurde von den Vergleichfahrzeugen entfernt!', 2);
	}
	filterSaved = () => {
		message.success('Ihr Suchauftrag wurde erfolgreich gespeichert!', 2);
	};
	filterExists = () => {
		message.info('Sie haben bereits einen identischen Suchauftrag.', 2);
	};
	updated = () => {
		message.info('Ihre persönlichen Daten wurden erfolgreich gespeichert!.', 2);
	};

	updateParkedVehicles = async () => {
		if((sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail"))) {
			const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");
			const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

			await axios.post(`${settings.servicePath}/customerpanel/parked/vehicles/count`, {mail: mail, linkCode: linkCode})
				.then((result) => {
					this.setState({
						countParkedVehicles: result.data
					});
				});
		}
	}

	updateSearchRequests = async () => {
		if((sessionStorage.getItem("linkCode") && sessionStorage.getItem("mail")) || (Cookies.get("linkCode") && Cookies.get("mail"))) {
			const mail = sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail");
			const linkCode = sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode");

			await axios.post(`${settings.servicePath}/searchfilters/count`, {mail: mail, linkCode: linkCode})
				.then((result) => {
					this.setState({
						countSavedSearchqueries: result.data
					});
				});
		}
	}

	onLoggedIn = () => {
		//TODO eingeloggt (Zähler erhöhen) NEUES FEATURE

		localStorage.setItem("loggedIn", true);
		window.location.replace("/");
	}

	render() {
		return (
			<div>
				<Provider store={this.props.store}>
					<div>
						<AuerLoadingScreen />
						<Router>
							<Switch>
								<Route exact
								   path="/"
								   render={(props) => (
								   	<Search {...props}
											parkedVehicleCount={this.state.countParkedVehicles}
											updateParkedVehicles={(addBool) => this.setState({countParkedVehicles: addBool ? this.state.countParkedVehicles +1 : this.state.countParkedVehicles -1})}
											searchRequestsCount={this.state.countSavedSearchqueries}
											updateSearchRequests={this.updateSearchRequests}
											vehicleParked={this.parkedVehicle}
											vehicleParkedOut={this.parkedVehicleOut}
											vehicleCompared={this.comparedVehicle}
											vehicleRemovedCompare={this.removedCompareVehicle}
											savedFilter={this.filterSaved}
											existingFilter={this.filterExists}
									/>
								   )}
								/>
								<Route
									path="/customerpanel"
									render={(props) => (
										<CustomerPanel {...props}
											   parkedVehicleCount={this.state.countParkedVehicles}
											   updateParkedVehicles={(addBool) => this.setState({countParkedVehicles: addBool ? this.state.countParkedVehicles +1 : this.state.countParkedVehicles -1})}
											   searchRequestsCount={this.state.countSavedSearchqueries}
											   updateSearchRequests={this.updateSearchRequests}
											   loggingIn={this.onLoggedIn}
											   vehicleParked={this.parkedVehicle}
											   vehicleParkedOut={this.parkedVehicleOut}
											   vehicleCompared={this.comparedVehicle}
											   vehicleRemovedCompare={this.removedCompareVehicle}
											   savedFilter={this.filterSaved}
											   existingFilter={this.filterExists}
											   updated={this.updated}
										/>
									)}
								/>
								<ScrollToTopRoute
									path="/fahrzeug/:vehicleId"
									component={Result}
									searchData={this.props.store.getState().search.searchData}
									searchRequestsCount={this.state.countSavedSearchqueries}
									parkedVehicleCount={this.state.countParkedVehicles}
									updateParkedVehicles={(addBool) => this.setState({countParkedVehicles: addBool ? this.state.countParkedVehicles +1 : this.state.countParkedVehicles -1})}
									vehicleParked={this.parkedVehicle}
									vehicleParkedOut={this.parkedVehicleOut}
								/>
								<Route exact path="/login" component={LoginForm} />
							</Switch>
						</Router>
					</div>
				</Provider>
				<CookieBanner printBanner={true} />
			</div>
		);
	}
}

export default hot(module)(App);