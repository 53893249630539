import React from 'react';
import { Tooltip } from 'antd';

const Color = ({ value, name, onClick, selectedColors }) => {
	return (
		<Tooltip placemend="top" title={name}>
			<div
				className={`
					color
					${selectedColors && selectedColors.includes(value) ? "color-border" : null}
					color-${value}
				`}
				onClick={(e) => onClick(value)}
			></div>
		</Tooltip>
	);
};

export default Color;