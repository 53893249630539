import React, { Component } from 'react';
import settings from 'settings';
import PropTypes from 'prop-types';

import ResultItem from 'components/ResultItem';
import axios from "axios";

class SearchResults extends Component {

	static propTypes = {
		searchResults: PropTypes.array.isRequired,
		loading: PropTypes.bool.isRequired,
		hasSearched: PropTypes.bool.isRequired,
		siteActions: PropTypes.object.isRequired
	}

	canDisplayVehicle = (vehicle) => {
		if(vehicle.searchClass === "Car") {
			if(vehicle.condition === "Gebraucht" && parseInt(vehicle.mileage.replace(".", "")) > 2500) {
				return true;
			}
			else if(((vehicle.condition === "Neu" || (vehicle.condition === "Gebraucht" && parseInt(vehicle.mileage.replace(".", "")) <= 2500)) || vehicle.usageType == "Vorführfahrzeug") && (settings.definitionPetrolNames.includes(motorType) || settings.definitionDieselNames.includes(motorType))) {
				if(vehicle.emissionFuelConsumption.co2Emission && vehicle.emissionFuelConsumption.co2Emission !== "" && vehicle.emissionFuelConsumption.consumptionCombined && vehicle.emissionFuelConsumption.consumptionCombined !== "") {
					return true;
				}
			}
			else if(((vehicle.condition === "Neu" || (vehicle.condition === "Gebraucht" && parseInt(vehicle.mileage.replace(".", "")) <= 2500)) || vehicle.usageType == "Vorführfahrzeug") && (settings.definitionElectricNames.includes(motorType))) {
				if(vehicle.emissionFuelConsumption.powerConsumptionCombined && vehicle.emissionFuelConsumption.powerConsumptionCombined !== "") {
					return true;
				}
			}
			else if(((vehicle.condition === "Neu" || (vehicle.condition === "Gebraucht" && parseInt(vehicle.mileage.replace(".", "")) <= 2500)) || vehicle.usageType == "Vorführfahrzeug") && (settings.definitionHybridNames.includes(motorType))) {
				if(vehicle.emissionFuelConsumption.co2Emission && vehicle.emissionFuelConsumption.co2Emission !== "" &&
					vehicle.emissionFuelConsumption.consumptionCombined && vehicle.emissionFuelConsumption.consumptionCombined !== "" &&
					vehicle.emissionFuelConsumption.powerConsumptionCombined && vehicle.emissionFuelConsumption.powerConsumptionCombined !== "")
				{
					return true;
				}
			}
			else {
				return false;
			}
		} else {
			return true;
		}
	};

	render() {
		let hasVehicles = this.props.searchResults && this.props.searchResults.length > 0;
		
		if (hasVehicles) {
			return (
				<div id="results" className="row" style={{ marginTop: "1rem" }}>
					{this.props.searchResults.map(vehicle => (
						<ResultItem
							key={vehicle.adId}
							vehicle={vehicle}
							siteActions={this.props.siteActions}
							siteData={this.props.siteData}
							comparsionVehicles={this.props.comparsionVehicles}
							actions={this.props.actions}
							searchActions={this.props.searchActions}
							updateParkedVehicles={this.props.updateParkedVehicles}
							onSetComparsionVehicle={this.props.onSetComparsionVehicle}
							vehicleParked={this.props.vehicleParked}
							vehicleParkedOut={this.props.vehicleParkedOut}
							vehicleCompared={this.props.vehicleCompared}
							vehicleRemovedCompare={this.props.vehicleRemovedCompare}
							isCompared={this.props.comparsionVehicles.find(comparsionVehicle => comparsionVehicle === vehicle)}
						/>
					))}
				</div>
			);
		}

		if (!hasVehicles && !this.props.hasSearched) {
			return (
				<div id="results" className="row" style={{ marginTop: "1rem" }}>
					<div className="no-results">
						<h2>Um die Fahrzeugsuche zu starten, drücken Sie "Suchen".</h2>
					</div>
				</div>
			);
		}

		return (
			<div id="results" className="row" style={{ marginTop: "1rem" }}>
				<div className="no-results">
					<h2>Es konnten keine Fahrzeuge gefunden werden.</h2>
					<h3>Bitte überprüfen Sie Ihre Suchkriterien.</h3>
				</div>
			</div>
		);
	}

}

export default SearchResults;