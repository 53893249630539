import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/*
{vehicle.features && vehicle.features.length > 0 ?
	<div className="wrapper alt style1 features">

		<div className="container">
			<div className="row no-margin">

				<div className="heading" style={{ marginBottom: "0rem" }}>
					<div className="text">
						<div className="skewed-bg" style={{ marginBottom: "-47px" }}></div>
						<span>Ausstattung</span>
					</div>
				</div>
				<div style={{ marginBottom: "1rem", fontWeight: "300" }}>Bereits im Kaufpreis enthalten.</div>
				<ul id="feature-list" style={{ paddingBottom: "2rem" }}>{vehicle.features && vehicle.features.length > 0 && vehicle.features.map(feature => {
					return (
						<li key={feature}>{feature}</li>
					)
				})}</ul>

			</div>
		</div>

	</div>
: null}
*/

const BackgroundSection = ({ containerClassName, title, children }) => (
	<div className={classnames(
		"wrapper alt style1",
		containerClassName
	)}>
		<div className="container">
			<div className="row no-margin">
				<div className="heading" style={{ marginBottom: "0rem" }}>
					<div className="text">
						<div className="skewed-bg" style={{ marginBottom: "-47px" }}></div>
						<span>{title}</span>
					</div>
				</div>
				{children}
			</div>
		</div>

	</div>
);

BackgroundSection.propTypes = {
	containerClassName: PropTypes.string,
	title: PropTypes.string.isRequired
}

export default BackgroundSection;