import React, { Component } from 'react';

class AuerGoogleMap extends Component {

	constructor(props) {
		super(props);
		this.state = {
			clicked: false
		};
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	onFocus() {
		this.setState({ clicked: true });
	}

	onBlur() {
		this.setState({ clicked: false });
	}

	render() {
		let coords = {};
		let template = "";
		let searchString = "";

		switch(this.props.filialeId + "") {
			case "Stockach":
				coords = { lat: 47.8403348, lng: 9.0255022 };
				template = "Auer Gruppe GmbH<br />Bodenseeallee 2<br />78333 Stockach<br />07771 93050"
				searchString = "Auer+Gruppe+GmbH+Stockach";
				break;
			case "Singen":
				coords = { lat: 47.7581253, lng: 8.863524 };
				template = "Auer Gruppe GmbH<br />Freibühlstraße 1<br />78224 Singen<br />07731 86850";
				searchString = "Auer+Gruppe+GmbH+Singen";
				break;
			case "Ravensburg":
				coords = { lat: 47.7686521, lng: 9.54927 };
				template = "Auer Gruppe GmbH<br />Ganterhofstraße 1<br />88213 Ravensburg<br />0751 50930";
				searchString = "Auer+Gruppe+GmbH+Ravensburg";
				break;
			case "Konstanz":
				coords = { lat: 47.67814, lng: 9.1464613 };
				template = "Auer Gruppe GmbH<br />Macairestraße 1<br />78467 Konstanz<br />07531 59300";
				searchString = "Auer+Gruppe+GmbH+Konstanz";
				break;
			case "Zizenhausen":
				coords = { lat: 47.87525, lng: 8.99899 };
				template = "Auer Biker Village<br/>Meßkircher Str. 125A<br />78333 Stockach-Zizenhausen<br />07771 914340"
				searchString = "Auer+Gruppe+GmbH+Zizenhausen";
				break;
			case "Caramobil":
				coords = { lat: 47.84151, lng: 8.994133 };
				template = "Caramobil<br/>Johann-Glatt-Straße 4-16<br />78333 Stockach<br />07771 9165-10"
				searchString = "Auer+GmbH+Caramobil+Stockach";
				break;
			case "Caravan Bodensee":
				coords = { lat: 47.84151, lng: 8.994133 };
				template = "Caravan Bodensee<br/>Johann-Glatt-Straße 4<br />78333 Stockach<br />07771 9165 0"
				searchString = "Caravan+Bodensee+GmbH+Stockach";
				break;
			case "Bad Saulgau":
				coords = { lat: 48.024703, lng: 9.495334 };
				template = "Bad Saulgau<br/>Martin-Staud-Straße 2<br />88348 Bad Saulgau<br />07581 2000 0"
				searchString = "Auer+Gruppe+GmbH+Bad+Saulgau";
				break;
			default:
				coords = {}
		}

		return (
			<div
				ref="overlay"
				id="overlay"
				onFocus={this.onFocus}
				onClick={this.onFocus}
				onBlur={this.onBlur}
				onMouseLeave={this.onBlur}
			>
				<div
					style={{
						pointerEvents: this.state.clicked ? "auto" : "none"
					}}
				>
					<iframe
						id="map"
						style={{ width: "100%", margin: 0, padding: 0, border: 0 }}
						frameBorder="0"
						allowFullScreen={true}
						src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC9MizoeXBScK8Jj4d9Bh96-COgNqQkjyo&q=${searchString}&zoom=13`}>
					</iframe>
				</div>
			</div>
		);
	}

}

/*
	proptypes
	- filiale
*/

export default AuerGoogleMap;