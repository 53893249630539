import React, { Component } from 'react';
import {Button, Checkbox, Row, Col, Tabs, Form, Input, Select, Popconfirm, Alert, Result, Divider} from 'antd';
import { Typography } from 'antd';
import Cookies from 'js-cookie';

const { Text } = Typography;

import * as resultActions from 'actions/resultActions';
import * as siteActions from 'actions/siteActions';

import qs from "querystring";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import settings from "../settings";
import FormItem from "antd/es/form/FormItem";

class AgreementLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			prevData: {
				anrede: "",
				firstname: "",
				lastname: "",
				firm: ""
			},
			anrede: "",
			firstname: "",
			lastname: "",
			firma: "",
			mail: "",
			message: "",
			nameRequired: false,
			anredeRequired: false,
			firmaRequired: false,
			agreementLinkCode: qs.decode(location.search.substring(1)),
			checked: false,
			error: false,
			pending: true,
			alreadyChecked: false,
			serverError: false,
			linkExpired: false,
			stateLoaded: false
		};
	}

	async componentDidMount() {
		await axios.post(`${settings.servicePath}/agreement`, {linkCode: sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode")})
		.then((result) => {
			if(result.data) {
				this.setState({
					linkCodeToDecline: result.data.linkCodeToDecline,
					alreadyChecked: result.data.zugestimmt,
					anrede: result.data.salutation,
					mail: result.data.mail,
					firstname: result.data.firstName,
					lastname: result.data.name,
					firma: result.data.company,
					lastAction: "",
					wantDecline: result.data.wantDecline,
					stateLoaded: true,
					prevData: { anrede: result.data.salutation, firstname: result.data.firstName, lastname: result.data.name, firma: result.data.company }
				})
			} else {
				this.setState({linkExpired: true, stateLoaded: true});
			}
		});
	}

	onChange = (value, name) => {
		if(name === "anrede" && value !== undefined) {
			if(value === "Firma") {
				this.setState({firmaRequired: true, nameRequired: false, anredeRequired: true});
			}
			else {
				this.setState({firmaRequired: false, nameRequired: true, anredeRequired: true});
			}
		} else if((name === "firstname" || name === "lastname") && value !== "") {
			this.setState({anredeRequired: true, nameRequired: true});
		} else if((name === "firstname") && (value === "" && this.state.lastname === "" && this.state.anrede === undefined)) {
			this.setState({anredeRequired: false, nameRequired: false});
		} else if((name === "lastname") && (value === "" && this.state.firstname === "" && this.state.anrede === undefined)) {
			this.setState({anredeRequired: false, nameRequired: false});
		} else if(name === "anrede" && value === undefined && this.state.firstname === "" && this.state.lastname === "") {
			this.setState({anredeRequired: false, nameRequired: false});
		} else if(name === "anrede" && (this.state.firstname !== "" || this.state.lastname !== "")) {
			this.setState({anredeRequired: true, nameRequired: true});
		} else if(name === "firma" && (this.state.firma !== "" || value !== "")) {
			this.setState({anredeRequired: true, nameRequired: false, firmaRequired: true});
		}

		this.setState({[name]: value, error: false, message: ""});
	}

	handleAccept = () => {
		if(this.state.checked) {
			axios.post(`${settings.servicePath}/agreement/set`, {linkCode: sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode"), accepted: true})
			.then((result) => {
				if(result.data === "FOUND") {
					this.setState({alreadyChecked: true});
				} else if(result.data === "OK") {
					this.setState({
						pending: false,
						lastAction: "accept"
					});
					this.props.setAgreementAccepted(true);
				} else if(result.data === "INTERNAL_SERVER_ERROR") {
					this.setState({serverError: true});
				}
			})
		} else {
			this.setState({error: true});
		}
	}

	handleDecline = () => {
		axios.post(`${settings.servicePath}/agreement/set`, {linkCode: sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode"), accepted: false})
		.then((result) => {
			if(result.data === "OK") {
				this.setState({
					pending: false,
				});
				this.props.setAgreementAccepted(false);
			} else if(result.data === "INTERNAL_SERVER_ERROR") {
				this.setState({serverError: true});
			}
		})
	}

	handleCheck = () => {
		let checkedState = this.state.checked;

		this.setState({
			error: false,
			checked: !checkedState
		});
	}

	getSalutation = () => {
		if(this.state.prevData.anrede !== undefined){
			if(this.state.prevData.anrede === "Frau"){
				return "Sehr geehrte Frau ";
			} else if (this.state.prevData.anrede === "Herr"){
				return "Sehr geehrter Herr ";
			} else if (this.state.prevData.anrede === "Divers"){
				return "Sehr geehrte/r/s ";
			} else if (this.state.prevData.anrede === "Firma"){
				return "Sehr geehrte Firma ";
			}
		} else {
			return "Sehr geehrte/r/s";
		}
	}

	hasChanges = () => {
		if(this.state.prevData.anrede !== this.state.anrede || this.state.prevData.firstname !== this.state.firstname ||
			this.state.prevData.lastname !== this.state.lastname || this.state.prevData.firma !== this.state.firma) {
			return true;
		} else {
			return false;
		}
	}

	updateAcc = () => {
		let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if(this.state.mail === "") {
			this.setState({error: true, message: "E-Mail ist ein Pflichtfeld."});
			return;
		} else if(!regEx.test(this.state.mail)) {
			this.setState({error: true, message: "Die angebene E-Mail ist ungültig."});
			return;
		} else if(this.state.nameRequired && (this.state.firstname === "" || this.state.lastname === "")) {
			this.setState({error: true, message: "Vorname und Nachname sind Pflichtfelder, wenn Sie Ihre Anrede angeben möchten."});
			return;
		} else if(this.state.anredeRequired && this.state.anrede === undefined) {
			this.setState({error: true, message: "Anrede ist ein Pflichtfeld, wenn Sie Ihren Namen angeben möchten."});
			return;
		} else if(this.state.firmaRequired && (this.state.firma === "" || this.state.firma === undefined)) {
			this.setState({error: true, message: "Firma ist ein Pflichtfeld, wenn Sie Ihre Anrede angeben möchten."});
			return;
		}

		axios.post(`${settings.servicePath}/agreement/sec/${sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode")}/customer/update`,
	{anrede: (this.state.anrede && this.state.anrede !== "") ? this.state.anrede : null, firstName: this.state.firstname !== "" ? this.state.firstname : null, name: this.state.lastname !== "" ? this.state.lastname : null, company: this.state.firma !== "" ? this.state.firma : null})
			.then(result => {
				if(result.data === "OK") {
					this.setState({prevData: { anrede: this.state.anrede, firstname: this.state.firstname, lastname: this.state.lastname, company: this.state.company }});
					this.props.updated();
				}			
			})
	}

	deleteAcc = () => {
		axios.post(`${settings.servicePath}/agreement/sec/customer/${sessionStorage.getItem("linkCode") ? sessionStorage.getItem("linkCode") : Cookies.get("linkCode")}/delete`, {mail: sessionStorage.getItem("mail") ? sessionStorage.getItem("mail") : Cookies.get("mail")})
			.then((result) => {
				if(result.data === "OK") {
					sessionStorage.setItem("deletionSuccess", true);
					sessionStorage.removeItem("linkCode");
					sessionStorage.removeItem("mail");
					sessionStorage.removeItem("firstname");
					sessionStorage.removeItem("lastname");
					sessionStorage.removeItem("company");
					sessionStorage.removeItem("anrede");
					localStorage.removeItem("stay");
					Cookies.remove("addCode");
					Cookies.remove("linkCode");
					Cookies.remove("mail");

					this.props.history.push(`/customerpanel?start=agreement`);
					location.reload();
				} else if(result.data === "INTERNAL_SERVER_ERROR") {
					this.setState({serverError: true});
				}
			})
	}

	render(){
		if(!this.state.stateLoaded) { return null; }
		else {
			return(
				<div>
					{this.state.message !== "" &&
						<Row gutter={24}>
							<Col className={"gutter-row"} span={24}>
								<Alert
									message=""
									description={this.state.message}
									type={this.state.error ? "error" : "info"}
								/>
							</Col>
						</Row>
					}
					<Divider orientation="left">Persönliche Daten</Divider>
					<Row gutter={24}>
						<Col className="gutter-row" sm={24} md={14} lg={12}>
							<Form style={{marginBottom: "2rem"}}>
								<Form.Item
									label="E-Mail Adresse"
									name="mail"
									style={{marginBottom: "0"}}
								>
									<Text>{this.state.mail}</Text>
								</Form.Item>
								<Row gutter={24}>
									<Col className="gutter-row" sm={24} md={9} lg={7}>
										<FormItem label={`Anrede${!this.state.anredeRequired ? " (Optional)" : ""}`} style={{marginBottom: "0"}} required={this.state.anredeRequired}>
											<Select
												placeholder="Anrede"
												allowClear={true}
												onChange={(value) => this.onChange(value, "anrede")}
												value={this.state.anrede}
											>
												<Select.Option value="Herr">Herr</Select.Option>
												<Select.Option value="Frau">Frau</Select.Option>
												<Select.Option value="Divers">Divers</Select.Option>
												<Select.Option value="Firma">Firma</Select.Option>
											</Select>
										</FormItem>
									</Col>
								</Row>
								{this.state.anrede !== "Firma" ?
									<Row gutter={24}>
										<Col className="gutter-row" sm={24} md={14} lg={12}>
											<Form.Item
												label={`Vorname${!this.state.nameRequired ? " (Optional)" : ""}`}
												name="firstname"
												style={{marginBottom: "0"}}
												required={this.state.nameRequired}
											>
												<Input value={this.state.firstname} onChange={(e) => this.onChange(e.target.value, "firstname")} />
											</Form.Item>
										</Col>
										<Col className="gutter-row" sm={24} md={14} lg={12}>
											<Form.Item
												label={`Nachname${!this.state.nameRequired ? " (Optional)" : ""}`}
												name="lastname"
												style={{marginBottom: "1rem"}}
												required={this.state.nameRequired}
											>
												<Input value={this.state.lastname} onChange={(e) => this.onChange(e.target.value, "lastname")} />
											</Form.Item>
										</Col>
									</Row>
									:
									<Form.Item
										label={`Firmenname${!this.state.firmaRequired ? " (Optional)" : ""}`}
										name="firma"
										style={{marginBottom: "1rem"}}
										required={this.state.firmaRequired}
									>
										<Input value={this.state.firma} onChange={(e) => this.onChange(e.target.value, "firma")} />
									</Form.Item>
								}
								<Button disabled={!this.hasChanges()} onClick={this.updateAcc} type="primary">Speichern</Button>
							</Form>
						</Col>
					</Row>

					<Divider orientation="left">Einverständniserklärung</Divider>

					<div style={{width: "100%"}} id={"agreement"}>
						{
							this.state.linkExpired ?
								<div>
									<p>
										Dieser Link ist abgelaufen.
									</p>

									<Button
										type="primary"
										onClick={() => this.props.history.push(`/`)}
									>
										Zurück zum Fahrzeugmarkt
									</Button>
								</div>
							: this.state.pending && (this.state.alreadyChecked === false || this.state.alreadyChecked === undefined) ?
								<div>
									<p style={{marginTop: "2em"}}>
										{this.state.prevData.anrede !== "" ? this.getSalutation() : "Sehr geehrte/r/s"}{this.state.prevData.anrede !== "Firma" ? `${this.state.prevData.firstname} ${this.state.prevData.lastname}` : this.state.prevData.firma},
									</p>
									<p style={{marginBottom: "3em"}}>
										vielen Dank für Ihr Interesse an unserem Benachrichtigungsservice. Gerne möchten wir
										Sie künftig über Zukäufe für Sie relevanter Fahrzeuge informieren.
										Hierzu bitten wir Sie, die untenstehenden datenschutzrechtlichen Bestimmungen zu
										lesen und ihnen zuzustimmen.
										Daraufhin erhalten Sie eine Bestätigung für die erfolgreiche Anmeldung zu unserem
										Service.
									</p>
									<Row gutter={16} style={{marginBottom: "2em"}}>
										<Col className={"gutter-row"} xs={24} md={1} style={{textAlign: "center"}} xs={{ order: 2 }} md={{ order: 1 }} >
											<Checkbox
												onClick={this.handleCheck}
												name="Checked"
												checked={this.state.checked}
											/>
										</Col>
										<Col className={"gutter-row agreement-explain"} xs={24} md={23} xs={{ order: 1 }} md={{ order: 2 }}>
											<div style={{color: "black"}}>
												<p>
													Ja, ich bin damit einverstanden, dass die Auer Gruppe meine vorstehend
													angegebenen Kontaktdaten (d.h. Name und E-Mail-Adresse),
													sowie Informationen in Bezug auf angefragte Fahrzeuge zukünftig dafür
													speichern und verwenden darf, um mich gelegentlich via E-Mail über für
													mich interessante Produkte und Leistungen aus ihrem Sortiment zu
													informieren.
													(insbesondere Zusendung von Fahrzeuginformationen und -angeboten zu
													neuen Ankäufen, basierend auf dem angefragten Fahrzeugmodell).
												</p>
												<p>
													Meine Kontaktdaten werden ohne meine oder eine gesetzliche Erlaubnis
													nicht an Dritte übermittelt.
												</p>
												<p>
													Meine vorstehende Einwilligung gilt so lange, bis ich sie widerrufe.
													Diesen Widerruf kann ich zu jedem späteren Zeitpunkt ohne Angabe von
													Gründen mit Wirkung für die Zukunft telefonisch, schriftlich oder per
													E-Mail erklären.
													(z.B an datenschutz@auer-gruppe.de).
													Ferner stehen mir die weiteren, in den <Button style={{padding: "0"}}
																								   type="link"
																								   href={"https://auer-gruppe.de/datenschutzerklaerung/"}
																								   target={"_blank"}>Datenschutzhinweisen</Button> der
													Auer Gruppe GmbH dargestellten Rechte zu.
												</p>
											</div>
										</Col>
									</Row>

									{this.state.error &&
									<Text type="danger" style={{display: "block", marginBottom: "1em"}}>
										Eine Registrierung ist nur bei aktiver Bestätigung der datenschutzrechtlichen
										Hinweise möglich.
										Bitte bestätigen Sie diese und schließen Sie daraufhin die Registrierung erneut ab.
									</Text>
									}
									<Button
										type="primary"
										onClick={this.handleAccept}
									>
										Einwilligung geben
									</Button>
								</div>
							: this.state.pending && this.state.alreadyChecked === true ?
								<div>
									{this.state.wantDecline ?
										<p>
											Möchten Sie diese Zustimmung widerrufen klicken Sie bitte auf den
											untenstehenden Button.
										</p>
										:
										<p>
											Sie haben bereits Ihre Einverständniserklärung für unseren E-Mail
											Benachrichtigungs Service abgegeben.
											Möchten Sie diese Zustimmung widerrufen, klicken Sie bitte auf den
											untenstehenden Button.
										</p>
									}

									<Button
										type="primary"
										onClick={this.handleDecline}
									>
										Zustimmung Widerrufen
									</Button>
								</div>
							:
								<p>
									Vielen Dank. Wir
									haben {this.state.lastAction === "accept" ? "Ihre Einverständniserklärung" : "Ihren Widerruf"} erhalten.
								</p>
						}
					</div>

					<Divider />

					<Popconfirm
						title="Möchten Sie Ihr Kundenkonto unwiderruflich löschen?"
						onConfirm={this.deleteAcc}
						okText="Ja"
						cancelText="Nein"
					>
						<Button type={"danger"}>Kundenkonto löschen</Button>
					</Popconfirm>
				</div>
			)
		}
	}
}

function mapStateToProps(state, ownProps) {
	return {
		result: state.result,
		site: state.site
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(resultActions, dispatch),
		siteActions: bindActionCreators(siteActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AgreementLink);