import React, {Component} from 'react';
import {Select} from 'antd';

const Option = Select.Option;

class SelectSearch extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: undefined,
            options: {}
        }
    }

    componentDidMount() {
        this.setState({ value: this.props.multiple ? [] : {} });
    }

    onChange(value) {
        if (this.props.name !== "model" || this.props.name !== "brand" || this.props.name !== "modelSeries") {
            this.props.actions.setSearchFilterKey(this.props.name, value);
        }

		if (this.props.name === "model") {
            let newValue = [];

			// From selected models back to only "alle"
			if (value.length > 1 && value[value.length - 1] === "alle") {
                newValue = [];
			} else if (value.length > 1 && value[0] === "alle") {
                newValue = value.filter(value => value !== "alle");
			} else {
                newValue = value;
            }

            if (value.length === 1 && value[0] === "alle") {
                newValue = [];
            }

            let newModels = {};

            if (this.props.searchFilter.model !== undefined) {
                newModels = Object.assign({}, this.props.searchFilter.model);
            }

            if (newValue.length === 0) {
                delete newModels[this.props.brand];
            } else {
                newModels[this.props.brand] = newValue;
            }
           
            this.props.actions.setSearchFilterKey("model", newModels);
		}

        if (this.props.name === "brand") {
            if (value) {
                this.props.actions.loadBrandModels(value, this.props.searchMeta.searchClass);
                if(value === "BMW" && this.props.searchMeta.searchClass === "Car" || this.props.searchMeta.searchClass === "Motorbike") { this.props.actions.loadModelSeries(this.props.searchMeta.searchClass); }

                let brands = [];
                
                if (this.props.searchFilter.brand !== undefined && this.props.searchFilter.brand.length > 0) {
                    brands = Object.assign([], this.props.searchFilter.brand);
                }

                brands[this.props.index] = { ...brands[this.props.index], key: value };

                let currentModels = Object.assign({}, this.props.searchFilter.model);
                let currentModelSeries = Object.assign({}, this.props.searchFilter.modelSeries);
               
                // Ausgewählte Modelle der vorherigen Marke, falls vorhanden, löschen.
                if (this.props.searchFilter.brand !== undefined) {
                    let previousBrand = this.props.searchFilter.brand[this.props.index].key;
                    delete currentModels[previousBrand];
                    delete currentModelSeries[previousBrand];
    
                    this.props.actions.setSearchFilterKey("model", currentModels);
                    this.props.actions.setSearchFilterKey("modelSeries", currentModelSeries);
                }

                this.props.actions.setSearchFilterKey(this.props.name, brands);
            }
        }

        if (this.props.name === "modelSeries") {
            let newValue = [];

            // From selected modelSeries back to only "alle"
            if (value.length > 1 && value[value.length - 1] === "alle") {
                newValue = [];
            } else if (value.length > 1 && value[0] === "alle") {
                newValue = value.filter(value => value !== "alle");
            } else {
                newValue = value;
            }

            if (value.length === 1 && value[0] === "alle") {
                newValue = [];
            }

            let newModelSeries = {};

            if (this.props.searchFilter.modelSeries !== undefined) {
                newModelSeries = Object.assign({}, this.props.searchFilter.modelSeries);
            }

            //Überarbeiten
            if (newValue.length === 0) {
                delete newModelSeries[this.props.brand];
            } else {
                newModelSeries[this.props.brand] = newValue;
            }

            this.props.actions.setSearchFilterKey("modelSeries", newModelSeries);
        }

        if (this.props.name === "usageType") {
            if (value.length > 0 && !this.props.newFilterDisabled) {
                this.props.toggleNewCheckbox();
            } else if (value.length === 0 && this.props.newFilterDisabled) {
                this.props.toggleNewCheckbox();
            }
        }

        this.props.handleChange();
    }

    prerender() {
        let options = [];
        let valueList = Object.assign([], this.props.valueList);

        if (this.props.showAllSelect) {
            options.push(<Option key="alle" value="alle">Alle</Option>);
        }

        if (this.props.name === "category") {
            valueList.sort((a, b) => {
                let valueA = a.value.toLowerCase();
                let valueB = b.value.toLowerCase();

                if (valueA < valueB) {
                    return -1;
                }

                if (valueA > valueB) {
                    return 1;
                }

                return 0;
            });
        }

        this.props.valueList && valueList.map(item => {
            if(item == null || item == undefined) {
                return;
            }

            if (item.value && item.value.toUpperCase() === "ANDERE" && this.props.name !== "model") {
                return;
            }

            options.push(<Option key={item.key} value={item.key}>{item.value}</Option>);
        });

        if (this.props.name === "category") {
            if (this.props.searchClass === "Car") {
                options.push(<Option key={"OtherCar"} value={"OtherCar"}>Andere</Option>);
            }

            if (this.props.searchClass === "Motorbike") {
                options.push(<Option key={"OtherMotorbike"} value={"OtherMotorbike"}>Andere</Option>);
            }
        }

        if (this.props.name === "model") {
            let containsAndere = options.filter(item => {
                return item.key === "ANDERE";
            });

            if (containsAndere.length > 0) {
                options = options.filter(item => {
                    return item.key !== "ANDERE";
                });
                options.push(<Option key={"ANDERE"} value={"ANDERE"}>Andere</Option>);
            }
        }

        this.state.options = options;
    }

    render() {
        this.prerender();
        let allowClear = true;

        if (this.props.allowClear !== undefined) {
            allowClear = this.props.allowClear;
        }

        return (
            <Select
                showSearch
                style={{ width: "100%" }}
                mode={this.props.multiple ? "multiple" : null}
                onChange={this.onChange}
                allowClear={allowClear}
                placeholder={this.props.placeholder || "Alle"}
                notFoundContent="Keine Auswahl verfügbar"
                className="auer-select-field"
                value={this.props.value && this.props.value !== "" ? this.props.value : undefined}
                showArrow={true}
                filterOption={(inputValue, option) => {
                    let key = option.key;
                    let humanReadableKey;

                    if (this.props.name === "brand" || this.props.name === "model") {
                        humanReadableKey = option.key;
                    } else {
                        humanReadableKey = this.props.valueList.filter(item => item.key === key)[0].value;
                    }

                    return humanReadableKey.toUpperCase().includes(inputValue.toUpperCase());
                }}
            >
                {this.state.options}
            </Select>
        );
    }

}

export default SelectSearch;