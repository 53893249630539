// SEARCH //
export const SET_SEARCH_FILTER_KEY = "SET_SEARCH_FILTER_KEY";
export const SET_SEARCH_FILTER_FEATURE_KEY = "SET_SEARCH_FILTER_FEATURE_KEY";
export const SET_SEARCH_META_KEY = "SET_SEARCH_META_KEY";
export const SET_SEARCH_DATA_KEY = "SET_SEARCH_DATA_KEY";
export const REHYDRATE_SEARCH_FILTER = "REHYDRATE_SEARCH_FILTER";
export const REHYDRATE_SEARCH_META = "REHYDRATE_SEARCH_META";
export const SET_NEW_FILTER_DISABLED = "SET_NEW_FILTER_DISABLED";

export const SET_SEARCH_REQUEST_DATA = "SET_SEARCH_REQUEST_DATA";
export const RESET_SEARCH_REQUEST_DATA = "RESET_SEARCH_REQUEST_DATA";

export const EXECUTE_SEARCH_SUCCESS = "EXECUTE_SEARCH_SUCCESS";
export const EXECUTE_SEARCH_FAIL = "EXECUTE_SEARCH_FAIL";

export const GET_SEARCH_DATA_SUCCESS = "GET_SEARCH_DATA_SUCCESS";
export const GET_SEARCH_DATA_FAIL = "GET_SEARCH_DATA_FAIL";

export const LOAD_BRAND_MODELS_SUCCESS = "LOAD_BRAND_MODELS_SUCCESS";
export const LOAD_BRAND_MODELS_FAIL = "LOAD_BRAND_MODELS_FAIL";

export const GET_COUNT_SUCCESS = "GET_COUNT_SUCCESS";
export const GET_COUNT_FAIL = "GET_COUNT_FAIL";

export const GET_MODELSERIES_SUCCESS = "GET_MODELSERIES_SUCCESS";
export const GET_MODELSERIES_FAIL = "GET_MODELSERIES_FAIL";

export const GET_EXISTING_NEW_VEHICLE_BRANDS_SUCCESS = "GET_EXISTING_NEW_VEHICLE_BRANDS_SUCCESS";
export const GET_EXISTING_NEW_VEHICLE_BRANDS_FAIL = "GET_EXISTING_NEW_VEHICLE_BRANDS_FAIL";

export const RESET_SEARCH_STATE = "RESET_SEARCH_STATE";
export const SET_SEARCH_REDUCER_KEY = "SET_SEARCH_REDUCER_KEY";

export const LOAD_MOTORBIKE_DRIVING_MODES_FAIL = "LOAD_MOTORBIKE_DRIVING_MODES_FAIL";
export const LOAD_MOTORBIKE_DRIVING_MODES_SUCCESS = "LOAD_MOTORBIKE_DRIVING_MODES_SUCCESS";

// RESULT //
export const LOAD_SINGLE_VEHICLE_SUCCESS = "LOAD_SINGLE_VEHICLE_SUCCESS";
export const LOAD_SINGLE_VEHICLE_FAIL = "LOAD_SINGLE_VEHICLE_FAIL";

export const LOAD_INTERESTING_VEHICLES_SUCCESS = "LOAD_INTERESTING_VEHICLES_SUCCESS";
export const LOAD_INTERESTING_VEHICLES_FAIL = "LOAD_INTERESTING_VEHICLES_FAIL";

export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";

export const SEND_CHECK_LINK_CODE_FAIL = "SEND_CHECK_LINK_CODE_FAIL";
export const SEND_CHECK_LINK_CODE_SUCCESS = "SEND_CHECK_LINK_CODE_SUCCESS";

export const SEND_AGREEMENT_FAIL = "SEND_AGREEMENT_FAIL";
export const SEND_AGREEMENT_SUCCESS = "SEND_AGREEMENT_SUCCESS";

export const RESET_RESULT_VEHICLE = "RESET_RESULT_VEHICLE";

// SITE
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";

export const LOAD_COMPARSION_VEHICLES_SUCCESS = "LOAD_COMPARSION_VEHICLES_SUCCESS";
export const LOAD_COMPARSION_VEHICLES_FAIL = "LOAD_COMPARSION_VEHICLES_FAIL";
export const RESET_COMPARSION_VEHICLES = "RESET_COMPARSION_VEHICLES";