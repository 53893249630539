import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import SuggestionItem from './SuggestionItem';
import settings from 'settings';

const SuggestionList = ({ interesting, siteActions, resultActions, history }) => {

	if(interesting && interesting.length > 0) {
		return (
			<div className="container suggestions">
				
				<div className="heading">
					<div className="text">
						<div className="skewed-bg"></div>
						<span>Weitere Vorschläge</span>
					</div>
				</div>

				<div className="row">
					{interesting.map(item => {
						return (
							<div className="tcol-2" key={item.internalId}>
								<Link to={`/fahrzeug/${item.internalId}`} onClick={e => {
									e.preventDefault();
									siteActions.setPageLoadingStatus(true)
										.then(() => {
											resultActions.resetVehicle();
											history.push(`/fahrzeug/${item.internalId}`);
										})
								}}>
									<SuggestionItem suggestedVehicle={item} img={`${settings.imagePath}${item.image}`} />
								</Link>
							</div>
						)
					})}
				</div>

			</div>
		);
	} else {
		return null;
	}

};

export default withRouter(SuggestionList);