import * as types from 'actions/actionTypes';
import settings from 'settings';
import axios from 'axios';

export function resetVehicle() {
	return {
		type: types.RESET_RESULT_VEHICLE
	}
}

/**
 * Lädt die daten eines einzelnen Fahrzeugs anhand der adId.
 * @param {number} adId
 */
export function loadSingleVehicle(adId) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			axios.get(`${settings.servicePath}/ad/${adId}`)
			.catch(err => {
				console.log(err.response);
				dispatch(loadSingleVehicleFail("Dieses Fahrzeug existiert nicht."));
				reject(err.message);
			})
			.then(res => {
				dispatch(loadSuggestions(adId));
				dispatch(loadSingleVehicleSuccess(res.data));
				let vehicle = res.data
				let vehicleText = `Sehr geehrte Damen und Herren,

ich interessiere mich für folgendes Fahrzeug:

Zustand: ${vehicle.condition}
${vehicle.make} ${vehicle.shortDescription}
Interne Nr. ${vehicle.interneFahrzeugnummer}

Preis:  ${vehicle.price}

Bitte nehmen Sie Kontakt mit mir auf!
${vehicle.dealersOnly ?
	"Ich habe gelesen, dass dieses Fahrzeug ausschließlich an Händler und Gewerbetreibende unter Ausschluss jeglicher Gewährleistungen verkauft wird!"
	: ""}`;
				resolve({ vehicle, vehicleText });
			});
		});
	};
}

export function loadSingleVehicleFail(errMsg) {
	return {
		type: types.LOAD_SINGLE_VEHICLE_FAIL,
		errMsg
	};
}

export function loadSingleVehicleSuccess(data) {
	return {
		type: types.LOAD_SINGLE_VEHICLE_SUCCESS,
		data
	};
}



/**
 * Lädt ähnliche Fahrzeuge anhand der adId.
 * @param {number} adId
 */
export function loadSuggestions(adId) {
	return dispatch => {
		return axios.get(`${settings.servicePath}/suggestion?vehicle=${adId}`)
			.catch(err => {
				console.log(err.response);
				dispatch(loadSuggestionsFail(err.message));
				throw new Error(err.message);
			})
			.then(res => {
				let maxSize = res.data.length < 3 ? res.data.length : 3;
				let suggestions = [];
				for(let i = 0; i < maxSize; i++) {
					suggestions.push(res.data[i]);
				}
				dispatch(loadSuggestionsSuccess(suggestions));
			})
	};
}

export function loadSuggestionsFail(errMsg) {
	return {
		type: types.LOAD_INTERESTING_VEHICLES_FAIL,
		errMsg
	};
}

export function loadSuggestionsSuccess(data) {
	return {
		type: types.LOAD_INTERESTING_VEHICLES_SUCCESS,
		data
	};
}

/**
 * Versendet die Email
 * @param {obj} emailObj
 */
export function sendMail(emailObj, adId) {
	return dispatch => {
		return axios.post(`${settings.servicePath}/ad/${adId}/request`, emailObj)
			.catch(err => {
				dispatch(sendMailFail(err.message));
				throw new Error(err.message);
			})
			.then(res => {
				dispatch(sendMailSuccess(res));
			});
	}
}

export function sendMailFail(errMsg) {
	return {
		type: types.SEND_MAIL_FAIL,
		errMsg
	};
}

export function sendMailSuccess(data) {
	return {
		type: types.SEND_MAIL_SUCCESS,
		data
	};
}

/**
 * Versendet die Zustimmung für Angebote
 * @param {string} linkCode
 */
export function checkLinkCode(linkCode) {
	return dispatch => {
		return axios.post(`${settings.servicePath}/agreement`, linkCode)
			.catch(err => {
				dispatch(checkLinkCodeFail(err.message));
				throw new Error(err.message);
			})
			.then(res => {
				dispatch(checkLinkCodeSuccess(res));
			});
	}
}

export function checkLinkCodeFail(errMsg) {
	return {
		type: types.SEND_CHECK_LINK_CODE_FAIL,
		errMsg
	};
}

export function checkLinkCodeSuccess(data) {
	return {
		type: types.SEND_CHECK_LINK_CODE_SUCCESS,
		data
	};
}

/**
 * Versendet die Zustimmung für Angebote
 * @param {obj} agreementObj
 */
export function sendAgreement(agreementObj) {
	return dispatch => {
		return axios.post(`${settings.servicePath}/agreement/set`, agreementObj)
			.catch(err => {
				dispatch(sendAgreementFail(err.message));
				throw new Error(err.message);
			})
			.then(res => {
				dispatch(sendAgreementSuccess(res));
			});
	}
}

export function sendAgreementFail(errMsg) {
	return {
		type: types.SEND_AGREEMENT_FAIL,
		errMsg
	};
}

export function sendAgreementSuccess(data) {
	return {
		type: types.SEND_AGREEMENT_SUCCESS,
		data
	};
}