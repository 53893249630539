import * as types from 'actions/actionTypes';
import settings from 'settings';
import axios from 'axios';
import * as helper from "helper";

/**
 * Setzt einen key und dessen Wert im Suchfilter.
 * @param {string} key Der zu setzende Key
 * @param {any} value Der dazugehörige Wert
 */
export function setSearchFilterKey(key, value) {
	return {
		type: types.SET_SEARCH_FILTER_KEY,
		key,
		value
	};
}

/**
 * Setzt einen key im features Property und dessen Wert im Suchfilter.
 * @param {string} key Der zu setzende Key
 * @param {any} value Der dazugehörige Wert
 */
export function setSearchFilterFeatureKey(key, value) {
    return {
        type: types.SET_SEARCH_FILTER_FEATURE_KEY,
        key,
        value
    };
}

export function rehydrateSearchFilter(value) {
	return {
		type: types.REHYDRATE_SEARCH_FILTER,
		value
	};
}

export function rehydrateSearchMeta(value) {
	return {
		type: types.REHYDRATE_SEARCH_META,
		value
	};
}

/**
 * Setzt einen key und dessen Wert in der Suchmeta.
 * @param {string} key Der zu setzende Key
 * @param {any} value Der dazugehörige Wert
 */
export function setSearchMetaKey(key, value) {
	return {
		type: types.SET_SEARCH_META_KEY,
		key,
		value
	};
}

/**
 * Setzt einen key und dessen Wert in der Searchdata.
 * @param {string} key Der zu setzende Key
 * @param {any} value Der dazugehörige Wert
 */
export function setSearchDataKey(key, value) {
	return {
		type: types.SET_SEARCH_DATA_KEY,
		key,
		value
	};
}

/**
 * Ruft alle verfügbaren Daten für die Suche ab.
 * @param {string} searchClass Die zu suchende Fahrzeugklasse
 */
export function getSearchData(searchClass) {
	let requests = [
		axios.get(`${settings.servicePath}/${searchClass}/brands`),
		axios.get(`${settings.servicePath}/${searchClass}/categories`),
		axios.get(`${settings.servicePath}/${searchClass}/doorcounts`),
		axios.get(`${settings.servicePath}/${searchClass}/fuels`),
		axios.get(`${settings.servicePath}/${searchClass}/motorarten`),
		axios.get(`${settings.servicePath}/${searchClass}/gearboxes`),
		axios.get(`${settings.servicePath}/${searchClass}/usagetypes`),
		axios.get(`${settings.servicePath}/${searchClass}/colors`),
		axios.get(`${settings.servicePath}/${searchClass}/sites`),
		axios.get(`${settings.servicePath}/${searchClass}/features`),
		axios.get(`${settings.servicePath}/${searchClass}/conditions`),
		axios.get(`${settings.servicePath}/${searchClass}/emissionStickers`),
		axios.get(`${settings.servicePath}/${searchClass}/drivingmodes`),
		axios.get(`${settings.servicePath}/${searchClass}/maxPower`),
		axios.get(`${settings.servicePath}/${searchClass}/maxLength`),
		axios.get(`${settings.servicePath}/${searchClass}/maxHeight`),
		axios.get(`${settings.servicePath}/${searchClass}/maxWidth`),
		axios.get(`${settings.servicePath}/${searchClass}/maxWeight`),
	];
	
	return dispatch => {
		return axios.all(requests)
			.catch(err => {
				dispatch(getSearchDataFail(err.message));
				throw new Error(err.message);
			})
			.then(axios.spread((
				brands,
				categories,
				doorCount,
				fuels,
				motorarten,
				gearboxes,
				usageTypes,
				colors,
				sites,
				features,
				conditions,
				emissionStickers,
				drivingModes,
				maxPower,
				maxLength,
				maxHeight,
				maxWidth,
				maxWeight,
			) => {
				dispatch(getSearchDataSuccess({
					brands: brands.data,
					categories: categories.data,
					doorCount: doorCount.data,
					fuels: fuels.data,
					motorarten: motorarten.data,
					gearboxes: gearboxes.data,
					usageTypes: usageTypes.data,
					colors: colors.data,
					sites: sites.data,
					features: features.data,
					conditions: conditions.data,
					emissionStickers: emissionStickers.data,
					drivingModes: drivingModes.data,
					maxPower: maxPower.data,
					maxLength: helper.calcMaxSliderValue(maxLength.data),
					maxHeight: helper.calcMaxSliderValue(maxHeight.data),
					maxWidth: helper.calcMaxSliderValue(maxWidth.data),
					maxWeight: helper.calcMaxSliderValue(maxWeight.data),
				}));
			}));
	}
}

function getSearchDataSuccess(data) {
	return {
		type: types.GET_SEARCH_DATA_SUCCESS,
		data
	};
}

function getSearchDataFail(errMsg) {
	return {
		type: types.GET_SEARCH_DATA_FAIL,
		errMsg
	};
}

/**
 * Lädt die verfügbaren Modelle anhand der Marke.
 * @param {string} brand Die Marke
 * @param {string} searchClass Die zu suchende Fahrzeugklasse (Motorrad/Automobile)
 * @param {array} modelSeries Die zu suchenden Modelreihen
 */
export function loadBrandModels(brand, searchClass, modelSeries = []) {
	return dispatch => {
		return axios.get(`${settings.servicePath}/${searchClass}/${brand}/models`)
		.catch(err => {
			dispatch(loadBrandModelsFail(err.message));
			throw new Error(err.message);
		})
		.then(res => {
		if(modelSeries && modelSeries.length > 0) {
				let brandModels = [];
				res.data.map((model) => {
					modelSeries.map((modelSerie) => {
						let serie = "";
						serie = modelSerie.split("-")[0];

						if(isFinite(model.key.charAt(0)) && serie.charAt(0)) {
							if(model.key.charAt(0) === serie.charAt(0)) {
								brandModels.push(model);
							}
						}
						else if(isNaN(model.key.charAt(0)) && isNaN(serie.charAt(0))) {
							if(model.key.charAt(0).toUpperCase() == serie.charAt(0).toUpperCase()) {
								brandModels.push(model);
							}
						}
					});
				});
				dispatch(loadBrandModelsSuccess(brand, brandModels));
			} else {
				dispatch(loadBrandModelsSuccess(brand, res.data));
			}
		})
	}
}

function loadBrandModelsSuccess(brand, data) {
	return {
		type: types.LOAD_BRAND_MODELS_SUCCESS,
		data: { brand, response: data }
	};
}

function loadBrandModelsFail(errMsg) {
	return {
		type: types.LOAD_BRAND_MODELS_FAIL,
		errMsg
	};
}

/**
 * Lädt die verfügbaren Antriebsarten für die Motorradsuche
 */
export function loadMotorbikeDrivingModes() {
	return dispatch => {
		return axios.get(`${settings.servicePath}/Motorbike/drivingmodes`)
		.catch(err => {
			dispatch(loadMotorbikeDrivingModesFail(err.message));
			throw new Error(err.message);
		})
		.then(res => {
			dispatch(loadMotorbikeDrivingModesSuccess(res.data));
		})
	};
}

function loadMotorbikeDrivingModesFail(errMsg) {
	return {
		type: types.LOAD_MOTORBIKE_DRIVING_MODES_FAIL,
		errMsg
	};
}

function loadMotorbikeDrivingModesSuccess(data) {
	return {
		type: types.LOAD_MOTORBIKE_DRIVING_MODES_SUCCESS,
		data
	};
}

/**
 * Führt die Suche aus.
 * @param {obj} searchObj Suchfilter
 * @param {obj} searchMeta Suchmeta
 */
export function executeSearch(searchObj, searchMeta) {
	let obj = createSearchState(Object.assign({}, searchObj));
	let search = Object.assign({}, obj, searchMeta);
	let regVehicleSearch = Object.assign({}, obj, searchMeta);
	let newVehicleSearch = Object.assign({}, obj, searchMeta);

	// page offset
	search.page = search.page - 1;
	let combinedSearch = {
		"fmSearchQueriesRequestPartList" : [],
		"page": search.page,
		"pageSize": search.pageSize,
		"sortField": search.sortField,
		"sortDescending": search.sortDescending,
		"searchClass": search.searchClass,
		"eingetroffenVor": search.eingetroffenVor
	};
	return dispatch => {
		if(search.condition && ((search.condition.includes("NEW") || search.condition.length === 0) && search.regMin !== null)) {
			newVehicleSearch.regMin = null;
			newVehicleSearch.condition = ["NEW"];

			delete regVehicleSearch.page;
			delete regVehicleSearch.pageSize;
			delete regVehicleSearch.sortField;
			delete regVehicleSearch.sortDescending;
			delete regVehicleSearch.searchClass;
			delete regVehicleSearch.eingetroffenVor;
			delete newVehicleSearch.page;
			delete newVehicleSearch.pageSize;
			delete newVehicleSearch.sortField;
			delete newVehicleSearch.sortDescending;
			delete newVehicleSearch.searchClass;
			delete newVehicleSearch.eingetroffenVor;

			combinedSearch.fmSearchQueriesRequestPartList.push(regVehicleSearch);
			combinedSearch.fmSearchQueriesRequestPartList.push(newVehicleSearch);

			return new Promise((resolve, reject) => {
				axios.post(`${settings.servicePath}/searchqueries`, combinedSearch)
					.catch(err => {
						dispatch(executeSearchFail("Fehler bei der Suche"));
						reject(err.msg);
					})
					.then(async res =>
					{
						dispatch(executeSearchSuccess(res.data));
						resolve(res.data);
					});
			});
		} else {
			return new Promise((resolve, reject) => {
				return axios.post(`${settings.servicePath}/search`, search)
				.catch(err => {
					dispatch(executeSearchFail(err.msg));
					reject(err.msg);
				})
				.then(res => {
					dispatch(executeSearchSuccess(res.data));
					resolve(res.data);
				});
			});
		}
	};
}

function executeSearchSuccess(res) {
	return {
		type: types.EXECUTE_SEARCH_SUCCESS,
		data: res.items
	}
}

function executeSearchFail(errMsg) {
	return {
		type: types.EXECUTE_SEARCH_FAIL,
		errMsg
	};
}

/**
 * Führt eine Abfrage der Anzahl der möglichen Suchergebnisse durch
 * @param {obj} searchObj Suchfilter
 * @param {obj} searchMeta Suchmeta
 */
export function getCount(searchObj, searchMeta) {
	let obj = createSearchState(Object.assign({}, searchObj));
	let search = Object.assign({}, obj, searchMeta);
	let regVehicleSearch = Object.assign({}, obj, searchMeta);
	let newVehicleSearch = Object.assign({}, obj, searchMeta);
	let combinedSearch = {
		"fmSearchQueriesRequestPartList" : [],
		"page": search.page,
		"pageSize": search.pageSize,
		"sortField": search.sortField,
		"sortDescending": search.sortDescending,
		"searchClass": search.searchClass,
		"eingetroffenVor": search.eingetroffenVor
	};
	
	// unrepaired
	if(search.unrepaired == false) {
		delete search.unrepaired;
	}

	// accidentFree
	if(search.accidentFree == false) {
		delete search.accidentFree;
	}

	return dispatch => {
		let newVehicleItems = [];
		let searchItems = [];

		if(search.condition && (search.condition.includes("NEW") || search.condition.length === 0) && search.regMin !== null) {
			newVehicleSearch.regMin = null;
			newVehicleSearch.condition = ["NEW"];
			delete regVehicleSearch.page;
			delete regVehicleSearch.pageSize;
			delete regVehicleSearch.sortField;
			delete regVehicleSearch.sortDescending;
			delete regVehicleSearch.searchClass;
			delete regVehicleSearch.eingetroffenVor;
			delete newVehicleSearch.page;
			delete newVehicleSearch.pageSize;
			delete newVehicleSearch.sortField;
			delete newVehicleSearch.sortDescending;
			delete newVehicleSearch.searchClass;
			delete newVehicleSearch.eingetroffenVor;

			combinedSearch.fmSearchQueriesRequestPartList.push(regVehicleSearch);
			combinedSearch.fmSearchQueriesRequestPartList.push(newVehicleSearch);

			 return new Promise((resolve, reject) => {
				axios.post(`${settings.servicePath}/searchqueries`, combinedSearch)
					.catch(err => {
						dispatch(getCountFail("Fehler bei der Verarbeitung"));
						reject(err.message);
						throw new Error(err.message);
					})
					.then(res => {
						dispatch(getCountSuccess({"key":"count", "value":res.data.total.toString(), "items":res.data.items}));
						resolve({"key":"count", "value":res.data.total.toString()});
					});
			});
		} else {
			return new Promise((resolve, reject) => {
				axios.post(`${settings.servicePath}/count`, search)
					.catch(err => {
						dispatch(getCountFail("Fehler bei der Verarbeitung"));
						reject(err.message);
						throw new Error(err.message);
					})
					.then(res => {
						dispatch(getCountSuccess(res.data));
						resolve(res.data);
					});
			});
		}
	}
}

function getCountSuccess(data) {
	return {
		type: types.GET_COUNT_SUCCESS,
		data
	};
}

function getCountFail(errMsg) {
	return {
		type: types.GET_COUNT_FAIL,
		errMsg
	};
}

export function loadModelSeries(searchClass) {
	return dispatch => {
		return axios.get(`${settings.servicePath}/${searchClass}/BMW/modelseries`)
		.catch(err => {
			dispatch(getModelSeriesFail(err.message));
			throw new Error(err.message);
		})
		.then(res => {
			dispatch(getModelSeriesSuccess(res.data.slice(1), "BMW"));
		});
	}
}

function getModelSeriesSuccess(data, brand) {
	return {
		type: types.GET_MODELSERIES_SUCCESS,
		data: {brand, response: data}
	};
}

function getModelSeriesFail(errMsg) {
	return {
		type: types.GET_MODELSERIES_FAIL,
		errMsg
	};
}

export function getBrandsList(searchMeta, onlyNew = false, onlyUsed = false) {
	let requests = [];

	return dispatch => {
		const brands = axios.get(`${settings.servicePath}/${searchMeta.searchClass}/brands`);

		if(onlyNew || onlyUsed) {
			brands.then(brands => {
				brands.data.map((brand) => {
					requests.push(axios.post(`${settings.servicePath}/search`, {
						searchClass: searchMeta.searchClass,
						condition: onlyNew ? ["NEW"] : ["USED"],
						brands: [{key: brand.key}]
					}))
				});

				return axios.all(requests)
					.catch(err => {
						dispatch(getExistingNewVehicleBrandsFail(err.message));
						throw new Error(err.message);
					})
					.then(function (results) {
						let existingNewVehicleBrands = [];

						results.map((result) => {
							if (result.data.items.length > 0) {
								existingNewVehicleBrands.push({
									"key": result.data.items[0].make,
									"value": result.data.items[0].make
								});
							}
						});

						dispatch(getExistingNewVehicleBrandsSuccess(existingNewVehicleBrands));
					});
			})
			.catch(err => {
				dispatch(getExistingNewVehicleBrandsFail(err.message));
			});
		} else {
			brands
			.then(brands => {
				dispatch(getExistingNewVehicleBrandsSuccess(brands.data));
			})
			.catch(err => {
				dispatch(getExistingNewVehicleBrandsFail(err.message));
			});
		}
	}
}

function getExistingNewVehicleBrandsSuccess(data) {
	return {
		type: types.GET_EXISTING_NEW_VEHICLE_BRANDS_SUCCESS,
		data
	};
}

function getExistingNewVehicleBrandsFail(errMsg) {
	return {
		type: types.GET_EXISTING_NEW_VEHICLE_BRANDS_FAIL,
		errMsg
	};
}

export function resetSearchState() {
	return {
		type: types.RESET_SEARCH_STATE
	};
}

export function setSearchReducerKey(key, value) {
	return {
		type: types.SET_SEARCH_REDUCER_KEY,
		key,
		value
	};
}

export function setNewFilterDisabled(value) {
	return {
		type: types.SET_NEW_FILTER_DISABLED,
		value
	}
}

export function setSearchRequestData(daysBack, jumpToList, searchRequest) {
	return {
		type: types.SET_SEARCH_REQUEST_DATA,
		daysBack,
		jumpToList,
		searchRequest
	}
}

export function resetSearchRequestData() {
	return {
		type: types.RESET_SEARCH_REQUEST_DATA
	}
}

/**
 * Diese Methode bereitet den State aus dem Front-End so auf, dass es
 * dem Datenmodell im Backend entspricht.
 * @param {*} search Das Suchobjekt
 */
function createSearchState(search) {

	if(search.usageType && (search.usageType === "" || search.usageType.length === 0)) {
		delete search.usageType;
	}
	if(search.category && (search.category === "" || search.category.length === 0)) {
		delete search.category;
	}
	if(search.doorCount && search.doorCount.length === 0) {
		delete search.doorCount;
	}
	if(search.emissionStickers && search.emissionStickers.length === 0) {
		delete search.emissionStickers;
	}
	if(search.model === "") {
		delete search.model;
	}

	if (search.price) {
		search.priceMin = search.price[0] == 0 ? null : search.price[0];
		search.priceMax = search.price[1] == settings.priceMax ? null : search.price[1];
		delete search.price;
	}

	if (search.km) {
		search.kmMin = search.km[0] == 0 ? null : search.km[0];
		search.kmMax = search.km[1] == settings.kmMax ? null : search.km[1];
		delete search.km;
	}

	if (search.reg) {
		search.regMin = search.reg[0] == 1988 ? null : search.reg[0];
		search.regMax = search.reg[1] == settings.regMax ? null : search.reg[1];
		delete search.reg;
	}

	if (search.power) {
		search.powerMin = search.power[0] == 0 ? null : search.power[0];
		search.powerMax = search.power[1] == 0 ? null : search.power[1];
		delete search.power;
	}

	if (search.length) {
		search.lengthMin = search.length[0] == 0 ? null : search.length[0];
		search.lengthMax = search.length[1] == 0 ? null : search.length[1];
		delete search.length;
	}
	if (search.height) {
		search.heightMin = search.height[0] == 0 ? null : search.height[0];
		search.heightMax = search.height[1] == 0 ? null : search.height[1];
		delete search.height;
	}
	if (search.width) {
		search.widthMin = search.width[0] == 0 ? null : search.width[0];
		search.widthMax = search.width[1] == 0 ? null : search.width[1];
		delete search.width;
	}
	if (search.weight) {
		search.weightMin = search.weight[0] == 0 ? null : search.weight[0];
		search.weightMax = search.weight[1] == 0 ? null : search.weight[1];
		delete search.weight;
	}

	// TODO: Refactor
	if (search.brand) {
		let brandArray = [];

		search.brand.forEach((item, i) => {
			let brandObject = {};

			brandObject = Object.assign({}, item);

			if (brandObject.key === "alle" && !brandObject.modelDescription) {
				delete brandObject.key;
				return;
			} else if(brandObject.key === "alle" && brandObject.modelDescription) {
				brandObject = {"modelDescription": brandObject.modelDescription}
			}

			if (Object.keys(brandObject).length > 0) {
				brandArray.push(brandObject);
			}
		});

		if(brandArray.length > 0) { search.brands = brandArray; }

		delete search.brand;
	} else {
		delete search.brand;
	}

	if (search.model) {
		let modelArray = [];

		Object.keys(search.model).forEach(key => {
			modelArray.push({ brand: key, models: [...search.model[key]] });
		})

		search.model = modelArray;
	} else {
		delete search.model;
	}

	if (search.modelSeries) {
		let modelSeriesArray = [];

		Object.keys(search.modelSeries).forEach(key => {
			if(search.modelSeries[key]) {
				modelSeriesArray.push({ brand: key, modelSeries: [...search.modelSeries[key]] });
			}
		});

		search.modelSeries = modelSeriesArray;
	} else {
		delete search.modelSeries;
	}

	if (search.features) {
		let featureList = [];
		let arrayItems = ["OTHER", "COMFORT", "INTERIOR_EXTERIOR", "OTHER_FUEL", "SECURITY", "INFOTAINMENT"];

		Object.keys(search.features).map(key => {
			if(arrayItems.includes(key)) {
				if(search.features[key] && search.features[key].length > 0) {
					featureList = [...featureList, ...search.features[key]];
				}
			} else {
				if(search.features[key] !== undefined && search.features[key] !== "" && search.features[key] && search.features[key].length > 0) {
					featureList.push(search.features[key]);
				}
			}
		});
	
		search.features = featureList;
	}
	
	return search;
}