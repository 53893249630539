import React, { Component } from 'react';
import Color from './Color';

class ColorSelect extends Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.getSelectedColors = this.getSelectedColors.bind(this);
		this.state = {
			colors: []
		};
	}

	handleClick(key) {
		let currentColors = Object.assign([], this.state.colors);

		// Value bereits vorhanden -> wieder entfernen
		if(currentColors.includes(key)) {
			let index = currentColors.indexOf(key);
			currentColors.splice(index, 1);
		} else {
			// Value nicht vorhanden -> hinzufügen
			currentColors.push(key);
		}
		
		// state updaten, redux triggern
		this.setState({ colors: currentColors }, () => {
			this.props.onValueChange(this.state.colors);
		});
	}

	getSelectedColors() {
		return this.props.selectedColors;
	}

	
	componentWillReceiveProps(nextProps) {
		// sync with redux store
		this.setState({ colors: nextProps.selectedColors });
	}

	render() {
		let selectedColors = false; // this.props.selectedColors... (searchData/searchFilter Array vergleichen, if item is in array of output..)
		if(!this.props.colors) return null;
		return (
			<div id="color-select">
				{this.props.colors.map(color => (
					<Color
						key={color.key}
						value={color.key}
						name={color.value}
						onClick={this.handleClick}
						selectedColors={this.getSelectedColors()}
					/>
				))}
			</div>
		);
	}

}

/*
	proptypes
		- colors
		- selectedColors
*/

export default ColorSelect;