import React from 'react';

const EmissionSticker = ({ name }) => {
	let fileName = "";
	switch(name) {
		case "Grün":
			fileName = "green";
			break;

		case "Gelb":
			fileName = "yellow";
			break;

		case "Rot":
			fileName = "red";
			break;

		default:
			break;
	}

	if(fileName != "") {
		return (
			<img style={{ maxWidth: "100%", maxHeight: "100px", display: "block", margin: "0 auto" }} src={require(`../../../styles/img/${fileName}.png`)} alt={`Umweltplakette ${name.toUpperCase()}`} />
		);
	} else {
		return null;
	}
};

export default EmissionSticker;