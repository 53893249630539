import React from 'react';
import PropTypes from 'prop-types';

import Section from 'components/layout/Section';
import ConsumptionItem from 'components/vehicle/ConsumptionItem';
import EfficiencyClass from 'components/vehicle/EfficiencyClass';
import EmissionSticker from 'components/vehicle/EmissionSticker';
import * as helper from 'helper';

const VehicleEmissionInfo = ({ emissionFuelConsumption, site, emissionClass, emissionSticker }) => {
	if (emissionFuelConsumption === null || emissionFuelConsumption === undefined || site === "Zizenhausen") {
		return null;
	}

	return (
		<Section
			title="Verbrauchsangaben*"
			containerClassName="emission-section"
		>
			<div className={`tcol-${emissionFuelConsumption.energyEfficiencyClass != null ? 4 : 6}`}>
				<ConsumptionItem
					name="innerorts"
					value={emissionFuelConsumption.consumptionInner}
					suffix={`l/100km`}
					col={3}
				/>
				<ConsumptionItem
					name="außerorts"
					value={emissionFuelConsumption.consumptionOuter}
					suffix={`l/100km`}
					col={3}
				/>
				<ConsumptionItem
					name="kombiniert"
					value={emissionFuelConsumption.consumptionCombined}
					suffix={`l/100km`}
					col={3}
				/>
				<ConsumptionItem
					name="CO2-Emission (kombiniert)"
					value={emissionFuelConsumption.co2Emission}
					suffix={`g/km`}
					col={3}
				/>
				<ConsumptionItem
					name="Abgasnorm"
					value={helper.renderEmissionClass(emissionClass)}
					col={3}
				/>
				<ConsumptionItem
					name="Stromverbrauch (kombiniert)"
					value={emissionFuelConsumption.powerConsumptionCombined}
					suffix={`kWh/100km`}
					col={3}
				/>
				<div className="tcol-3">
					<EmissionSticker name={emissionSticker} />
				</div>
			</div>
			{emissionFuelConsumption.energyEfficiencyClass != null ?
				<div className="tcol-2">
					<EfficiencyClass id={emissionFuelConsumption.energyEfficiencyClass.toLowerCase()} />
				</div>
			: null}
		</Section>
	);
};

VehicleEmissionInfo.propTypes = {
	emissionFuelConsumption: PropTypes.object,
	site: PropTypes.string.isRequired,
	emissionSticker: PropTypes.string.isRequired
};

export default VehicleEmissionInfo;