import React from 'react';

const CHFPrice = ({ chf, vehicle }) => {
	return (
		<div>
			<div id="modal">
				<table>
					<tbody>
						<tr>
							<td>Fahrzeugpreis</td>
							<td>{vehicle.price.replace("€", "EUR")}</td>
						</tr>
						{chf.deVAT && chf.deVAT != "null €" ? <tr>
							<td>- 19 % deutsche MwSt.</td>
							<td>{chf.deVAT}</td>
						</tr> : null}
						<tr>
							<td></td>
							<td><hr /></td>
						</tr>
						<tr>
							<td>= netto</td>
							<td>{chf.priceWithDuty}</td>
						</tr>
						<tr>
							<td>entspricht ***</td>
							<td>{chf.netPriceCHF}</td>
						</tr>
						{chf.vehicleTax && chf.vehicleTax != "0,00 CHF" ?
							<tr>
								<td>+ 4 % Fahrzeug-Steuer *</td>
								<td>{chf.vehicleTax}</td>
							</tr>
						: null}
						<tr>
							<td>+ 7.7 % Schweizer MwSt. *</td>
							<td>{chf.vat}</td>
						</tr>
						<tr>
							<td>+ Zollpauschale ({chf.dutyDe})***</td>
							<td>{chf.duty}</td>
						</tr>
						<tr>
							<td></td>
							<td><hr /></td>
						</tr>
						<tr>
							<td><b>= Preis **</b></td>
							<td><b>{chf.totalPriceCHF}</b></td>
						</tr>
					</tbody>
				</table>
				<br />
				<table className="finePrint">
					<tbody>
						<tr>
							<td className="right">*</td>
							<td className="left">muss bei Grenzübertritt vom Käufer an entsprechende Behörde entrichtet werden</td>
						</tr>
						<tr>
							<td className="right">**</td>
							<td className="left">inkl. Überführung zum Händler</td>
						</tr>
						<tr>
							<td className="right">***</td>
							<td className="left">Preis bei Wechselkurs von {chf.exchangeRate} (Stand {chf.exchangeRateDate} / EZB)</td>
						</tr>
						{false ?
							<tr>
								<td className="right">****</td>
								<td className="left">Seit Juli 2012 ist für alle Personenwagen, die in die Schweiz importiert werden, eine CO2-Sanktion zu entrichten.</td>
							</tr>
						:
							null
						}

					</tbody>
				</table>
			</div>
		</div>
	);
};

export default CHFPrice;